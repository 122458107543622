import { createAction } from "@reduxjs/toolkit"

export const createWineClubMembershipsAction = createAction("createWineClubMemberships")

export const uploadBenefitsImageAction = createAction("uploadBenefitsImageAction")

export const uploadMembershipImageAction = createAction("uploadMembershipImageAction")

export const getExistingBenefitsAction = createAction("getExistingBenefitsAction")

export const getTriviaCategoriesAction = createAction("DMVgetTriviaCategoriesAction")

export const uploadTastingNotesWineImageAction = createAction("uploadTastingNotesWineImageAction")

export const uploadTastingNotesFlavourImageAction = createAction(
  "uploadTastingNotesFlavourImageAction"
)

export const createTastingNotesAction = createAction("createTastingNotesAction")

export const deleteBenefitAction = createAction("deleteBenefitAction")

export const getTastingNotesWeekListAction = createAction("getTastingNotesWeekListAction")

export const getTastingNotesDataAction = createAction("getTastingNotesDataAction")

export const deleteTastingNoteAction = createAction("deleteTastingNoteAction")
export const uploadTriviaCategoryImageAction = createAction("uploadTriviaCategoryImageAction")

export const createTriviaCategoriesAction = createAction("createTriviaCategoriesAction")

export const editTastingNotesAction = createAction("editTastingNotesAction")

export const getWineBenefitDataAction = createAction("getWineBenefitDataAction")

export const uploadBenefitIconImageAction = createAction("uploadBenefitIconImageAction")

export const editWineBenefitsAction = createAction("editWineBenefitsAction")

export const updateTriviaCategoriesAction = createAction("updateTriviaCategoriesAction")

export const getMembershipImageURLAction = createAction("getMembershipImageURLAction")

export const uploadGrandCruThumbnailAction = createAction("uploadGrandCruThumbnailAction")

export const uploadGrandCruVideoAction = createAction("uploadGrandCruVideoAction")

export const getGrandCruGoalsAction = createAction("getGrandCruGoalsAction")

export const updateGrandCruGoalsAction = createAction("updateGrandCruGoalsAction")

export const getGrandCruVideoDataAction = createAction("getGrandCruVideoDataAction")

export const updateGrandCruVideoAction = createAction("updateGrandCruVideoAction")

export const uploadTastingNotesVideoThumbnailAction = createAction(
  "uploadTastingNotesVideoThumbnailAction"
)

export const uploadVideoGalleryThumbnailAction = createAction("uploadVideoGalleryThumbnailAction")

export const uploadVideoGalleryVideoAction = createAction("uploadVideoGalleryVideoAction")

export const getVideoGalleryVideosAction = createAction("getVideoGalleryVideosAction")

export const deleteVideoGalleryVideoAction = createAction("deleteVideoGalleryVideoAction")

export const getRoundsListAction = createAction("getRoundsListAction")
export const getViewRoundDataAction = createAction("getViewRoundDataAction")
export const createRoundAction = createAction("createRoundAction")
export const deleteRoundAction = createAction("deleteRoundAction")
export const getRoundDataAction = createAction("getRoundDataAction")
export const updateRoundAction = createAction("updateRoundAction")
export const getHolidaysAction = createAction("getHolidaysAction")
export const getVideoLibraryVideosAction = createAction("getVideoLibraryVideosAction")
export const getAssignVideosWeekDataAction = createAction("getAssignVideosWeekDataAction")
export const assignVideosAction = createAction("assignVideosAction")
export const roundStatusUpdateAction = createAction("roundStatusUpdateAction")
export const revertAllDMV = createAction("revertAllDMV")
export const uploadHolidayListAction = createAction("uploadHolidayListAction")
export const getImportPreviousRoundsAction = createAction("getImportPreviousRoundsAction")
export const getDMVWineRegionListAction = createAction("getDMVWineRegionListAction")
export const getBeverageAction = createAction("getBeverageAction")
export const editWineRegionCategoryAction = createAction("editWineRegionCategoryAction")
export const addWineRegionCategoryAction = createAction("addWineRegionCategoryAction")
export const deleteWineRegionMenuCategoryAction = createAction("deleteWineRegionMenuCategoryAction")
export const addDMVWineMenuItemsAction = createAction("addDMVWineMenuItemsAction")
export const getDMVWineItemsAction = createAction("getDMVWineItemsAction")
export const updateDMVWineItemsAction = createAction("updateDMVWineItemsAction")
export const deleteDMVWineMenuItemAction = createAction("deleteDMVWineMenuItemAction")
export const editBeverageCategoryAction = createAction("editBeverageCategoryAction")
export const addBeverageCategoryAction = createAction("addBeverageCategoryAction")
export const deleteBeverageCategoryAction = createAction("deleteBeverageCategoryAction")
export const addDMVBeverageMenuItemsAction = createAction("addDMVBeverageMenuItemsAction")
export const getDMVBeverageItemsAction = createAction("getDMVBeverageItemsAction")
export const updateDMVBeverageItemsAction = createAction("updateDMVBeverageItemsAction")
export const deleteDMVBeverageMenuItemAction = createAction("deleteDMVBeverageMenuItemAction")
export const getDMVAutoSuggestQuestionsAction = createAction("getDMVAutoSuggestQuestionsAction")
export const editDMVAutoSuggestedQuestionAction = createAction("editDMVAutoSuggestedQuestionAction")
export const getGenerateQuestionDMVWineRegionAction = createAction(
  "getGenerateQuestionDMVWineRegionAction"
)
export const getGenerateQuestionDMVBeverageAction = createAction(
  "getGenerateQuestionDMVBeverageAction"
)
export const editSpiritCategoryAction = createAction("editSpiritCategoryAction")
export const addSpiritCategoryAction = createAction("addSpiritCategoryAction")
export const deleteSpiritCategoryAction = createAction("deleteSpiritCategoryAction")
export const getDMVSpiritSubMenuistAction = createAction("getDMVSpiritSubMenuistAction")
export const getSpiritAction = createAction("getSpiritAction")
export const getDMVSpiritItemsAction = createAction("getDMVSpiritItemsAction")
export const addDMVSpiritMenuItemsAction = createAction("addDMVSpiritMenuItemsAction")
export const updateDMVSpiritItemsAction = createAction("updateDMVSpiritItemsAction")
export const deleteDMVSpiritMenuItemAction = createAction("deleteDMVSpiritMenuItemAction")
export const getGenerateQuestionDMVSpiritsAction = createAction(
  "getGenerateQuestionDMVSpiritsAction"
)
export const getImportSpiritAction = createAction("getImportSpiritAction")
export const addDMVSpiritSubMenuistAction = createAction("addDMVSpiritSubMenuistAction")
export const getDMVSpiritSummaryAction = createAction("getDMVSpiritSummaryAction")
export const createQuizAction = createAction("createQuizAction")
export const editQuizAction = createAction("editQuizAction")
export const getDmvQuizListAction = createAction("getDmvQuizListAction")
export const getQuizCategoryAction = createAction("getQuizCategoryAction")
export const addQuestionToQuizAction = createAction("addQuestionToQuizAction")
export const getQuizDataAction = createAction("getQuizDataAction")
export const deleteQuestionFromQuizAction = createAction("deleteQuestionFromQuizAction")
export const getMonthlyAnalyticsAction = createAction("getMonthlyAnalyticsAction")
export const getWeeklyAnalyticsAction = createAction("getWeeklyAnalyticsAction")
export const getExportDataAction = createAction("getExportDataAction")
export const getPushNotificationAction = createAction("getPushNotificationAction")
export const getEmployeeListAction = createAction("getEmployeeListAction")
export const addPushNotificationAction = createAction("addPushNotificationAction")
export const resendNotificationAction = createAction("resendNotificationAction")
export const getVIPRestaurantsAction = createAction("getVIPRestaurantsAction")
export const getVIPListAction = createAction("getVIPListAction")
export const getVIPProfileAction = createAction("getVIPProfileAction")
export const getVIPImageAction = createAction("getVIPImageAction")
export const confirmVIPImageAction = createAction("confirmVIPImageAction")
export const generateVIPQuestionAction = createAction("generateVIPQuestionAction")
export const getVIPQuestionAction = createAction("getVIPQuestionAction")
export const addToVIPQuestionsBankAction = createAction("addToVIPQuestionsBankAction")
export const editVIPQuestionsAction = createAction("editVIPQuestionsAction")
export const deleteVIPQuestionsAction = createAction("deleteVIPQuestionsAction")
export const getGenerateQuestionPromptAction = createAction("getGenerateQuestionPromptAction")
