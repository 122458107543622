import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  Typography
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "../GenerateQyestion.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import AppButton from "components/StyledComponents/AppButton"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "react-bootstrap"
import TriviaMenuNavBar from "components/TriviaMenuBar"
import SearchIcon from "@mui/icons-material/Search"
import Card from "components/Card/Card"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { toast } from "react-toastify"
import {
  getBarMenuItemAction,
  getGenerateQuestionsSpiritsAction,
  getMenuDinnerLunchAction,
  getMenuDinnerLunchSearchAction,
  getWineBottleMenuItemAction,
  getWineMenuItemAction
} from "redux/L&D/actions/user"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import { useDebounce } from "hooks/utils"
import { useTheme } from "@mui/system"
import SearchTextField from "components/StyledComponents/SearchTextField"
import AppPagination from "components/StyledComponents/AppPagination"

const menuItems = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  BREAKFAST: "breakfast",
  BAR_MENU: "bar menu",
  WINE: "wine by glass",
  WINEBOTTLE: "wine by bottle",
  SPIRITS: "spirits",
  SPECIALS: "specials"
}

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const SelectMenuItems = ({ onClose = () => {} }) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState("")
  const dispatch = useDispatch()
  const { selectedMenuItems } = useSelector((state) => state?.learningDevelopment || [])
  const {
    generate_question_MenuLink,
    dinner_Lunch_data,
    wineMenuData,
    barMenuData,
    generateQuestionSpiritData,
    wineBottleMenuData
  } = useSelector((state) => state?.learningDevelopment || {})
  const [currentPage, setCurrentPage] = useState(1)

  const debouncedSearchTerm = useDebounce(searchValue, 500)

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  //Trivia_menu_search

  const [selectedItem, setSelectedItem] = useState(selectedMenuItems || [])

  //checks item is selected or not
  const checkSelected = (ID) => {
    let isItemSelected = selectedItem.filter((item) => item?.id === ID)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleSelect = (data) => {
    //check that item is already selected or not
    const isAvailable = selectedItem.filter((item) => item?.id === data?.id)
    //if item is already selected remove that item from list
    if (isAvailable?.length > 0) {
      const updatedList = selectedItem.filter((item) => item?.id !== data?.id)
      setSelectedItem(updatedList)
    } else {
      //if selected items count is more then 5 throw error
      if (selectedItem?.length === 5) {
        return toast.error("Only five items can be selected", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark"
        })
      }

      const newData = {
        ...data,
        category:
          generate_question_MenuLink === menuItems?.DINNER ||
          generate_question_MenuLink === menuItems?.LUNCH ||
          generate_question_MenuLink === menuItems?.BRUNCH ||
          generate_question_MenuLink === menuItems?.BREAKFAST ||
          generate_question_MenuLink === menuItems?.SPECIALS
            ? "FOOD"
            : generate_question_MenuLink === menuItems?.BAR_MENU
            ? "BAR_MENU"
            : generate_question_MenuLink === menuItems?.SPIRITS
            ? "SPIRITS"
            : "WINE_GLASS"
      }
      //add new item in list
      setSelectedItem([...selectedItem, newData])
    }
  }

  const handlePreview = () => {
    if (selectedItem?.length > 0) {
      dispatch(learningDevelopmentActions.setSelectedMenuItems(selectedItem))
      onClose()
    } else {
      toast.error("Please select atleast one menu item!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  useEffect(() => {
    dispatch(learningDevelopmentActions.setGenerate_Question_MenuLink("dinner"))
  }, [])

  useEffect(() => {
    if (generate_question_MenuLink === menuItems.BAR_MENU) {
      dispatch(getBarMenuItemAction(debouncedSearchTerm))
    } else if (generate_question_MenuLink === menuItems.WINE) {
      dispatch(getWineMenuItemAction(debouncedSearchTerm))
    } else if (generate_question_MenuLink === menuItems.WINEBOTTLE) {
      dispatch(getWineBottleMenuItemAction(debouncedSearchTerm))
    } else if (generate_question_MenuLink === menuItems.SPIRITS) {
      dispatch(
        getGenerateQuestionsSpiritsAction({ page: currentPage, search: debouncedSearchTerm })
      )
    } else if (debouncedSearchTerm === "") {
      dispatch(getMenuDinnerLunchAction(debouncedSearchTerm))
    }
  }, [generate_question_MenuLink, debouncedSearchTerm, currentPage])

  useEffect(() => {
    if (
      generate_question_MenuLink !== menuItems.BAR_MENU &&
      generate_question_MenuLink !== menuItems.WINE &&
      generate_question_MenuLink !== menuItems.WINEBOTTLE &&
      debouncedSearchTerm !== ""
    ) {
      dispatch(getMenuDinnerLunchSearchAction({ debouncedSearchTerm, generate_question_MenuLink }))
    }
  }, [debouncedSearchTerm])

  return (
    <StyledDialog open={true} maxWidth="">
      <DialogContent id="editQuestionDialog" className={styles.dialogBox}>
        <React.Fragment>
          <Box className="d-flex justify-content-center position-relative">
            <Typography variant="h2">Select Menu Item</Typography>
            <Box className={styles.closeButton}>
              <span role="button" onClick={onClose}>
                <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                  <CloseIcon sx={{ height: "14px", width: "14px" }} />
                </IconButton>
              </span>
            </Box>
          </Box>
          <Box height={40} />
          <Box sx={{ padding: "0 11px" }}>
            <Row className="mt-4">
              <Col lg="12">
                <SearchTextField
                  id="search"
                  name="search"
                  value={searchValue}
                  onChange={handleSearchField}
                  fullWidth
                  type="search"
                  placeholder="Search Menu Item"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          backgroundColor: "#231E1B"
                        }}>
                        <SearchIcon htmlColor="#C4C4C4" />
                      </InputAdornment>
                    )
                  }}
                  style={{
                    border: "1px solid #C4C4C4",
                    backgroundColor: "#231E1B",
                    borderRadius: 6
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <TriviaMenuNavBar activeTab={generate_question_MenuLink}></TriviaMenuNavBar>
              </Col>
            </Row>
            {selectedItem?.length > 0 && (
              <Row className="mt-3" style={{ marginLeft: 1 }}>
                <Typography variant="caption" color={theme.palette.secondary.main}>
                  {selectedItem?.length} of 5 selected
                </Typography>
              </Row>
            )}
            <>
              {/* Bar Menu items list */}
              {generate_question_MenuLink === menuItems?.BAR_MENU && (
                <Card>
                  <Grid container spacing={2}>
                    {barMenuData?.map((item) => (
                      <Grid item xs={4} className={classes.triviaItems} key={item?.id}>
                        <div className="d-flex align-items-center">
                          {checkSelected(item?.id) ? (
                            <IconButton sx={(theme) => ({ color: theme.palette.secondary.main })}>
                              <CheckCircleIcon onClick={() => handleSelect(item)} />
                            </IconButton>
                          ) : (
                            <IconButton sx={(theme) => ({ color: theme.palette.secondary.main })}>
                              <RadioButtonUncheckedIcon onClick={() => handleSelect(item)} />
                            </IconButton>
                          )}
                        </div>
                        <div className={classes.imageContainer} style={{ height: 100, width: 100 }}>
                          <ImageDisplay data={item?.image_url}></ImageDisplay>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            maxWidth: 300,
                            overflowWrap: "break-word"
                          }}>
                          <div className="d-flex justify-content-start align-items-center">
                            <Typography variant="h4">{item?.name ?? "-"}</Typography>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              )}
              {/* Wine Menu items list */}
              {generate_question_MenuLink === menuItems?.WINE && (
                <>
                  {wineMenuData?.map(
                    (item, index) =>
                      item?.meal_category === "Wine By Glass" &&
                      item?.food_list?.length > 0 && (
                        <Card key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid container spacing={2}>
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image_url}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}
              {/* Wine By Bottle Menu items list */}
              {generate_question_MenuLink === menuItems?.WINEBOTTLE && (
                <>
                  {wineBottleMenuData?.map(
                    (item, index) =>
                      item?.meal_category === "Wine By Bottle" &&
                      item?.food_list?.length > 0 && (
                        <Card key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid container spacing={2}>
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image_url}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}

              {/* Dinner items list */}
              {generate_question_MenuLink === menuItems?.DINNER && (
                <>
                  {dinner_Lunch_data?.map(
                    (item, index) =>
                      item?.meal_category === "Dinner" &&
                      item?.food_list?.length > 0 && (
                        <Card className="mb-2" key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                            className="mb-4">
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}

              {/* Lunch items list */}
              {generate_question_MenuLink === menuItems?.LUNCH && (
                <>
                  {dinner_Lunch_data?.map(
                    (item, index) =>
                      item?.meal_category === "Lunch" &&
                      item?.food_list?.length > 0 && (
                        <Card className="mb-2" key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                            className="mb-4">
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}

              {/* Brunch items list */}
              {generate_question_MenuLink === menuItems?.BRUNCH && (
                <>
                  {dinner_Lunch_data?.map(
                    (item, index) =>
                      item?.meal_category === "Brunch" &&
                      item?.food_list?.length > 0 && (
                        <Card className="mb-2" key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                            className="mb-4">
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}

              {/* Breakfast items list */}
              {generate_question_MenuLink === menuItems?.BREAKFAST && (
                <>
                  {dinner_Lunch_data?.map(
                    (item, index) =>
                      item?.meal_category === "Breakfast" &&
                      item?.food_list?.length > 0 && (
                        <Card className="mb-2" key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                            className="mb-4">
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}

              {/* Specials items list */}
              {generate_question_MenuLink === menuItems?.SPECIALS && (
                <>
                  {dinner_Lunch_data?.map(
                    (item, index) =>
                      item?.meal_category === "Specials" &&
                      item?.food_list?.length > 0 && (
                        <Card className="mb-2" key={index}>
                          <div className="d-flex justify-content-start align-item-center mb-4">
                            <Typography variant="h2">{item?.food_category}</Typography>
                            <div className="d-flex align-items-center"></div>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                            className="mb-4">
                            {item?.food_list?.length > 0 ? (
                              item?.food_list?.map((data) => (
                                <Grid item xs={4} className={classes.triviaItems} key={data?.id}>
                                  <div className="d-flex align-items-center">
                                    {checkSelected(data?.id) ? (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <CheckCircleIcon onClick={() => handleSelect(data)} />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                        <RadioButtonUncheckedIcon
                                          onClick={() => handleSelect(data)}
                                        />
                                      </IconButton>
                                    )}
                                  </div>
                                  <div
                                    className={classes.imageContainer}
                                    style={{ height: 100, width: 100 }}>
                                    <ImageDisplay data={data?.image}></ImageDisplay>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      maxWidth: 300,
                                      overflowWrap: "break-word"
                                    }}>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                    </div>
                                  </div>
                                </Grid>
                              ))
                            ) : (
                              <Typography
                                className="mt-4"
                                style={{
                                  opacity: 0.6,
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                No Items Found
                              </Typography>
                            )}
                          </Grid>
                        </Card>
                      )
                  )}
                </>
              )}

              {/*  Spirits items list */}
              {generate_question_MenuLink === menuItems?.SPIRITS && (
                <>
                  <Card className="mb-2">
                    <Grid
                      container
                      spacing={2}
                      justifyContent={generateQuestionSpiritData?.count === 0 ? "center" : ""}
                      className="mb-4">
                      {generateQuestionSpiritData?.results?.map((item, index) => (
                        <Grid
                          item
                          xs={4}
                          key={index}
                          className={classes.triviaItems}
                          sx={{ border: "none" }}>
                          <div className="d-flex align-items-center">
                            {checkSelected(item?.id) ? (
                              <IconButton sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                <CheckCircleIcon onClick={() => handleSelect(item)} />
                              </IconButton>
                            ) : (
                              <IconButton sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                <RadioButtonUncheckedIcon onClick={() => handleSelect(item)} />
                              </IconButton>
                            )}
                          </div>
                          <div
                            className={classes.imageContainer}
                            style={{ height: 120, width: 120 }}>
                            <ImageDisplay data={item?.image_url}></ImageDisplay>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              maxWidth: 300,
                              overflowWrap: "break-word"
                            }}>
                            <div className="d-flex justify-content-start align-items-center">
                              <Typography variant="h4">{item?.name ?? "-"}</Typography>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                  <AppPagination
                    onPageChange={setCurrentPage}
                    totalPages={generateQuestionSpiritData?.pages}
                    currentPage={currentPage}
                  />
                </>
              )}
            </>
            <Box className="d-flex justify-content-center">
              <AppButton className="mt-3 px-5" variant="outlined" onClick={handlePreview}>
                Preview Menu Item
              </AppButton>
            </Box>
          </Box>
        </React.Fragment>
      </DialogContent>
    </StyledDialog>
  )
}

export default SelectMenuItems
