import React from "react"
import { Box, Card, Checkbox, Grid, styled, Tooltip, Typography, useTheme } from "@mui/material"
import styles from "../generateVipQuestions.module.scss"
import { useSelector } from "react-redux"
import { convertTo12HourFormat } from "helpers/functions"

const StyledCard = styled(Card)(({ theme }) => ({
  color: theme.palette.white.main,
  border: "1px solid #C4C4C4",
  borderRadius: "8px",
  textAlign: "center",
  padding: "12px",
  height: "100%",
  background: "transparent"
}))

const SelectVIP = ({ setSelectedVips, selectedVips }) => {
  const theme = useTheme()
  const { VIPList } = useSelector((state) => state?.dmv)

  const handleCheck = (VIP) => {
    setSelectedVips((prevValue) =>
      prevValue.some((vip) => vip?.id === VIP?.id)
        ? prevValue.filter((vip) => vip?.id !== VIP?.id)
        : [...prevValue, VIP]
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2Italic" color={theme.palette.text.secondary}>
          Step-1
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body4Regular" color={theme.palette.text.secondary}>
          Select VIP
        </Typography>
      </Grid>
      {VIPList?.map((VIP, index) => (
        <Grid item xs={4} key={index}>
          <StyledCard>
            <Box className={styles.card}>
              <Box className="d-flex flex-column">
                <Typography variant="body1Regular" textAlign={"left"} mb={0.5}>{`${
                  VIP?.name
                }- ${convertTo12HourFormat(VIP?.arriving_time || "")}`}</Typography>
                <Typography
                  variant="body3Light"
                  color={theme.palette.text.secondary}
                  textAlign="left"
                  ml={0.5}
                  mb={2}>
                  {VIP?.designation || "No Designation"} - {VIP?.work_company || "No Company"}
                </Typography>

                <Box className="d-flex">
                  <Typography className={styles.selectVipText} variant="body3Light">
                    {`Occasion :`}
                  </Typography>
                  {VIP?.occasion?.length > 26 ? (
                    <Tooltip title={`${VIP?.occasion || ""}`} placement="top">
                      <Typography variant="body3Light" color={"#C4C4C4"} textAlign="left" ml={0.5}>
                        {`${
                          (VIP?.occasion?.length > 26
                            ? VIP?.occasion.substring(0, 26) + "..."
                            : VIP?.occasion) || "No Occasion"
                        } `}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="body3Light" color={"#C4C4C4"} textAlign="left" ml={0.5}>
                      {`${VIP?.occasion || "No Occasion"} `}
                    </Typography>
                  )}
                </Box>
                <Box className="d-flex">
                  <Typography variant="body3Light" className={styles.selectVipText}>
                    {`Spent :`}
                  </Typography>
                  <Typography variant="body3Light" color={theme.palette.text.secondary} ml={0.5}>
                    {VIP?.spent ? `$${parseFloat(VIP.spent).toString()}` : "$0"}
                  </Typography>
                </Box>
                <Box className="d-flex">
                  <Typography variant="body3Light" className={styles.selectVipText}>
                    {`Visits :`}
                  </Typography>
                  <Typography
                    variant="body3Light"
                    color={theme.palette.text.secondary}
                    ml={0.5}>{`${VIP?.visits || "0"}`}</Typography>
                </Box>
                <Box className="d-flex">
                  <Typography variant="body3Light" className={styles.selectVipText}>
                    {`Notes :`}
                  </Typography>

                  {VIP?.res_notes?.length > 26 ? (
                    <Tooltip title={`${VIP?.res_notes || ""}`} placement="top">
                      <Typography variant="body3Light" color={"#C4C4C4"} textAlign="left" ml={0.5}>
                        {`${
                          (VIP?.res_notes?.length > 26
                            ? VIP?.res_notes.substring(0, 26) + "..."
                            : VIP?.res_notes) || "No Occasion"
                        } `}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="body3Light" color={"#C4C4C4"} textAlign="left" ml={0.5}>
                      {`${VIP?.res_notes || "No Occasion"} `}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box className="d-flex justify-content-end align-items-center">
                <Checkbox
                  onClick={() => {
                    handleCheck(VIP)
                  }}
                  checked={selectedVips.some((vip) => vip.id === VIP.id)}
                  style={{
                    color: theme.palette.secondary.default
                  }}
                />
              </Box>
            </Box>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  )
}

export default SelectVIP
