import React, { Fragment, useEffect, useState } from "react"
import { Box, Divider, Grid, InputAdornment, Typography } from "@mui/material"
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"
import { useTheme } from "@mui/system"
import BankQuestions from "./BankQuestions"
import CreateIcon from "@mui/icons-material/Create"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Card from "components/Card/Card"
import AppPagination from "components/StyledComponents/AppPagination"
import EditQuestion from "../EditQuestion"
import ImageDisplay from "components/ImageDisplayer"
import { Col, Row } from "react-bootstrap"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  addToQuestionBankAction,
  deleteQuestionFromAutoSuggestAction,
  deleteQuestionFromBankAction,
  editSuggestedQuestionAction,
  flagQuestionAction
} from "redux/L&D/actions/user"
import DeleteIcon from "@mui/icons-material/Delete"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import AppSelect from "components/StyledComponents/AppSelect"
import AppDatePicker from "components/AppDatePicker"
import CloseIcon from "@mui/icons-material/Close"
import CategoryCard from "./CategoryCard"
import SearchTextField from "components/StyledComponents/SearchTextField"
import SearchIcon from "@mui/icons-material/Search"
import flag from "assets/icons/flag.svg"
import flagFilled from "assets/icons/flagFilled.svg"
import ReasonsForm from "components/flagReasonDialog"
import { Formik } from "formik"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import { validationSchemaReasonsForm } from "helpers/validationSchema"
import { useSelector } from "react-redux"
import {
  addToVIPQuestionsBankAction,
  deleteVIPQuestionsAction,
  editVIPQuestionsAction
} from "redux/DMV/actions/user"
import { DMVActions } from "redux/DMV/slice/user"

const btnText = {
  questionBank: "Add To Question Bank",
  triviaText: "Add to Trivia"
}

function QuestionsBanks({
  questions,
  isEditable = false,
  showImage = true,
  showPagination = true,
  currentPage = 1,
  setCurrentPage,
  isTiriva = false,
  handleAddToTrivia,
  pages = 0,
  isGamification = false,
  isAutoSuggest = false,
  isDelete = false,
  questionFilter = false,
  setFilterQuestion,
  filterQuestion,
  filterOptions,
  disableFilter,
  itemsPerPage = 12,
  month,
  setMonth,
  platform,
  questionCount,
  categoryCount = false,
  showRank = false,
  RankOpt,
  questionCategoryCount,
  searchValue,
  setSearchValue,
  isCMSAutoSuggest = false,
  isDMV = false,
  isFlagged = false,
  isGenerateQuestion = false,
  isVIP = false,
  isVIPGenerated = false,
  allQuestions
}) {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const style = {
    card: {
      padding: "24px 0"
    },
    box: {
      padding: "0px 24px",
      background: "none"
    },
    divider: {
      background: "#7f7f7f",
      height: "2px",
      opacity: 0.12
    },
    btnQuestion: {
      paddingLeft: "47px",
      paddingRight: "47px"
    },
    checkBoxPosition: {
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 14
    }
  }

  const [selectAll, setSelectAll] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [editData, setEditData] = useState()
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [execeptQuestions, setExceptQuestions] = useState([])
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [deleteQuestion, setDeleteQuestion] = useState("")
  const [openReasonDialog, setOpenReasonDialog] = useState(false)
  // const [openFlagged, setOpenFlagged] = useState(false)
  const [selectedQuestionsObjects, setSelectedQuestionsObjects] = useState([])
  const [initialValues, setInitialValues] = useState({
    reasonCodes: "",
    description: "",
    questionType: "",
    category: ""
  })
  const { autoSuggestQuestionId, questionCategory } = useSelector(
    (state) => state?.learningDevelopment
  )
  const { flaggedQuestions } = useSelector((state) => state?.learningDevelopment)

  const handleQuestionSelect = () => {
    if (selectAll && execeptQuestions?.length > 0) {
      setExceptQuestions([])
      const uniqueQuestions = allQuestions?.filter(
        (question) => !selectedQuestionsObjects?.some((selected) => selected?.id === question?.id)
      )
      setSelectedQuestionsObjects(uniqueQuestions)
      return
    } else if (selectAll) {
      setSelectedQuestionsObjects(allQuestions)
    }
    setSelectedQuestions([])
    setExceptQuestions([])
    setSelectAll((prevSelectAll) => !prevSelectAll)
  }

  const handleSelectQuestion = (ID, question) => {
    if (selectAll) {
      const isExceptQuestionsAvailable = execeptQuestions?.filter((item) => item === ID)
      if (isExceptQuestionsAvailable?.length > 0) {
        const updatedExceptQuestionList = execeptQuestions?.filter((item) => item !== ID)
        setExceptQuestions(updatedExceptQuestionList)
        selectedQuestionsObjects?.length > 0
          ? setSelectedQuestionsObjects([...selectedQuestionsObjects, question])
          : setSelectedQuestionsObjects([question])
      } else {
        setExceptQuestions([...execeptQuestions, ID])
        setSelectedQuestionsObjects(selectedQuestionsObjects?.filter((item) => item?.id !== ID))
      }
    } else {
      //check that question is already selected or not
      const isAvailable = selectedQuestions.filter((item) => item === ID)
      //if item is already selected remove that item from list
      if (isAvailable?.length > 0) {
        const updatedList = selectedQuestions.filter((item) => item !== ID)
        setSelectedQuestions(updatedList)
        setSelectedQuestionsObjects(selectedQuestionsObjects?.filter((item) => item?.id !== ID))
      } else {
        //add new item in list
        setSelectedQuestions([...selectedQuestions, ID])
        selectedQuestionsObjects?.length > 0
          ? setSelectedQuestionsObjects([...selectedQuestionsObjects, question])
          : setSelectedQuestionsObjects([question])
        // setSelectedQuestionsObjects([...selectedQuestionsObjects, question])
      }
    }
  }
  // console.log("selectedquestions", selectedQuestionsObjects)

  const checkSelected = (ID) => {
    if (selectAll && execeptQuestions?.length === 0) {
      return true
    }
    if (selectAll && execeptQuestions?.length > 0) {
      let exceptIems = execeptQuestions.filter((data) => data === ID)
      if (exceptIems?.length > 0) {
        return false
      } else {
        return true
      }
    } else {
      let isItemSelected = selectedQuestions.filter((item) => item === ID)
      if (isItemSelected?.length > 0) {
        return true
      } else {
        return false
      }
    }
  }

  const handleEditDialogOpen = (question) => {
    setEditData(question)
    setOpenEditDialog(true)
  }

  const handleDeleteQuestion = () => {
    if (isAutoSuggest) {
      dispatch(
        deleteQuestionFromAutoSuggestAction({
          question: deleteQuestion,
          currentPage: isCMSAutoSuggest ? 1 : currentPage,
          page_size: isCMSAutoSuggest ? 9999 : 12,
          ...(!isCMSAutoSuggest ? { menu: filterQuestion } : {})
        })
      )
    } else {
      if (isVIP) {
        dispatch(deleteVIPQuestionsAction({ question: deleteQuestion }))
      } else {
        dispatch(
          deleteQuestionFromBankAction({
            question: deleteQuestion,
            currentPage,
            ...(filterQuestion === "INCORRECT"
              ? { type: filterQuestion }
              : { question_used: filterQuestion }),
            platform
          })
        )
      }
    }
  }

  const handleOpenDelete = (question) => {
    setIsDeletePopupOpen(true)
    setDeleteQuestion(question)
  }

  const handleCloseDelete = () => {
    setIsDeletePopupOpen(false)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  useEffect(() => {
    dispatch(DMVActions.setSelectedVIPQuestions(selectedQuestionsObjects))
  }, [selectedQuestionsObjects])

  useEffect(() => {
    const questionLength = questionCount ?? questions?.length
    if (selectedQuestions?.length > 0 && questionLength === selectedQuestions?.length) {
      setSelectAll(true)
    }
  }, [selectedQuestions])

  const handleAddToBank = () => {
    const questionIds = questions?.map((question) => question.id)
    const payload = {
      is_all_selected: selectAll,
      question_ids: selectedQuestions,
      excepted_ids: execeptQuestions,
      ...(!isAutoSuggest && selectAll ? { all_question_ids: questionIds } : null)
    }
    if (isVIPGenerated) {
      dispatch(addToVIPQuestionsBankAction(payload))
      history.push("/dmv/dashboard/vip-question-bank")
    } else {
      dispatch(addToQuestionBankAction({ payload, isAutoSuggest }))
      if (!isGamification) {
        history.push("/learning_development/dashboard/question-bank")
      } else {
        history.goBack()
      }
    }
  }

  const handleAddQuestionsToTrivia = () => {
    const payload = {
      is_all_selected: selectAll,
      question_ids: selectedQuestions,
      excepted_ids: execeptQuestions
    }
    handleAddToTrivia(payload)
  }

  const handleAddQuestions = () => {
    const questionIds = questions?.map((question) => question.id)
    const payload = {
      is_all_selected: selectAll,
      question_ids: selectedQuestions,
      excepted_ids: execeptQuestions,
      ...(!isAutoSuggest && selectAll ? { all_question_ids: questionIds } : null)
    }
    handleAddToTrivia(payload)
  }

  const checkButtonDisable = () => {
    if (selectAll || selectedQuestions?.length > 0 || execeptQuestions?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleFilter = (event) => {
    setFilterQuestion(event.target.value)
    setCurrentPage(1)
  }

  const handleClear = () => {
    setMonth(null)
  }

  const handleFlag = (question) => {
    const categoryMap = {
      lunch: "FOOD",
      brunch: "FOOD",
      dinner: "FOOD",
      breakfast: "FOOD",
      bar_menu: "BAR",
      "bar menu": "BAR",
      wine_glass: "WINE_BY_GLASS",
      spirits: "SPIRIT"
    }

    const category = categoryMap[question?.filter_category]
    dispatch(learningDevelopmentActions.setQuestionCategory(category))
    setOpenReasonDialog(true)
    dispatch(learningDevelopmentActions.setAutoSuggestQuestionId(question?.id))
  }
  // TO DO

  // const handleFlagged = (question) => {
  //   const payload = {
  //     reasonCodes: question.reason.reason_id,
  //     description: question.remark,
  //     questionType: question.reason_type
  //   }
  //   setInitialValues(payload)
  //   setOpenReasonDialog(true)
  //   setOpenFlagged(true)
  // }

  const handleReasonDialogClose = () => {
    // setOpenFlagged(false)
    setOpenReasonDialog(false)
    setInitialValues({ reasonCodes: "", description: "", questionType: "" })
  }

  const handleSubmit = (values) => {
    const payload = {
      reason: values.reasonCodes,
      description: values.description,
      question: autoSuggestQuestionId,
      category: questionCategory
    }
    const patchPayload = {
      is_flag: true
    }
    dispatch(flagQuestionAction({ payload, handleClose: handleReasonDialogClose, patchPayload }))
  }

  const handleFlaggedButton = () => {
    history.push("/learning_development/dashboard/question-bank/add-questions/flagged-questions")
  }

  const handleRank = (data, newRank) => {
    let payload = {
      rank: newRank
    }

    if (isVIP) {
      dispatch(editVIPQuestionsAction({ questionId: data.id, data: payload, isRankUpdate: true }))
    } else {
      dispatch(
        editSuggestedQuestionAction({
          questionId: data.id,
          data: payload,
          currentPage,
          ...(filterQuestion === "INCORRECT"
            ? { type: filterQuestion }
            : { question_used: filterQuestion }),
          platform,
          isRankUpdate: true
        })
      )
    }
  }

  const renderFilterSection = () => (
    <Grid container justifyContent="flex-end" spacing={1}>
      {questionFilter && (
        <Grid item xs={disableFilter ? 4 : 4}>
          <AppSelect
            menuItems={filterOptions}
            value={filterQuestion}
            onChange={handleFilter}
            disabled={disableFilter}
          />
        </Grid>
      )}
      {!!disableFilter && (
        <Grid item xs={3} className="datesContainer" sx={{ position: "relative" }}>
          <Box className="dateFieldContainer">
            <AppDatePicker
              openTo="month"
              views={["year", "month"]}
              minDate={new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)}
              maxDate={new Date().setMonth(new Date().getMonth() - 1)}
              inputFormat="MMM yyyy"
              key={"month"}
              value={month}
              onChange={(val) => setMonth(val)}
              placeholder="Month"
            />
          </Box>
          {month && (
            <Grid item sx={{ top: "6px" }} className={styles.ellipse_gold} onClick={handleClear}>
              <CloseIcon fontSize="1rem" />
            </Grid>
          )}
        </Grid>
      )}
      {questions?.length > 0 && (
        <Grid item xs={2.5} xl={2} display="flex" justifyContent="flex-end" alignItems="center">
          <Typography>
            Select All
            {selectAll && execeptQuestions?.length === 0 ? (
              <CheckCircleIcon
                htmlColor={theme.palette.secondary.main}
                onClick={handleQuestionSelect}
                style={style.checkBoxPosition}
              />
            ) : (
              <RadioButtonUncheckedIcon
                style={style.checkBoxPosition}
                onClick={handleQuestionSelect}
              />
            )}
          </Typography>
        </Grid>
      )}
    </Grid>
  )

  const renderSearchSection = () => (
    <>
      <Grid container item xs={12} justifyContent="flex-end">
        {!isGenerateQuestion && (
          <Grid item xs={4}>
            <SearchTextField
              id="search"
              name="search"
              value={searchValue}
              onChange={handleSearchField}
              fullWidth
              type="search"
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: theme.palette.background.secondary
                    }}>
                    <SearchIcon htmlColor="#C4C4C4" />
                  </InputAdornment>
                )
              }}
              style={{
                border: "1px solid #C4C4C4",
                backgroundColor: theme.palette.background.secondary,
                borderRadius: 6
              }}
            />
          </Grid>
        )}
        <Grid container item xs={8} justifyContent="flex-end">
          {renderFilterSection()}
        </Grid>
      </Grid>
    </>
  )

  return (
    <Fragment>
      {openEditDialog ? (
        <EditQuestion
          onClose={handleEditDialogClose}
          data={editData}
          currentPage={currentPage}
          isGamification={isGamification}
          filterQuestion={filterQuestion}
          platform={platform}
          showRank={showRank}
          RankOpt={RankOpt}
          isVIP={isVIP}
        />
      ) : null}
      {openReasonDialog && (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchemaReasonsForm}
          initialValues={initialValues}>
          {(formik) => (
            <ReasonsForm
              onClose={() => handleReasonDialogClose()}
              formik={formik}
              open={openReasonDialog}
            />
          )}
        </Formik>
      )}
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        onClose={() => handleCloseDelete()}
        onDelete={() => handleDeleteQuestion()}
        description="Are you sure you want to delete this question?"
      />
      {categoryCount && (
        <Row>
          <Col lg="12">
            <Box className="d-flex w-100 flex-wrap">
              {questionCategoryCount?.map((option, index) => (
                <CategoryCard
                  key={index}
                  title={option?.title}
                  category={option?.category}
                  count={option?.count}
                />
              ))}
            </Box>
          </Col>
        </Row>
      )}
      {!isAutoSuggest ? (
        <Grid container minWidth={520} xs={12} mt={2} justifyContent="flex-end">
          {renderSearchSection()}
        </Grid>
      ) : (
        <Grid container xs={12} mt={2} alignItems="center" justifyContent="space-between">
          {isFlagged && (
            <Grid item xs={3}>
              {!!flaggedQuestions?.count && (
                <span className={styles.questionCount}>
                  Total Flagged Questions: {flaggedQuestions?.count}
                </span>
              )}
            </Grid>
          )}
          <Grid container item xs={9}>
            {renderFilterSection()}
          </Grid>
        </Grid>
      )}
      <Card sx={style.card}>
        {questions?.length > 0 ? (
          questions?.map((question, index) => (
            <Fragment key={question?.id}>
              <Box
                key={question?.id}
                className={`${styles.questionCtr} ${
                  question?.is_used_in_trivia ? styles.question_added : ""
                }`}
                sx={style.box}>
                <Grid container>
                  <Grid item xs={10}>
                    <div className="d-flex">
                      <div
                        className={styles.countBorder}
                        style={{
                          borderColor: "#FFFFFF",
                          marginTop: 25,
                          marginRight: 10
                        }}>
                        {currentPage * itemsPerPage - (itemsPerPage - 1) + index}
                      </div>
                      {showImage ? (
                        <div className="d-flex ml-2">
                          <div
                            className="me-3 mt-3 p-2 d-flex justify-content-center"
                            style={{ width: "180px", height: "180px" }}>
                            <ImageDisplay data={question.image} keyProp={"image"} />
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginLeft: 20 }}></div>
                      )}
                      <BankQuestions
                        question={question}
                        status={question?.status}
                        index={index}
                        isIncorrect={filterQuestion === "INCORRECT" ? true : false}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                    }}>
                    <div>
                      <Grid container justifyContent="flex-end">
                        <div style={{ marginTop: 20 }}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              {(isAutoSuggest || isEditable) && (
                                <>
                                  {isAutoSuggest &&
                                    (question.is_flag ? (
                                      <img
                                        src={flagFilled}
                                        // onClick={() => handleFlagged(question)}
                                        className={styles.flagImage}
                                      />
                                    ) : (
                                      <img
                                        src={flag}
                                        onClick={() => handleFlag(question)}
                                        className={styles.flagImage}
                                      />
                                    ))}
                                  {isEditable && (
                                    <CreateIcon
                                      sx={(theme) => ({
                                        color: theme.palette.white.main,
                                        cursor: "pointer"
                                      })}
                                      className="me-2"
                                      onClick={() => handleEditDialogOpen(question)}
                                    />
                                  )}
                                </>
                              )}
                            </Grid>

                            {!question?.is_used_in_trivia && isDelete ? (
                              <Grid item>
                                <DeleteIcon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleOpenDelete(question)}
                                />
                              </Grid>
                            ) : null}
                            <Grid item>
                              {checkSelected(question?.id) || question?.is_used_in_trivia ? (
                                <CheckCircleIcon
                                  htmlColor={theme.palette.secondary.main}
                                  onClick={() =>
                                    !question?.is_used_in_trivia &&
                                    handleSelectQuestion(question?.id, question)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSelectQuestion(question?.id, question)}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item mt={2} display="flex" justifyContent="flex-end" gap={2}>
                        {question?.is_added_manually ? (
                          <Typography className={styles.inUse} variant="body2Regular">
                            Manual
                          </Typography>
                        ) : null}
                        {question?.is_used_in_trivia ? (
                          <Typography className={styles.inUse} variant="body2Regular">
                            In Use
                          </Typography>
                        ) : null}
                      </Grid>
                    </div>
                    {showRank && (
                      <Grid item my={2}>
                        <Grid container justifyContent="flex-end" alignContent="center">
                          <Grid item mr={1}>
                            <Typography variant="tag1">Rank: </Typography>
                          </Grid>
                          {RankOpt?.map((rank, index) => (
                            <Grid
                              item
                              key={index}
                              className={
                                question?.rank !== rank ? styles.rank : styles.rank_selected
                              }
                              style={{
                                marginRight: index !== RankOpt.length - 1 ? "12px" : "0px"
                              }}
                              onClick={() => handleRank(question, rank)}>
                              {rank}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {questions?.length - 1 > index && <Divider sx={style.divider} />}
            </Fragment>
          ))
        ) : (
          <Typography variant="h2" className="d-flex justify-content-center">
            No Questions Found
          </Typography>
        )}
        {showPagination && pages > 0 && (
          <AppPagination
            onPageChange={setCurrentPage}
            totalPages={pages}
            currentPage={currentPage}
          />
        )}
      </Card>
      {questions?.length > 0 && !isVIP && (
        <>
          <Col
            lg="12"
            className={
              isTiriva || !isVIP ? "d-flex justify-content-end" : "d-flex justify-content-between"
            }>
            <AppButton
              styles={
                isTiriva
                  ? { paddingLeft: 47, paddingRight: 47 }
                  : { paddingLeft: 13, paddingRight: 13 }
              }
              disabled={checkButtonDisable() ? false : true}
              onClick={
                isTiriva ? handleAddQuestionsToTrivia : isDMV ? handleAddQuestions : handleAddToBank
              }>
              {isTiriva ? btnText.triviaText : btnText.questionBank}
            </AppButton>

            {isAutoSuggest && (
              <AppButton styles={{ cursor: "pointer" }} onClick={() => handleFlaggedButton()}>
                Flagged Questions
              </AppButton>
            )}
          </Col>
        </>
      )}
    </Fragment>
  )
}

export default QuestionsBanks
