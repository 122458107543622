import { Box, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import AppSelect from "components/StyledComponents/AppSelect"
import AppTextField from "components/StyledComponents/AppTextField"
import DraggableOptions from "../../../../../../components/DraggableOptions"
import ImageIcon from "assets/icons/ImageIcon.svg"
import ImageUploadPopup from "../ImageUploadPopup"
import { useDispatch, useSelector } from "react-redux"
import { uploadQuestionImageAction } from "redux/L&D/actions"
import CloseIcon from "assets/icons/closeIcon.svg"
import { learningDevelopmentActions } from "redux/L&D/slice/user"

const difficultyLevelChoices = [
  { label: "Beginner", value: "Beginner" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Advance", value: "Advance" }
]

const QuestionForm = ({ formik, questionIndex, questionImageData }) => {
  const [questionText, setQuestionText] = useState("")
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState()
  const dispatch = useDispatch()
  const { triviaCategories } = useSelector(
    (store) => store?.learningDevelopment?.trivia?.triviaContest
  )

  const onImagePopupOpen = () => {
    setIsImagePopupOpen(true)
  }

  const onImagePopupClose = () => {
    setIsImagePopupOpen(false)
  }

  const questionErrors =
    (formik.errors.questionSet &&
      formik.errors.questionSet.length &&
      formik.errors.questionSet[questionIndex]) ||
    {}
  const questionTouched =
    (formik.touched.questionSet &&
      formik.touched.questionSet.length &&
      formik.touched.questionSet[questionIndex]) ||
    {}

  const getOptionValues = (values = formik.values) => {
    return values?.questionSet?.[questionIndex]?.optionSet
  }

  const getOptionPath = () => {
    return `questionSet[${questionIndex}].optionSet`
  }

  const onQuestionTextChange = (e) => {
    setQuestionText(e.target.value)
    const timeOutId = setTimeout(
      () => formik.setFieldValue(`questionSet[${questionIndex}].question`, e.target.value),
      800
    )
    return () => clearTimeout(timeOutId)
  }

  const onImageUpload = (newImage) => {
    let data = new FormData()
    data.append("media", newImage)
    data.append("media_type", "IMAGE")
    data.append("content_type", newImage.type)

    dispatch(
      uploadQuestionImageAction({
        index: questionIndex,
        data: data
      })
    )
  }

  const onImageDelete = () => {
    setSelectedImage(null)
    dispatch(
      learningDevelopmentActions.setUploadedImages({
        index: questionIndex,
        data: null
      })
    )
  }

  const onCategorySelect = (e) => {
    formik.setFieldValue(`questionSet[${questionIndex}].category`, e.value)
    formik.setFieldValue(`questionSet[${questionIndex}].categoryName`, e.label)
    if (e.label === "Special") {
      formik.setFieldValue(`questionSet[${questionIndex}].filterCategory`, "Special")
    } else if (e.label === "Wine Region") {
      formik.setFieldValue(`questionSet[${questionIndex}].filterCategory`, "Wine Region")
    } else {
      formik.setFieldValue(`questionSet[${questionIndex}].filterCategory`, "")
    }
  }

  const onFilterCategorySelect = (e) => {
    formik.setFieldValue(`questionSet[${questionIndex}].filterCategory`, e.value)
  }

  useEffect(() => {
    setQuestionText(formik.values?.questionSet?.[questionIndex]?.question ?? "")
  }, [])

  function getMenuCategory(name) {
    if (name === "Beverage") {
      return [
        {
          label: "Bar",
          value: "Bar"
        },
        {
          label: "Spirits",
          value: "Spirits"
        },
        {
          label: "Wine By Glass",
          value: "Wine By Glass"
        },
        {
          label: "Wine By Bottle",
          value: "Wine By Bottle"
        }
      ]
    } else if (name === "Food") {
      return [
        {
          label: "Lunch",
          value: "Lunch"
        },
        {
          label: "Dinner",
          value: "Dinner"
        },
        {
          label: "Brunch",
          value: "Brunch"
        },
        {
          label: "Breakfast",
          value: "Breakfast"
        },
        {
          label: "Specials",
          value: "Specials"
        }
      ]
    } else if (name === "Wine Region") {
      {
        return [
          {
            label: "Wine Region",
            value: "Wine Region"
          }
        ]
      }
    } else {
      return [
        {
          label: "Special",
          value: "Special"
        }
      ]
    }
  }

  return (
    <React.Fragment>
      <ImageUploadPopup
        {...{ setSelectedImage, selectedImage, onImageDelete, onImageUpload }}
        open={isImagePopupOpen}
        onClose={onImagePopupClose}
        aspectHeight={1}
        aspectWidth={1}
      />
      <Box sx={{ padding: "0 11px" }}>
        <Grid container spacing={4}>
          <Grid item md={4} sm={4}>
            <AppSelect
              id={`questionSet[${questionIndex}].category`}
              name={`questionSet[${questionIndex}].category`}
              menuItems={
                triviaCategories?.map(({ id, name }) => ({
                  label: name,
                  value: id
                })) ?? []
              }
              label="Trivia Category"
              isRequired={true}
              value={formik?.values?.questionSet?.[questionIndex]?.category}
              onMenuClick={onCategorySelect}
              error={questionTouched.category && questionErrors.category}
              touched={questionTouched.category}
            />
          </Grid>
          <Grid item md={4} sm={4}>
            <AppSelect
              id={`questionSet[${questionIndex}].filterCategory`}
              name={`questionSet[${questionIndex}].filterCategory`}
              menuItems={getMenuCategory(
                formik?.values?.questionSet?.[questionIndex]?.categoryName
              )}
              label="Menu Category"
              isRequired={true}
              value={formik?.values?.questionSet?.[questionIndex]?.filterCategory}
              onMenuClick={onFilterCategorySelect}
              error={questionTouched.filterCategory && questionErrors.filterCategory}
              touched={questionTouched.filterCategory}
            />
          </Grid>
          <Grid item md={4} sm={4}>
            <AppSelect
              id={`questionSet[${questionIndex}].difficultyLevel`}
              name={`questionSet[${questionIndex}].difficultyLevel`}
              menuItems={difficultyLevelChoices}
              label="Difficulty Level"
              isRequired={true}
              value={formik?.values?.questionSet?.[questionIndex]?.difficultyLevel}
              onChange={formik.handleChange}
              error={questionTouched.difficultyLevel && questionErrors.difficultyLevel}
              touched={questionTouched.difficultyLevel}
            />
          </Grid>
        </Grid>
        <Box height={35} />
        <Typography variant="h2" sx={{ fontSize: 20 }}>
          Question
        </Typography>
        <Box height={19} />
        <Box className="d-flex align-items-start mb-2">
          <AppTextField
            placeholder="Enter Question"
            key={`questionSet[${questionIndex}].question`}
            value={questionText}
            onChange={onQuestionTextChange}
            className="flex-grow-1 flex-shrink-1"
            fullWidth
            error={questionTouched.question && Boolean(questionErrors.question)}
            helperText={questionTouched.question && questionErrors.question}
          />
          <img
            role="button"
            src={ImageIcon}
            onClick={onImagePopupOpen}
            className="flex-shrink-0 ms-4"
            alt="Upload Image"
          />
        </Box>
        {questionImageData?.url ? (
          <Box className="mt-4 position-relative" sx={{ width: "fit-content" }}>
            <img style={{ height: 182, borderRadius: 6 }} src={questionImageData.url} alt="" />
            <img
              role="button"
              src={CloseIcon}
              alt=""
              className="position-absolute"
              style={{ top: 12, right: 12 }}
              onClick={onImageDelete}
            />
          </Box>
        ) : null}
        <DraggableOptions {...{ formik, getOptionValues, getOptionPath }} />
      </Box>
    </React.Fragment>
  )
}

export default QuestionForm
