import React from "react"
import { Grid, Typography, useTheme, Slider } from "@mui/material"
import { makeStyles } from "@material-ui/core"
import AppTextField from "components/StyledComponents/AppTextField"
import { useSelector } from "react-redux"

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSlider-thumb": {
      color: "var(--status-negative, #CC4B37)"
    },
    "& .MuiSlider-mark": {
      display: "none"
    }
  }
}))

const GenerateQuestions = ({ noOfQuestion, setNoOfQuestions }) => {
  const theme = useTheme()
  const sliderClass = useStyles()
  const { prompt } = useSelector((store) => store?.dmv)

  const handleQuestionCount = (event) => {
    setNoOfQuestions(event.target.value)
  }

  function valuetext(value) {
    return `${value}`
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2Italic" color={theme.palette.text.secondary}>
          Step-4
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1Regular" color={theme.palette.text.secondary}>
          Number of questions
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Slider
          className={sliderClass.root}
          aria-label="Always visible"
          defaultValue={1}
          getAriaValueText={valuetext}
          step={1}
          marks={marks}
          valueLabelDisplay="off"
          color="secondary"
          min={1}
          max={5}
          value={noOfQuestion}
          onChange={handleQuestionCount}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body4Regular">Prompt</Typography>
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          value={prompt}
          placeholder={"Prompt to generate questions"}
          fullWidth
          isDisabled={true}
        />
      </Grid>
    </Grid>
  )
}

const marks = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2"
  },
  {
    value: 3,
    label: "3"
  },
  {
    value: 4,
    label: "4"
  },
  {
    value: 5,
    label: "5"
  }
]
export default GenerateQuestions
