import React from "react"
import { Grid, Typography, useTheme } from "@mui/material"
import VIPTable from "./VIPTable"
import { useSelector } from "react-redux"

const ChooseCorrectVIP = ({ selectedRows, setSelectedRows }) => {
  const theme = useTheme()
  const { VIPProfileList } = useSelector((state) => state?.dmv)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2Italic" color={theme.palette.text.secondary}>
          Step-2
        </Typography>
      </Grid>
      {VIPProfileList?.map((VIP, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12}>
            <Typography variant="body4Regular" color={theme.palette.text.secondary}>
              {`Select Correct VIP - ${VIP?.name}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VIPTable
              columns={columns}
              rows={VIP?.data}
              selectedRow={selectedRows[index]?.vip_id}
              setSelectedRow={(id) =>
                setSelectedRows((prev) => {
                  const newSelectedRows = [...prev]
                  newSelectedRows[index] = { vip_id: id, id: VIP.id }
                  return newSelectedRows
                })
              }
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}
export default ChooseCorrectVIP

const columns = [
  {
    id: "1",
    label: "Image",
    minWidth: 30
    // align: "center"
  },
  {
    id: "2",
    label: "Name",
    minWidth: 180
    // align: "center"
  },
  {
    id: "3",
    label: "Company",
    minWidth: 300
    // align: "center"
  },
  {
    id: "4",
    label: "Role",
    minWidth: 400
    // align: "center"
  }
]
