import React, { useEffect, useState } from "react"
import { Box, Typography, Grid, IconButton, useTheme } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import styles from "./generateVipQuestions.module.scss"
import AppButton from "components/StyledComponents/AppButton"
import SelectVIP from "./components/selectVIP"
import alternate from "assets/images/imagePlaceholder.png"
import ChooseCorrectVIP from "./components/ChooseCorrectVIP"
import CloseIcon from "@mui/icons-material/Close"
import {
  confirmVIPImageAction,
  generateVIPQuestionAction,
  getGenerateQuestionPromptAction,
  getVIPImageAction,
  getVIPListAction,
  getVIPProfileAction
} from "redux/DMV/actions/user"
import { useDispatch, useSelector } from "react-redux"
import ChooseCorrectImage from "./components/chooseCorrectImage"
import GenerateQuestions from "./components/generateQuestions"
import QuestionsBanks from "pages/private/learning_development/QuestionBank/components/QuestionBanks"
import { convertTo12HourFormat } from "helpers/functions"
import { DMVActions } from "redux/DMV/slice/user"

const GenerateVipQuestions = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [breadCrumbs, setBreadcrumbs] = useState("")
  const [selectedVips, setSelectedVips] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [noOfQuestion, setNoOfQuestions] = useState(1)
  const { generatedVIPQuestions } = useSelector((state) => state?.dmv || [])
  const { VIPImageData, VIPRestaurant } = useSelector((state) => state?.dmv)
  // const [finalVips, setFinalVips] = useState(VIPImageData)

  const dispatch = useDispatch()
  const theme = useTheme()

  const Step1 = () => <SelectVIP selectedVips={selectedVips} setSelectedVips={setSelectedVips} />
  const Step2 = () => (
    <ChooseCorrectVIP
      SelectVips={selectedVips}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
    />
  )
  const Step3 = () => (
    <ChooseCorrectImage selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
  )
  const Step4 = () => (
    <GenerateQuestions noOfQuestion={noOfQuestion} setNoOfQuestions={setNoOfQuestions} />
  )
  const steps = [Step1, Step2, Step3, Step4]

  const isNextButtonEnabled = () => {
    switch (currentStep) {
      case 0:
        return selectedVips?.length > 0
      case 1:
        return selectedRows?.length > 0 && selectedRows?.length === selectedVips?.length
      case 2:
        return selectedVips?.length > 0
      default:
        return false
    }
  }
  const mergeArrays = (array1, array2 = []) => {
    const includeImages = array2.length > 0

    return array1?.map((item, index) => ({
      generated_vip_id: item?.vip_id,
      ...(includeImages && { selected_image_id: String(array2[index]) })
    }))
  }

  const fetchStepData = (step) => {
    switch (step) {
      case 0:
        dispatch(getVIPListAction({ id: VIPRestaurant }))
        break
      case 1:
        if (selectedVips?.length > 0) {
          const payload = {
            guest_vip_ids: selectedVips?.map((vip) => vip.id)
          }
          setSelectedRows([])
          dispatch(getVIPProfileAction(payload))
        }
        break
      case 2:
        if (selectedVips?.length > 0) {
          const selectedVipIds = selectedVips?.map((vip) => vip.id)
          const validSelectedRows = selectedRows?.filter((row) => selectedVipIds?.includes(row.id))
          const payload = {
            guest_vip_ids: validSelectedRows?.map((row) => row?.vip_id)
          }
          setSelectedImages([])
          dispatch(getVIPImageAction(payload))
        }
        break
      default:
        break
    }
  }

  const NextStep = () => {
    if (currentStep === 0) {
      if (selectedVips?.length > 0) {
        const payload = {
          guest_vip_ids: selectedVips?.map((vip) => vip?.id)
        }
        dispatch(getVIPProfileAction(payload))
      }
    } else if (currentStep === 1) {
      if (selectedRows?.length > 0) {
        const payload = {
          guest_vip_ids: selectedRows?.map((row) => row?.vip_id)
        }
        dispatch(getVIPImageAction(payload))
      }
    } else if (currentStep === 2) {
      if (selectedVips?.length > 0) {
        const payload = {
          vip_updates: mergeArrays(selectedRows, selectedImages)
        }
        dispatch(confirmVIPImageAction(payload))
        dispatch(getGenerateQuestionPromptAction())
      }
    }
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1)
  }

  const PrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => {
        const newStep = prev - 1
        switch (newStep) {
          case 1:
            setSelectedRows([])
            break
          case 2:
            setSelectedImages([])
            break
        }

        fetchStepData(newStep)
        return newStep
      })
    }
  }
  const StepComponent = steps[currentStep]

  const handleRemoveMenuItem = (ID) => {
    if (selectedVips.length !== 1) {
      const updatedVips = selectedVips.filter((item) => item?.id !== ID)
      setSelectedVips(updatedVips)
      if (currentStep === 1) setSelectedRows([])
      if (currentStep === 2) setSelectedImages([])
    }
  }

  // const handleRemoveItem = (ID) => {
  //   if (selectedVips?.length !== 1) {
  //     const updatedVips = selectedVips?.filter((item) => item?.id !== ID)
  //     setFinalVips(updatedVips)
  //     if (currentStep === 1) setSelectedRows([])
  //     if (currentStep === 2) setSelectedImages([])
  //   }
  // }

  const generateQuestions = () => {
    const payload = {
      vip_ids: selectedVips?.map((vip) => vip?.id),
      question_number: noOfQuestion,
      platform: "VIP",
      restaurant_id: VIPRestaurant
    }
    dispatch(generateVIPQuestionAction(payload))
  }

  useEffect(() => {
    if (currentStep === 1) {
      if (selectedVips?.length > 0) {
        const payload = {
          guest_vip_ids: selectedVips.map((vip) => vip.id)
        }
        dispatch(getVIPProfileAction(payload))
      }
    } else if (currentStep === 2) {
      if (selectedVips?.length > 0) {
        const selectedVipIds = selectedVips?.map((vip) => vip.id)
        const validSelectedRows = selectedRows?.filter((row) => selectedVipIds?.includes(row.id))
        const payload = {
          guest_vip_ids: validSelectedRows?.map((row) => row?.vip_id)
        }
        dispatch(getVIPImageAction(payload))
      }
    }
  }, [selectedVips])

  // useEffect(() => {
  //   if (currentStep === 3) {
  //     if (finalVips?.length > 0) {
  //       const selectedVipIds = finalVips?.map((vip) => vip.id)
  //       const validSelectedRows = selectedRows?.filter((row) => selectedVipIds?.includes(row.id))
  //       console.log("valid", validSelectedRows)
  //       console.log("rows", selectedRows)

  //       // const payload = {
  //       //   vip_updates: mergeArrays(selectedRows, selectedImages)
  //       // }
  //       // dispatch(confirmVIPImageAction(payload))
  //       // dispatch(getGenerateQuestionPromptAction())
  //     }
  //   }
  // }, [finalVips])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Generate VIP Questions"
    dispatch(getVIPListAction({ id: VIPRestaurant }))
    dispatch(DMVActions.setGeneratedVIPQuestions([]))
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header title="Generate VIP Questions" breadCrumbs={breadCrumbs} />
      </Grid>
      <Grid item xs={12}>
        <Box className={styles.title}>
          <Typography variant="h2Italic" color={"#C4C4C4"}>
            Today’s Guest List
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={styles.container}>
          {currentStep !== 0 && currentStep !== 3 ? (
            <Grid container spacing={2} mb={4}>
              {selectedVips?.map((item) => (
                <Grid item xs={3} lg={3} key={item?.id}>
                  <Box className={styles.itemsContainer}>
                    {item?.image_url && (
                      <img
                        className={styles.selectedItemsImage}
                        src={item?.image_url || item?.image || alternate}
                        alt={item?.name}
                      />
                    )}
                    <Box className={styles.selectedItemsText}>
                      <Typography variant="body4Regular">
                        {`${item?.name || "-"} - ${
                          convertTo12HourFormat(item?.arriving_time) || "-"
                        }`}
                      </Typography>

                      <Typography variant="body4Regular" color={"#C4C4C4"} textAlign="left">
                        {`${item?.designation || "No Designation"} 
                        `}
                      </Typography>
                      <Typography variant="body4Regular">{`${
                        item?.work_company || "No Company"
                      }`}</Typography>
                    </Box>
                    {selectedVips.length > 1 && (
                      <IconButton
                        sx={{
                          color: theme.palette.text.primary
                        }}
                        onClick={() => handleRemoveMenuItem(item?.id)}>
                        <CloseIcon className={styles.closeIcon} />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : currentStep === 3 && VIPImageData ? (
            <Grid container spacing={2} mb={4}>
              {VIPImageData?.map((item) => (
                <Grid item xs={3} lg={3} key={item?.id}>
                  <Box
                    className={item?.selected_image ? styles.itemContainer : styles.itemsContainer}>
                    {item?.selected_image && (
                      <img
                        className={styles.selectedItemsImage}
                        src={item?.selected_image?.url || item?.selected_image?.url || alternate}
                        style={{ width: 70, height: 70, borderRadius: "50%" }}
                        alt={item?.name}
                      />
                    )}
                    <Box className={styles.selectedItemsText}>
                      <Typography variant="body4Regular">
                        {`${item?.name || "-"} - ${
                          convertTo12HourFormat(item?.arriving_time) || "-"
                        }`}
                      </Typography>

                      <Typography variant="body4Regular" color={"#C4C4C4"} textAlign="left">
                        {`${item?.designation || "No Designation"} 
                        `}
                      </Typography>
                      <Typography variant="body4Regular">{`${
                        item?.company || "No Company"
                      }`}</Typography>
                    </Box>
                    {selectedVips.length > 1 && (
                      <IconButton
                        sx={{ color: theme.palette.text.primary }}
                        onClick={() => handleRemoveMenuItem(item?.id)}>
                        <CloseIcon sx={{ height: "20px", width: "20px" }} />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : null}

          <StepComponent />
        </Box>
      </Grid>
      {currentStep !== 3 ? (
        <Grid item xs={12} mt={3}>
          {currentStep != 0 && (
            <AppButton variant="contained" onClick={PrevStep} sx={{ mr: 2 }}>
              <span>
                <ArrowBackIcon style={{ marginRight: 8 }} />
              </span>
              Previous
            </AppButton>
          )}
          <AppButton variant="contained" onClick={NextStep} disabled={!isNextButtonEnabled()}>
            Next
            <span>
              <ArrowForwardIcon style={{ marginLeft: 8 }} />
            </span>
          </AppButton>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} mt={3}>
            <AppButton onClick={generateQuestions}>Generate Questions</AppButton>
          </Grid>
          {generatedVIPQuestions?.length > 0 && (
            <Grid item xs={12} mt={3}>
              <QuestionsBanks
                title="Question Bank"
                questions={generatedVIPQuestions}
                showImage={true}
                showPagination={false}
                isVIPGenerated={true}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default GenerateVipQuestions
