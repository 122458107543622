import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import Typography from "@mui/material/Typography"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteWineItemAction, getWineItemsAction } from "redux/marketing/actions"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"
import AddMenuItems from "pages/private/marketing/menu/AddMenuItems"
import { unArchiveWineItemsAction, unarchiveWineItemsAction } from "redux/marketing/actions/user"
import Card from "components/Card/Card"
import { Col, Row } from "react-bootstrap"
import { formatMenuDate, isEmptyObject } from "helpers/functions"
import selectedItem from "assets/icons/SelectedRectange.svg"
import SelectAll from "assets/icons/unSelectedRectange.svg"
import UnarchiveItem from "assets/icons/UnarchiveItem.svg"
import { toast } from "react-toastify"
import unSelect from "assets/icons/unSelectedRectange.svg"
import trashIcon from "assets/icons/trash.svg"
import editIcon from "assets/icons/edit.svg"
import WineVideoPlayer from "components/WineVideoPlayer"

function ArchivedWineListItems({ data, isEdit, categoryIdData = () => {} }) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState({})
  const dispatch = useDispatch()

  const handleClick = (type, id, categoryId) => {
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getWineItemsAction())
    if (type == "edit") {
      handleEditMenuItemModal()
    } else if (type == "archive") {
      handleArchiveItem()
    } else {
      handleMenuDeleteModal()
    }
  }

  function handleMenuItemDelete() {
    dispatch(deleteWineItemAction())
    dispatch(marketingActions.setisArchive(false))
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleArchiveItem() {
    setUnarchiveItemModal(true)
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(unarchiveWineItemsAction(payload))
  }

  useEffect(() => {}, [])

  function handleMenuDeleteModal() {
    setDeleteModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleEditMenuItemModal() {
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  const handleSelect = (item) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems(item)
    } else {
      setSelectedItems({})
    }
  }

  const handleUnArchive = () => {
    if (isEmptyObject(selectedItems)) {
      toast.error("Please select items to unarchive!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    } else {
      const id_dict = selectedItems?.food_list?.map((item) => ({
        id: item.id,
        food_type: "wine"
      }))
      const payload = {
        id_dict
      }
      const clearState = () => {
        setSelectedItems({})
      }
      dispatch(unArchiveWineItemsAction({ payload, setSelectedItems: clearState }))
    }
  }

  const checkSelectedItems = (id) => {
    const isItemSelected = selectedItems?.food_list?.filter((item) => item?.id === id)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleSelectItem = (id) => {
    if (isEmptyObject(selectedItems)) {
      setSelectedItems({ ...data, food_list: data?.food_list?.filter((item) => item?.id === id) })
    } else {
      const itemsData = selectedItems?.food_list?.filter((item) => item?.id === id)
      if (itemsData?.length > 0) {
        if (selectedItems?.food_list?.length === 1) {
          setSelectedItems({})
        } else {
          setSelectedItems({
            ...selectedItems,
            food_list: selectedItems?.food_list?.filter((item) => item?.id !== id)
          })
        }
      } else {
        setSelectedItems({
          ...selectedItems,
          food_list: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...selectedItems?.food_list,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...data?.food_list?.filter((item) => item?.id === id)
          ]
        })
      }
    }
  }

  return (
    <>
      <AddMenuItems isEdit={isEditModalItem} open={menuItemsModal} onClose={handleCloseMenuItems} />
      <DeleteConfirmationDialog
        title={`Are you sure you want to delete this wine ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are you sure you want to unarchive this wine?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <img
                src={
                  selectedItems?.food_list?.length === data?.food_list?.length
                    ? selectedItem
                    : SelectAll
                }
                alt="selectAll"
                style={{ cursor: "pointer" }}
                onClick={() => handleSelect(data)}
              />
              <Typography variant="h2">{data?.food_category}</Typography>
              <div className="d-flex align-items-center">
                <img
                  src={UnarchiveItem}
                  alt="unarchive"
                  style={{ cursor: "pointer" }}
                  onClick={handleUnArchive}
                />
              </div>
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
                      borderBottom: `${
                        !isEdit ? `1px solid #605e5e` : `.5px solid ${theme.palette.secondary.main}`
                      }`,
                      borderRadius: `${isEdit ? "6px" : "0px"}`,
                      padding: `${isEdit ? "8px" : "5px"}`,
                      margin: "24px 0"
                    }}>
                    <div className={classes.archivedItemContainer} style={{ margin: 0 }}>
                      <div className={classes.checkbox}>
                        {checkSelectedItems(item?.id) ? (
                          <img
                            src={selectedItem}
                            alt="select"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSelectItem(item?.id)}
                          />
                        ) : (
                          <img
                            src={unSelect}
                            alt="select"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleSelectItem(item?.id)}
                          />
                        )}
                      </div>
                      <div className={classes.imageContainer} style={{ height: 250 }}>
                        {item?.video ? (
                          <WineVideoPlayer video={item} />
                        ) : (
                          <ImageDisplay data={item?.image_url}></ImageDisplay>
                        )}
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          flexDirection: "column",
                          maxWidth: 375,
                          overflowWrap: "break-word"
                        }}>
                        <div key={item.id}>
                          <Typography variant="h4">{item?.name ?? "-"}</Typography>
                        </div>

                        <div>
                          {item?.data_to_display?.map((data, index) => {
                            return (
                              <div key={index}>
                                {data.filedName === "Tasting Notes"
                                  ? (data?.value?.length > 0 || isEdit) && (
                                      <div className="mt-2 d-flex flex-column">
                                        <Typography variant="menuLabel">
                                          {data.filedName}
                                        </Typography>
                                        <Typography variant="menuLabelItalic" sx={{ opacity: 0.6 }}>
                                          {data?.value?.length > 0
                                            ? data?.value?.map((item, index) => {
                                                return data?.value?.length - 1 > index
                                                  ? `${item?.name}, `
                                                  : `${item?.name}`
                                              })
                                            : ""}
                                        </Typography>
                                      </div>
                                    )
                                  : data.filedName === "Notes"
                                  ? (data?.value || isEdit) && (
                                      <div className="mt-2 d-flex flex-column">
                                        <Typography variant="menuLabel">{"Notes:"}</Typography>
                                        <Typography
                                          component="div"
                                          variant="menuLabelItalic"
                                          sx={{ opacity: 0.6 }}>
                                          {
                                            <Paragraph
                                              text={data?.value ?? ""}
                                              maxCharCount={70}></Paragraph>
                                          }
                                        </Typography>
                                      </div>
                                    )
                                  : data.filedName === "Ingredients"
                                  ? (data?.value?.length > 0 || isEdit) && (
                                      <div className="mt-2">
                                        <Typography variant="tag1">{data.filedName}</Typography>
                                        <br></br>

                                        {typeof data?.value !== "string" ? (
                                          data?.value?.map((item, index) => (
                                            <Typography
                                              key={index}
                                              sx={{
                                                opacity: 0.6,
                                                fontSize: 12,
                                                fontWeight: "400",
                                                fontStyle: "italic",
                                                display: item?.name ? "list-item" : "",
                                                marginLeft: 2
                                              }}>
                                              {`${item?.name ?? ""}`}
                                              {item?.measurement
                                                ? data?.value?.length - 1 > index
                                                  ? ` (${item?.measurement})`
                                                  : item?.measurement
                                                  ? ` (${item?.measurement}) `
                                                  : ""
                                                : data?.value?.length - 1 > index
                                                ? ", "
                                                : ""}
                                            </Typography>
                                          ))
                                        ) : (
                                          <div>
                                            {data.value.split(",").map((ingredient, index) => (
                                              <Typography
                                                key={index}
                                                sx={{
                                                  opacity: 0.6,
                                                  fontSize: 12,
                                                  fontWeight: "400",
                                                  fontStyle: "italic",
                                                  display: ingredient ? "list-item" : "",
                                                  marginLeft: 2
                                                }}>
                                                {data.value.split(",")?.length - 1 > index
                                                  ? `${ingredient.trim()},`
                                                  : ingredient.trim()}
                                              </Typography>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  : data.filedName === "Unique Facts"
                                  ? (data?.value?.length || isEdit) && (
                                      <div className="mt-2">
                                        <Typography variant="tag1">{data.filedName}</Typography>
                                        <br></br>
                                        {data?.value?.length === 1
                                          ? data?.value?.map((item, index) =>
                                              item?.fact !== "" ? (
                                                <Typography
                                                  component="div"
                                                  style={{
                                                    display: item?.fact ? "list-item" : "",
                                                    marginLeft: 15
                                                  }}
                                                  key={index}
                                                  variant="tagItalic"
                                                  sx={{ opacity: 0.6 }}>
                                                  <Paragraph
                                                    text={item?.fact ?? ""}
                                                    maxCharCount={70}
                                                    variant="tagItalic"
                                                    sx={{ opacity: 0.6 }}
                                                  />
                                                </Typography>
                                              ) : null
                                            )
                                          : data?.value?.map((item, index) => (
                                              <Typography
                                                component="div"
                                                style={{
                                                  display: item?.fact ? "list-item" : "",
                                                  marginLeft: 15
                                                }}
                                                key={index}
                                                variant="tagItalic"
                                                sx={{ opacity: 0.6 }}>
                                                <Paragraph
                                                  text={item?.fact ?? ""}
                                                  maxCharCount={70}
                                                  key={index}
                                                  variant="tagItalic"
                                                  sx={{ opacity: 0.6 }}></Paragraph>
                                              </Typography>
                                            ))}
                                      </div>
                                    )
                                  : (data?.value || isEdit) && (
                                      <div className="mt-2 d-flex flex-column">
                                        <Typography variant="menuLabel">
                                          {data.filedName}
                                        </Typography>
                                        <Typography
                                          component="div"
                                          variant="menuLabelItalic"
                                          sx={{ opacity: 0.6 }}>
                                          {typeof data?.value === "string" &&
                                          data.value.includes("\n") ? (
                                            data.value
                                              .split("\n")
                                              .map((line, index) =>
                                                line.length > 70 ? (
                                                  <Paragraph
                                                    key={index}
                                                    text={line}
                                                    maxCharCount={70}
                                                  />
                                                ) : (
                                                  <div key={index}>{line}</div>
                                                )
                                              )
                                          ) : typeof data?.value === "string" &&
                                            data.value.length > 70 ? (
                                            <Paragraph text={data.value} maxCharCount={70} />
                                          ) : (
                                            data?.value || "-"
                                          )}
                                        </Typography>
                                      </div>
                                    )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          {item?.price > 0 && (
                            <Typography variant="bold">${item?.price}</Typography>
                          )}
                        </div>
                        <div className={classes.actionButtonContainer}>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              src={editIcon}
                              alt="edit"
                              onClick={() => handleClick("edit", item?.id, categoryIdData)}
                              className={classes.menu_action_icon}
                            />
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              src={UnarchiveItem}
                              alt="unarchive"
                              className={classes.menu_action_icon}
                              onClick={() => handleClick("archive", item?.id, categoryIdData)}
                            />
                          </div>
                          <div className="d-grid justify-content-center mt-1">
                            <img
                              onClick={() => handleClick("delete", item?.id, categoryIdData)}
                              className={classes.menu_action_icon}
                              src={trashIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-end align-items-end">
                      <div>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: 14,
                            fontWeight: "400"
                          }}>
                          Added:{" "}
                          <Typography
                            sx={(theme) => ({
                              fontSize: 14,
                              fontWeight: "400",
                              color: theme.palette.orange.tertiary,
                              cursor: "pointer"
                            })}
                            variant="tag1">
                            {formatMenuDate(item?.created_at)}
                          </Typography>
                        </Typography>
                      </div>
                      {item?.updated_at ? (
                        <div>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: 14,
                              fontWeight: "400"
                            }}>
                            Updated:{" "}
                            <Typography
                              sx={(theme) => ({
                                fontSize: 14,
                                fontWeight: "400",
                                color: theme.palette.orange.tertiary,
                                cursor: "pointer"
                              })}
                              variant="tag1">
                              {formatMenuDate(item?.updated_at)}
                            </Typography>
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ArchivedWineListItems
