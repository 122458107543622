import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import Header from "components/Header/Header"
import { Col } from "react-bootstrap"
import { Typography, styled, Grid, Box } from "@mui/material"
import AppDatePicker from "components/AppDatePicker"
import { Formik } from "formik"
import AppTextArea from "components/StyledComponents/AppTextArea"
import { validationSchemaRaffleTicket } from "helpers/validationSchema"
import AppButton from "components/StyledComponents/AppButton"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { editRaffleContestAction, getRaffleSingleDataAction } from "redux/marketing/actions"
import { useHistory } from "react-router-dom"
import AppTextField from "components/StyledComponents/AppTextField"
import { generateMonths } from "helpers/functions"
import AppSelect from "components/StyledComponents/AppSelect"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))
const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}

function EditRaffleContest() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialValuesRaffle, setRaffleValues] = useState({})
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false)
  const [raffleData, setRaffleData] = useState({})
  const dispatch = useDispatch()
  const history = useHistory()
  const zone_name = moment.tz.guess()

  const { getSingleRaffle } = useSelector((state) => state?.marketing?.raffleContestData || {})
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Configuration"
  }, [])

  function convertDate(date, time) {
    const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    return newDate.toUTCString()
  }

  function initDateConvert(date, time) {
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().toDate()
  }

  function payloadDate(dateArray = [], timeStamp) {
    console.log(timeStamp)
    return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
  }

  /**
   * This function sets the data to form UI
   */
  function setDataToUi() {
    if (!getSingleRaffle) return
    const raffleTimeStamp = initDateConvert(getSingleRaffle?.start_date, getSingleRaffle?.time)
    // const dinnerTimeStamp = initDateConvert(getSingleRaffle?.wine_club_dinner, getSingleRaffle?.dinner_time)
    const raffleStartTime = moment(raffleTimeStamp).format("HH:mm:ss")
    // const dinnerTime = moment(dinnerTimeStamp).format("HH:mm:ss")
    const initData = {
      start_date: initDateConvert(getSingleRaffle?.start_date, getSingleRaffle?.time),
      time: raffleStartTime || "",
      // wine_club_dinner: initDateConvert(getSingleRaffle?.wine_club_dinner, getSingleRaffle?.dinner_time),
      description: getSingleRaffle?.description,
      month: getSingleRaffle?.raffle_month
      // dinner_time: dinnerTime || ""
    }
    setRaffleValues(initData)
  }

  /**
   * This function sets the API Payload
   */
  const handleSubmit = (values) => {
    const { start_date, description, time, month } = values

    const RaffleTimeStamp = convertDate(start_date, time)
    // const dinnerTimeStamp = convertDate(wine_club_dinner, dinner_time)
    const RaffleTimeSplit = RaffleTimeStamp.split(" ")
    // const RaffleDinnerTimeSplit = dinnerTimeStamp.split(" ")
    const RaffleStartDate = RaffleTimeSplit.toString().replace(/,/g, " ")
    // const RaffleWinnerDate = RaffleDinnerTimeSplit.toString().replace(/,/g, " ")
    const payloadStartDate = payloadDate(RaffleTimeSplit, RaffleStartDate)
    // const payloadWinnerDate = payloadDate(RaffleDinnerTimeSplit, RaffleWinnerDate)
    const raffle_month = month

    const payload = {
      start_date: payloadStartDate,
      //   dinner_time: RaffleDinnerTimeSplit[4],
      time: RaffleTimeSplit[4],
      //   wine_club_dinner: payloadWinnerDate,
      description: description,
      time_zone: zone_name,
      raffle_month: raffle_month
    }
    setRaffleData(payload)
    setIsSubmitDialogOpen(true)
  }

  const editRaffleContest = () => {
    dispatch(editRaffleContestAction(raffleData))
    setRaffleValues({})
    history.push("/marketing/dashboard/contest-configuration")
    setIsSubmitDialogOpen(false)
  }

  useEffect(() => {
    dispatch(getRaffleSingleDataAction())
  }, [])

  useEffect(() => {
    if (Object.keys(getSingleRaffle).length > 0) {
      setDataToUi()
    }
  }, [getSingleRaffle])

  return (
    <>
      <Container fluid className="p-0">
        <Row>
          <Header title="Edit Contest Configuration" back={true} breadCrumbs={breadCrumbs} />
        </Row>
      </Container>
      <Container fluid className="p-0 ">
        <Row>
          <Col lg="12" className="mt-4">
            <Formik
              initialValues={initialValuesRaffle}
              enableReinitialize={true}
              validationSchema={validationSchemaRaffleTicket}
              onSubmit={handleSubmit}>
              {(formik) => (
                <>
                  <DeleteConfirmationDialog
                    open={isSubmitDialogOpen}
                    onClose={() => setIsSubmitDialogOpen(false)}
                    title="Are you sure you want to save changes ?"
                    description=" "
                    btnText="Save"
                    onDelete={() => editRaffleContest()}
                  />
                  <Grid container spacing={3}>
                    <Grid item sm={12} md={6} lg={4} className="datesContainer">
                      <StyledLabel variant="body1Regular">
                        {"Start Date"}
                        <span>*</span>
                      </StyledLabel>
                      <Box className="dateFieldContainer w-100 mt-2">
                        <AppDatePicker
                          minDate={new Date()}
                          maxDate={new Date().setMonth(new Date().getMonth() + 3)}
                          inputFormat="dd MMM yyyy"
                          key={"start_date"}
                          value={formik.values.start_date}
                          onChange={(val) => {
                            formik.setFieldValue("start_date", val)
                            formik.setFieldValue("month", "")
                          }}
                          error={formik.errors.start_date}
                          touched={formik.touched.start_date}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                      <AppTextField
                        id="time"
                        type="time"
                        fullWidth
                        label="Raffle Start Time"
                        isRequired={true}
                        value={formik.values.time}
                        onChange={formik.handleChange("time")}
                        error={formik.touched.time && Boolean(formik.errors.time)}
                        helperText={formik.touched.time && formik.errors.time}
                      />
                    </Grid>
                    {/* <Grid item sm={12} md={6} lg={4} className="datesContainer">
                      <StyledLabel variant="body1Regular">
                        {"Wine Club Dinner Date"}
                        <span>*</span>
                      </StyledLabel>
                      <Box className="dateFieldContainer w-100 mt-2">
                        <AppDatePicker
                          disabled={formik.values.start_date ? false : true}
                          minDate={new Date(formik.values.start_date).setDate(
                            new Date(formik.values.start_date).getDate() + 1
                          )}
                          maxDate={new Date(formik.values.start_date).setMonth(
                            new Date(formik.values.start_date).getMonth() + 2
                          )}
                          inputFormat="dd MMM yyyy"
                          key={"wine_club_dinner"}
                          error={formik.errors.wine_club_dinner}
                          touched={formik.touched.wine_club_dinner}
                          value={formik.values.wine_club_dinner}
                          onChange={(val) => formik.setFieldValue("wine_club_dinner", val)}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                      <AppTextField
                        type="time"
                        fullWidth
                        label="Dinner Time"
                        isRequired={true}
                        value={formik.values.dinner_time}
                        onChange={formik.handleChange("dinner_time")}
                        error={formik.touched.dinner_time && Boolean(formik.errors.dinner_time)}
                        helperText={formik.touched.dinner_time && formik.errors.dinner_time}
                      />
                    </Grid> */}
                    <Grid item sm={12} md={6} lg={4}>
                      <AppTextArea
                        label="Description"
                        isRequired={true}
                        id="description"
                        placeholder="Enter Description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.errors.description}
                        touched={formik.touched.description}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                      <AppSelect
                        label="Month"
                        isRequired={true}
                        id="month"
                        menuItems={generateMonths(formik.values.start_date)}
                        value={formik.values.month}
                        onChange={(e) => {
                          formik.setFieldValue("month", e.target.value)
                        }}
                        error={formik.touched.month && formik.errors.month}
                      />
                    </Grid>
                  </Grid>
                  <Row>
                    <Col lg="2" className="mt-4">
                      <AppButton onClick={formik.handleSubmit}>Submit</AppButton>
                    </Col>
                  </Row>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default EditRaffleContest
