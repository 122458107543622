import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import QuizTable from "./quizTable"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import { getDmvQuizListAction } from "redux/DMV/actions/user"
import { DMVActions } from "redux/DMV/slice/user"
const DmvQuiz = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const { dmvQuizList } = useSelector((state) => state.dmv)

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getDmvQuizListAction())
    document.title = "Quiz"
    dispatch(DMVActions.setQuizId(""))
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header title="DMV Quiz" breadCrumbs={breadCrumbs}>
          <AppButton
            styles={{ padding: "13px 80px" }}
            onClick={() => {
              history.push("/dmv/dashboard/quiz/add-quiz")
            }}>
            Create Quiz
          </AppButton>
        </Header>
      </Grid>
      <Grid item xs={12}>
        <QuizTable quizList={dmvQuizList} columns={columns} />
      </Grid>
    </Grid>
  )
}

export default DmvQuiz

const columns = [
  {
    id: "Quiz Name",
    label: "Quiz Name",
    align: "center",
    minWidth: 100
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 75
  },
  {
    id: "start_date",
    label: "Start Date",
    align: "center",
    minWidth: 75
  },
  {
    id: "end_date",
    label: "End Date",
    align: "center",
    minWidth: 75
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 75
  }
]
