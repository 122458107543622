import React, { useState, useEffect } from "react"
import { Box, IconButton, styled } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const ModalOverlay = styled(Box)(({ isOpen }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  zIndex: 1000,
  display: isOpen ? "flex" : "none",
  justifyContent: "center",
  alignItems: "center"
}))

const PopupContainer = styled(Box)(() => ({
  position: "relative",
  borderRadius: "12px",
  padding: "16px",
  maxWidth: "90vw",
  maxHeight: "90vh"
}))

const CustomcloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: "absolute",
  top: "-30px",
  right: "0px",
  left: "550px",
  color: theme.palette.text.primary,
  width: "30px",
  height: "30px",
  cursor: "pointer"
}))

function YoutubeFrame({
  videoLoaded,
  setVideoLoaded,
  url,
  style = {},
  isPopup = false,
  isOpen = false,
  onClose = () => {}
}) {
  const [popupOpen, setPopupOpen] = useState(isOpen)

  useEffect(() => {
    setPopupOpen(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setPopupOpen(false)
    onClose()
  }

  const styles = {
    youtube: {
      borderRadius: "12px",
      height: "250px",
      display: videoLoaded ? "block" : "none",
      ...style
    }
  }

  const iframe = (
    <iframe
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      onLoad={() => setVideoLoaded(true)}
      style={styles.youtube}
    />
  )

  if (!isPopup) {
    return iframe
  }

  return (
    <ModalOverlay isOpen={popupOpen} onClick={handleClose}>
      <PopupContainer onClick={(e) => e.stopPropagation()}>
        <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
          <CustomcloseIcon onClick={handleClose} />
        </IconButton>
        {iframe}
      </PopupContainer>
    </ModalOverlay>
  )
}

export default YoutubeFrame
