import React, { useEffect, useRef, useState } from "react"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from "@mui/material"
import AppPagination from "components/StyledComponents/AppPagination"
import styles from "./notifications.module.scss"
import { toTitleCase } from "helpers/functions"
import TableNoRecords from "components/TableNoRecords"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import AppButton from "components/StyledComponents/AppButton"
import avatar from "assets/images/user/avatarUser.svg"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { resendNotificationAction } from "redux/DMV/actions/user"

const PushNotificationListTable = ({
  columns = [],
  rows = [],
  setPage,
  currentPage,
  totalPage
}) => {
  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")
  const dispatch = useDispatch()
  const rowId = useRef(null)
  const [resendNotification, setResendNotification] = useState(false)

  const handleSortDirection = (dir, id) => {
    setSortDirection(dir)
    handleSort(dir, id)
  }

  const handleSort = (dir, id) => {
    if (dir === "asc") {
      setSort(id)
      dispatch(appActions.setOrderBy(id))
    } else {
      setSort(`-${id}`)
      dispatch(appActions.setOrderBy(`-${id}`))
    }
  }

  useEffect(() => {
    dispatch(appActions.setOrderBy("title"))
  }, [])

  function handleResend(id) {
    rowId.current = id
    setResendNotification(true)
  }

  function handleSendNotification() {
    const payload = {
      notification: rowId.current
    }
    dispatch(resendNotificationAction(payload))
    rowId.current = null
  }

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to resend this notification?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setResendNotification(false)}
        onDelete={() => handleSendNotification()}
        open={resendNotification}
        btnText="Resend"
        type="warning"
      />
      <Paper>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.isSorting && (
                        <span style={{ cursor: "pointer" }}>
                          {sortDirection === "desc" && sort === `-${column?.sortKey}` ? (
                            <ArrowDownwardIcon
                              onClick={() => handleSortDirection("asc", column?.sortKey)}
                              fontSize="small"></ArrowDownwardIcon>
                          ) : (
                            <ArrowUpwardIcon
                              onClick={() => handleSortDirection("desc", column?.sortKey)}
                              fontSize="small"></ArrowUpwardIcon>
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <div className="d-flex flex-column">
                          <Typography variant="body1Regular">{row?.title ?? "-"}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1Regular">
                          {toTitleCase(row?.content ?? "-") ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">All</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body1Regular">
                          {row?.streaks.length ? (
                            row?.streaks?.length > 2 ? (
                              <Typography variant="body1Regular">
                                {row?.streaks
                                  ?.map((element) => String(element).replace(/_/g, " "))
                                  ?.slice(0, 2)
                                  ?.join(", ")}
                                <Tooltip
                                  title={row?.streaks
                                    ?.map((element) => String(element).replace(/_/g, " "))
                                    ?.slice(2)
                                    ?.join(", ")}>
                                  <span className={styles.moreStreaks}>
                                    +{row?.streaks?.length - 2}
                                  </span>
                                </Tooltip>
                              </Typography>
                            ) : (
                              <Typography variant="body1Regular">
                                {row?.streaks.slice(0, 3)?.join(", ")}
                              </Typography>
                            )
                          ) : (
                            <Typography variant="body1Regular">-</Typography>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <div className={`${styles.tableAvatar}`}>
                          <div className={`${styles.tableAvatar__img}`}>
                            <img src={row?.image_url ? row?.image_url : avatar} />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body1Regular"
                          className={
                            row?.status?.toLowerCase() === "delivered"
                              ? styles.deliver
                              : row?.status === "failed"
                              ? styles.failure
                              : styles.schedule
                          }>
                          {toTitleCase(row?.status) || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <AppButton
                          onClick={() => handleResend(row?.id)}
                          sx={{ padding: "10px 20px" }}
                          disabled={row?.status?.toLowerCase() === "scheduled" ? true : false}>
                          Resend
                        </AppButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={8} label="No New Notification" />
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Paper>
      {rows?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default PushNotificationListTable

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))
