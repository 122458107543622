import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton, Grid } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextArea from "components/StyledComponents/AppTextArea"
import { useDispatch, useSelector } from "react-redux"
import MultiSelect from "components/StyledComponents/MultipleSelect"
import AppDatePicker from "components/AppDatePicker"
import StyledLabel from "components/StyledComponents/StyledLabel"
import { uploadNotificationImageAction } from "redux/superAdmin/actions/user"
import { superAdminAction } from "redux/superAdmin/slice/user"
import AppSelect from "components/StyledComponents/AppSelect"
import { getEmployeeListAction } from "redux/DMV/actions/user"
import RepeatModal from "components/RepeatModal"
import { Formik } from "formik"
import { validationSchemaRepeatModal } from "helpers/validationSchema"
import { convertDate, payloadDate } from "helpers/functions"
import { DMVActions } from "redux/DMV/slice/user"

const buttonText = {
  EDIT: "Update",
  SAVE: "Save"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

const PushNotificationForm = ({ formik, open = false, onClose = () => {}, setRepeatData }) => {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)
  const { restaurantList } = useSelector((store) => store?.user)
  const { employeeList } = useSelector((store) => store?.dmv)
  const [restaurantsList, setRestaurantsList] = useState([])
  const [employeesList, setEmployeesList] = useState([])
  const [restaurantIds, setRestaurantIds] = useState("")
  const [openRepeat, setOpenRepeat] = useState(false)
  const [repeats, setRepeats] = useState("Does Not Repeat")
  const [initData, setInItData] = useState({})
  const [scheduledDate, setScheduledDate] = useState("")

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  useEffect(() => {
    const allKeyPair = [
      { value: "ALL", label: "All" },
      ...(restaurantList
        ? restaurantList.map((res) => ({
            value: res.id,
            label: res.name
          }))
        : [])
    ]
    setRestaurantsList(allKeyPair)
  }, [restaurantList])

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      notificationName: "",
      uploadThumbnail: "",
      scheduleNotificationDate: "",
      scheduleNotificationTime: "",
      employeeList: [],
      streakMilestone: [],
      notificationContent: "",
      repeat: ""
    })
    dispatch(superAdminAction?.setNotificationImageId(""))
    dispatch(superAdminAction?.setNotificationData({}))
    dispatch(DMVActions.setEmployeeList([]))
    dispatch(getEmployeeListAction("restaurant_ids=All"))
  }, [open])

  useEffect(() => {
    if (employeeList) {
      const employeeOptions = [
        { value: "ALL", label: "All" },
        ...employeeList.map((emp) => ({
          value: emp.id,
          label: `${emp.full_name}, ${emp.role_name} (${
            emp?.restaurants?.length > 0
              ? emp?.restaurants
                  ?.map(
                    (rId) => restaurantsList?.find((res) => res.value === rId)?.label || "Unknown"
                  )
                  .join(", ")
              : "No Restaurants"
          }) || [${emp.streaks} Days]`
        }))
      ]
      setEmployeesList(employeeOptions)
    }
  }, [employeeList, restaurantsList])

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }

  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadNotificationImageAction(data))
      setUploadType("")
    }
  }

  const handleRestaurantChange = (e) => {
    const selectedRestaurants = e.target.value

    const paramsObj = new URLSearchParams(restaurantIds)
    if (!(selectedRestaurants.includes("ALL") && paramsObj.get("restaurant_ids") === "All")) {
      paramsObj.delete("restaurant_ids")

      if (selectedRestaurants.includes("ALL")) {
        paramsObj.append("restaurant_ids", "All")
      } else {
        selectedRestaurants.forEach((id) => paramsObj.append("restaurant_ids", id))
      }
    }

    setRestaurantIds(paramsObj.toString())
    formik.setFieldValue(
      "restaurants",
      selectedRestaurants.includes("ALL") ? ["ALL"] : selectedRestaurants
    )

    if (formik.values.restaurants.some((id) => !selectedRestaurants.includes(id))) {
      formik.setFieldValue("employeeList", [])
      setEmployeesList([])
    }
  }

  const handleEmployeeListChange = (e) => {
    const employeeList = e.target.value
    if (employeeList.includes("ALL")) {
      formik.setFieldValue("employeeList", ["ALL"])
    } else {
      formik.setFieldValue("employeeList", employeeList)
    }
  }
  const handleStreakChange = (e) => {
    const streakArray = e.target.value
    const streak = streakArray.map((id) => `streak=${id}`).join("&")

    setRestaurantIds((prevParams) => {
      const paramsObj = new URLSearchParams(prevParams)
      paramsObj.delete("streak")
      if (streak) {
        streakArray.forEach((id) => paramsObj.append("streak", id))
      }
      return paramsObj.toString()
    })

    formik.setFieldValue("streakMilestone", streakArray)
  }

  const handleRepeatChange = (e) => {
    formik.setFieldValue("repeat", e.target.value)
    setRepeats(e.target.value)
    const initialValues = {
      repeat: e.target.value,
      scheduledDate: scheduledDate === "" ? null : scheduledDate,
      scheduleNotificationStartDate: null,
      scheduleNotificationEndDate: null,
      repeatEvery: 1,
      selectedDay: 1,
      selectedMonth: new Date().getMonth() + 1,
      selectedDays: [],
      selectedWeek: "first",
      selectedWeekDay: 0
    }
    setInItData(initialValues)
  }

  useEffect(() => {
    if (restaurantIds) {
      dispatch(getEmployeeListAction(restaurantIds))
    }
  }, [restaurantIds])

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  const onCloseRepeatModal = () => {
    formik.setFieldValue("repeat", "Does Not Repeat")
    setRepeats("Does Not Repeat")
    setOpenRepeat(false)
  }

  const handleSubmit = (values) => {
    const {
      repeat,
      repeatEvery,
      scheduleNotificationStartDate,
      scheduleNotificationEndDate,
      selectedDays,
      selectedMonth,
      selectedDay,
      selectedWeek,
      selectedWeekDay,
      monthlyOption
    } = values

    const currentTime = new Date().toISOString().split("T")[1].split(".")[0]
    const convertedStartDate = convertDate(scheduleNotificationStartDate, currentTime).split(" ")
    const convertedEndDate = convertDate(scheduleNotificationEndDate, currentTime).split(" ")
    const payloadStartDate = payloadDate(convertedStartDate)
    const payloadEndDate = payloadDate(convertedEndDate)

    if (repeat !== "Does Not Repeat") {
      const repeat_schedule = {
        repeat_type: repeat,
        repeat_every: repeatEvery,
        start_date: payloadStartDate,
        end_date: payloadEndDate
      }

      if (repeat === "weekly") {
        repeat_schedule.weekly_days = selectedDays
      } else if (repeat === "Every Weekday(Mon-Fri)") {
        repeat_schedule.repeat_type = "every_weekday"
      } else if (repeat === "monthly") {
        repeat_schedule.selected_month = selectedMonth
        if (monthlyOption === "day") {
          repeat_schedule.selected_day = selectedDay
        } else {
          repeat_schedule.monthly_week_position = selectedWeek
          repeat_schedule.monthly_weekday = selectedWeekDay
        }
      } else if (repeat === "yearly") {
        if (monthlyOption === "day") {
          repeat_schedule.yearly_month = selectedMonth
          repeat_schedule.yearly_date = selectedDay
        } else {
          repeat_schedule.yearly_week_position = selectedWeek
          repeat_schedule.yearly_weekday = selectedWeekDay
          repeat_schedule.yearly_week_month = selectedMonth
        }
      }

      setRepeatData(repeat_schedule)
      setOpenRepeat(false)
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchemaRepeatModal}
        initialValues={initData}
        enableReinitialize={true}>
        {(formik) => (
          <RepeatModal
            onClose={onCloseRepeatModal}
            setRepeats={setRepeats}
            formik={formik}
            open={openRepeat}
          />
        )}
      </Formik>
      <StyledDialog open={open}>
        <DialogContent>
          {showCropperModal(imageObjectURL, fileDetails, formik)}
          <Box className="d-flex justify-content-end">
            <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
              <CloseIcon sx={{ height: "14px", width: "14px" }} />
            </IconButton>
          </Box>
          <Box className="w-100 d-flex justify-content-center mb-2">
            <Typography variant="h2"> {"Add New"} Notification</Typography>
          </Box>

          <Box flexDirection="column" className="mt-4">
            <React.Fragment>
              <Box className=" w-100 py-2">
                <Row className="w-100 m-0">
                  <Col lg="6">
                    <AppTextField
                      id="notificationName"
                      name="notificationName"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Notification Name"
                      isRequired={true}
                      placeholder="Enter Notification Name"
                      value={formik.values.notificationName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.notificationName && Boolean(formik.errors.notificationName)
                      }
                      helperText={formik.touched.notificationName && formik.errors.notificationName}
                    />
                  </Col>
                  <Col lg="6">
                    <ImageUploadField
                      label="Upload Thumbnail"
                      isVariant={true}
                      placeholder="Upload Image"
                      value={formik.values.uploadThumbnail}
                      onChange={(e) => onImageUpload(e)}
                      onDelete={() => handleImageDelete(formik)}
                      error={formik.errors.uploadThumbnail}
                      touched={formik.touched.uploadThumbnail}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-4">
                  <Col lg="6">
                    <Grid item sm={12} lg={6} className="datesContainer">
                      <StyledLabel variant="body1Regular">
                        {"Schedule Notification Date"}
                        <span>*</span>
                      </StyledLabel>
                      <Box className="dateFieldContainer w-100 mt-2">
                        <AppDatePicker
                          minDate={new Date()}
                          maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                          inputFormat="dd MMM yyyy"
                          disableMaskedInput
                          key={"scheduleNotificationDate"}
                          value={formik.values.scheduleNotificationDate}
                          onChange={(val) => {
                            formik.setFieldValue("scheduleNotificationDate", val)
                            setScheduledDate(val)
                          }}
                          error={formik.errors.scheduleNotificationDate}
                          touched={formik.touched.scheduleNotificationDate}
                        />
                      </Box>
                    </Grid>
                  </Col>
                  <Col lg="6">
                    <AppTextField
                      type="time"
                      fullWidth
                      label="Schedule Notification Time"
                      isRequired={true}
                      isUpperCase={true}
                      value={formik.values.scheduleNotificationTime}
                      onChange={formik.handleChange("scheduleNotificationTime")}
                      error={
                        formik.touched.scheduleNotificationTime &&
                        Boolean(formik.errors.scheduleNotificationTime)
                      }
                      helperText={
                        formik.touched.scheduleNotificationTime &&
                        formik.errors.scheduleNotificationTime
                      }
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-4">
                  <Col lg="6">
                    <MultiSelect
                      id="restaurants"
                      name="restaurants"
                      menuItems={restaurantsList}
                      label="Restaurants"
                      placeholder="Select Restaurants"
                      isRequired={true}
                      search={true}
                      checkBox={true}
                      value={formik.values?.restaurants || []}
                      onChange={handleRestaurantChange}
                      error={formik.errors.restaurants && Boolean(formik.touched.restaurants)}
                      touched={formik.touched.restaurants && formik.errors.restaurants}
                    />
                  </Col>
                  <Col lg="6">
                    <MultiSelect
                      id="streakMilestone"
                      name="streakMilestone"
                      menuItems={streakMilestone}
                      label="Streak Milestone"
                      placeholder={"Select Streaks"}
                      value={formik.values?.streakMilestone || []}
                      onChange={handleStreakChange}
                      error={
                        formik.errors.streakMilestone && Boolean(formik.touched.streakMilestone)
                      }
                      touched={formik.touched.streakMilestone && formik.errors.streakMilestone}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-3">
                  <Col lg="12">
                    <MultiSelect
                      id="employeeList"
                      name="employeeList"
                      menuItems={employeesList}
                      search={true}
                      label="Employee List"
                      placeholder={"Select Employee"}
                      isRequired={true}
                      value={formik.values?.employeeList}
                      onChange={handleEmployeeListChange}
                      error={formik.errors.employeeList && Boolean(formik.touched.employeeList)}
                      touched={formik.touched.employeeList && formik.errors.employeeList}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextArea
                      label="Notification Content"
                      minRows={5}
                      maxRows={15}
                      isRequired={true}
                      placeholder="Enter Notification Content"
                      value={formik.values.notificationContent}
                      onChange={formik.handleChange("notificationContent")}
                      error={formik.errors?.notificationContent}
                      touched={formik.touched?.notificationContent}
                      charLimit={100}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppSelect
                      id="repeat"
                      name="repeat"
                      label="Repeat"
                      menuItems={repeatOptions}
                      onMenuClick={(e) => {
                        if (e.value !== "Does Not Repeat") {
                          setOpenRepeat(true)
                        } else {
                          setOpenRepeat(false)
                        }
                      }}
                      value={repeats}
                      onChange={handleRepeatChange}
                      error={formik.errors.repeat}
                      touched={formik.touched.repeat}
                    />
                  </Col>
                </Row>
              </Box>
            </React.Fragment>
          </Box>
          <Box className="mt-5 d-flex justify-content-around">
            <AppButton
              onClick={formik.handleSubmit}
              sx={() => ({
                height: "48px",
                padding: "10px 50px"
              })}>
              {buttonText.SAVE}
            </AppButton>
          </Box>
        </DialogContent>
      </StyledDialog>
    </>
  )
}

export default PushNotificationForm

const streakMilestone = [
  { value: "2", label: "2 Days Streak" },
  { value: "7", label: "7 Days Streak" },
  { value: "14", label: "14 Days Streak" },
  { value: "21", label: "21 Days Streak" },
  { value: "30", label: "30 Days Streak" },
  { value: "60", label: "60 Days Streak" },
  { value: "90", label: "90 Days Streak" }
]

const repeatOptions = [
  {
    label: "Does Not Repeat",
    value: "Does Not Repeat"
  },
  { label: "Every Weekday(Mon-Fri)", value: "Every Weekday(Mon-Fri)" },
  {
    label: "Daily",
    value: "daily"
  },
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  },
  {
    label: "Yearly",
    value: "yearly"
  }
]
