import React from "react"
import {
  Paper,
  Radio,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import TableNoRecords from "components/TableNoRecords"
import styles from "../generateVipQuestions.module.scss"
// import ImageDisplay from "components/ImageDisplayer"
import image from "assets/images/imagePlaceholder.png"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderRadius: "12px",
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:first-child": {
      paddingLeft: 20
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`
  }
}))
const VIPTable = ({ columns, rows, selectedRow, setSelectedRow }) => {
  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 700 }}>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10px" }}></TableCell>

              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column.minWidth }}>
                  <Typography variant="body4Regular" style={{ opacity: 0.6 }}>
                    {column.label}{" "}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length ? (
              rows?.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                      <Radio
                        checked={selectedRow === row.id}
                        onChange={() => setSelectedRow(row.id)}
                      />
                    </TableCell>
                    <TableCell>
                      {row?.image ? (
                        <img src={row?.image} className={styles.image} />
                      ) : (
                        <img src={image} className={styles.image} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body6Regular">{row.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body6Regular">{row.work_company}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body6Regular">{row.designation}</Typography>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableNoRecords colSpan={8} label="No VIP Found" />
            )}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  )
}

export default VIPTable
