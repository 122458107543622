import { List, ListItem, Typography } from "@mui/material"
import Card from "components/Card/Card"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import React, { useState } from "react"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ChooseRestaurantsModal from "components/ChooseRestaurantsModal"
import { useDispatch } from "react-redux"
import { getVIPRestaurantsAction } from "redux/DMV/actions/user"

const GenerateVipCard = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  return (
    <>
      <ChooseRestaurantsModal open={open} onClose={() => setOpen(false)} />
      <Card style={{ paddingBottom: 12 }}>
        <List>
          <ListItem sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">Generate VIP Questions</Typography>
            <span
              onClick={() => {
                setOpen(true)
                dispatch(getVIPRestaurantsAction())
              }}>
              <IconButtonWithBorder islight={true}>
                <ArrowForwardIosIcon
                  sx={{
                    color: "white",
                    fontSize: "small"
                  }}
                />
              </IconButtonWithBorder>
            </span>
          </ListItem>
        </List>
      </Card>
    </>
  )
}

export default GenerateVipCard
