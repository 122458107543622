import { Grid, Typography } from "@mui/material"
import React from "react"
import styles from "./questionBank.module.scss"

const CategoryCard = ({ title, count, handleClick, isIncluded }) => {
  return (
    <div className={styles.Container} onClick={handleClick}>
      <Grid item className={`${styles.container} ${isIncluded ? styles.withBoxShadow : ""}`}>
        <Typography variant="body1Regular" color="text.secondary" className={styles.category}>
          {title}
        </Typography>
        <Grid item className={styles.ellipse_spirit_gold}>
          {count ?? 0}
        </Grid>
      </Grid>
    </div>
  )
}

export default CategoryCard
