import axios from "axios"
import { call, put, select } from "redux-saga/effects"
import slice from "../slice/user"
import { toast } from "react-toastify"
import { appActions } from "redux/app/slice/app"
import { saveAs } from "file-saver"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* createWineClubMemberships(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/wine-club-benefits/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setIsWineClubMembershipSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadBenefitsImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      toast.success("Image Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(
        slice.actions.setbenefitImage({
          index: action.payload.index,
          id: data.data.id
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadMembershipImage(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      toast.success("Image Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setMembershipImage(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getExistingBenefits(action) {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/wine-club-benefits/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setExistingBenefits(data?.data?.benefits ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getTriviaCategories(action) {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/trivia/categories/?web=true`, action.payload)
    if (data.success) {
      yield put(slice.actions.setTriviaCategoriesList(data?.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadTastingNotesWineImage(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setTastingNotesWineImage(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadTastingNotesVideoThumbnail(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setTastingNotesVideoThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadTastingNotesFlavourImage(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      yield put(
        slice.actions.setTastingNotesFlavourImage({
          index: action.payload.index,
          id: data.data.id
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* createTastingNotes(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/wine-of-the-week/`,
      action.payload
    )
    if (data.success) {
      toast.success("Tasting Note Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setIsTastingNotesSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editTastingNotes(action) {
  try {
    const id = yield select((store) => store.dmv.tastingNotes.id)
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/wine-of-the-week/${id}/`,
      action.payload
    )
    if (data.success) {
      toast.success("Tasting Note Updated Successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setIsTastingNotesSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteBenefit(action) {
  try {
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/frontline/wine-club-benefits/${action.payload}/`
    )
    if (data.success) {
      const existingBenefits = yield select(
        (store) => store.dmv.wineClubMembership.existingBenefits
      )
      yield put(
        slice.actions.setExistingBenefits(existingBenefits.filter((el) => el.id !== action.payload))
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getTastingNotesWeekList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/wine-of-the-week-list-web/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setWeekList(data?.data ?? []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getTastingNotesData(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/wine-of-the-week/${action.payload}/`
    )
    if (data.success) {
      yield put(slice.actions.setTastingNotesData(data?.data ?? {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteTastingNote() {
  try {
    yield put(appActions.setIsLoading(true))
    const { id } = yield select((store) => store.dmv.tastingNotes)
    const { data } = yield call(axios.delete, `${baseUrl}/frontline/wine-of-the-week/${id}/`)
    if (data.success) {
      const { data } = yield call(axios.get, `${baseUrl}/frontline/wine-of-the-week-list-web/`)
      if (data.success) {
        yield put(slice.actions.setWeekList(data?.data ?? []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* uploadTriviaCategoryImage(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload.data)
    if (data.success) {
      toast.success("Image Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(
        slice.actions.setTriviaCategoryImage({
          index: action.payload.index,
          id: data.data.id
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* createTriviaCategories(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/categories/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setIsTriviaCategoriesSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateTriviaCategories(action) {
  try {
    const { data } = yield call(axios.patch, `${baseUrl}/trivia/categories/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setIsTriviaCategoriesSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getWineBenefitData(action) {
  try {
    const id = yield select((store) => store.dmv.wineBenefits.id)
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/wine-club-benefits/${id}/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setWineBenefitData(data?.data ?? {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadBenefitIconImage(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      toast.success("Image Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setBenefitImageIcon(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editWineBenefits(action) {
  try {
    const id = yield select((store) => store.dmv.wineBenefits.id)
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/wine-club-benefits/${id}/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setIsBenefitSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getMembershipImageURL(action) {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/wine-club-membership/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setMembershipImageURL(data?.data?.[0]?.image))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadGrandCruThumbnail(action) {
  try {
    yield put(slice.actions.setGrandCruLoadingStart(true))
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      toast.success("Image Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setGrandCruThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  } finally {
    yield put(slice.actions.setGrandCruLoadingEnd(false))
  }
}

export function* uploadGrandCruVideo(action) {
  try {
    yield put(slice.actions.setGrandCruLoadingStart(true))
    const id = toast.loading("Uploading Video", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      theme: "dark"
    })
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      toast.update(id, {
        render: "Video Uploaded",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        progress: undefined
      })
      yield put(slice.actions.setGrandCruVideo(data.data.id))
    }
  } catch (e) {
    console.log(e)
  } finally {
    yield put(slice.actions.setGrandCruLoadingEnd(false))
  }
}

export function* getGrandCruGoals(action) {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/grand-cru-settings/`,
      action.payload
    )
    if (data.success) {
      yield put(
        slice.actions.setGrandCruGoalsData({
          id: data?.data?.id || "",
          server: data?.data?.server_goal || 0,
          bartender: data?.data?.bartender_goal || 0,
          tr: data?.data?.tasting_room_attendant_goal || 0
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateGrandCruGoals(action) {
  try {
    const { data } = yield call(
      action.payload.id ? axios.patch : axios.post,
      `${baseUrl}/frontline/grand-cru-settings/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setGrandCruGoalsSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getGrandCruVideoData(action) {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/frontline/grand-cru-video/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setGrandCruVideoData(data?.data?.[0] || {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateGrandCruVideo(action) {
  try {
    const { id } = action.payload
    const { data } = yield call(
      id ? axios.patch : axios.post,
      `${baseUrl}/frontline/grand-cru-video/${id ? `${id}/` : ""}`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setGrandCruVideoSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadVideoGalleryThumbnail(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      toast.success("Thumbnail Uploaded", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setVideoGalleryThumbnail(data.data.id))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadVideoGalleryVideo(action) {
  try {
    const { payload, isEditMode } = action.payload
    const id = yield select((store) => store.dmv.videoGallery.id)
    const { data } = yield call(
      isEditMode ? axios.patch : axios.post,
      `${baseUrl}/frontline/performance-management-video/${isEditMode ? `${id}/` : ""}`,
      payload
    )
    if (data.success) {
      yield put(slice.actions.setVideoGalleryVideoSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getVideoGalleryVideos(action) {
  try {
    let params = ""
    if (action.payload === "assigned") {
      params = "?assigned=True"
    } else if (action.payload === "unassigned") {
      params = "?assigned=False"
    }
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/performance-management-video/${params}`
    )
    if (data.success) {
      yield put(
        slice.actions.setVideoGalleryVideosList({
          type: action.payload,
          data: data.data || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteVideoGalleryVideo() {
  try {
    const id = yield select((store) => store.dmv.videoGallery.id)
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/frontline/performance-management-video/${id}/`
    )
    if (data.success) {
      yield put(slice.actions.setVideoGalleryVideoDeleted(true))
      toast.success("Video Deleted", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getRoundsList() {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/frontline/round-configuration/`)
    if (data.success) {
      yield put(slice.actions.setRoundsList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getViewRoundData(action) {
  try {
    const id = yield select((store) => store.dmv.rounds.roundId)
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/round-configuration/${id}/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setViewRoundData(data?.data ?? {}))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* createRound(action) {
  try {
    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/round-configuration/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setRoundId(data?.data?.id))
      yield put(slice.actions.setFromRoundId(data?.data?.id))
      yield put(slice.actions.setIsRoundFormSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* deleteRound() {
  try {
    const id = yield select((store) => store.dmv.rounds.roundId)
    yield call(axios.delete, `${baseUrl}/frontline/round-configuration/${id}/`)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/round-configuration/`)
    if (data.success) {
      yield put(slice.actions.setRoundsList(data.data))
      toast.success("Round Deleted", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getRoundData() {
  try {
    const id = yield select((store) => store?.dmv?.roundForm?.id)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/round-configuration/${id}/`)
    if (data.success) {
      yield put(slice.actions.setRoundFormData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* updateRound(action) {
  try {
    const id = yield select((store) => store?.dmv?.roundForm?.id)
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/round-configuration/${id}/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setIsRoundFormSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getHolidays(action) {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/holiday-calendar/?date=${action.payload}`
    )
    if (data.success) {
      yield put(
        slice.actions.setHolidayCalendarData({
          date: action.payload,
          data: data.data
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getVideoLibraryVideos(action) {
  try {
    yield put(slice.actions.setAssignVideosLoading(true))
    let query = `page=${action.payload.page}`
    if (action?.payload?.filter !== "all-videos") {
      query += `&video-type=${action.payload.filter}`
    }
    if (action?.payload?.searchData) {
      query += `&search=${action.payload.searchData}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/frontline/video-library?${query}`)
    if (data.success) {
      yield put(slice.actions.setVideoLibrary(data?.data?.results || 0))
      yield put(slice.actions.setAssignVideoTotalPages(data?.data?.pages || 0))
      yield put(slice.actions.setAssignVideosLoading(false))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getAssignVideosWeekData(action) {
  try {
    const id = yield select((store) => store?.dmv?.roundForm?.id)
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/round-configuration/${id}/`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setAssignVideosWeekData(data?.data?.weeks || []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* assignVideos(action) {
  try {
    const id = yield select((store) => store?.dmv?.roundForm?.id)
    const { data } = yield call(axios.post, `${baseUrl}/frontline/round-video-assigment`, {
      round: id,
      video_data: action.payload
    })
    if (data.success) {
      yield put(slice.actions.setAssignVideosSubmitted(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* roundStatusUpdate(action) {
  try {
    const id = yield select((store) => store?.dmv?.roundForm?.id)
    yield call(
      axios.post,
      `${baseUrl}/frontline/round-configuration/${id}/publish/`,
      action.payload
    )
  } catch (e) {
    console.log(e)
  }
}

export function* uploadHolidayList(action) {
  console.log(action.payload)
  try {
    const headerParams = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }

    const { data } = yield call(
      axios.post,
      `${baseUrl}/frontline/upload-holidays`,
      {
        file: action.payload
      },
      headerParams
    )

    if (data.success) {
      toast.success("Upload Successful", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getImportPreviousRounds(action) {
  const { import_tasting, import_n_tasting } = action.payload
  const id = yield select((store) => store?.dmv?.roundForm?.id)

  try {
    if (import_tasting === null && import_n_tasting === null) {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/round-configuration/${id}/`,
        action.payload
      )
      if (data.success) {
        yield put(slice.actions.setAssignVideosWeekData(data?.data?.weeks || []))
      }
    } else {
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/import-video/${id}/?import_tasting=${import_tasting}&import_n_tasting=${import_n_tasting}`,
        action.payload
      )
      if (data.success) {
        yield put(slice.actions.setAssignVideosWeekData(data?.data?.weeks || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getDMVWineRegionList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
    if (data.success) {
      yield put(slice.actions.setWineRegionData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getBeverage() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
    if (data.success) {
      yield put(slice.actions.setBeverageData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGenerateQuestionDMVWineRegion(action) {
  try {
    const { search } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/wine-region-web/${search !== "" ? `?search=${search}` : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setWineRegionData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGenerateQuestionDMVBeverage(action) {
  try {
    const { search } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/beverage-item-web/${search !== "" ? `?search=${search}` : ""}`
    )
    if (data.success) {
      yield put(slice.actions.setBeverageData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editWineRegionCategory(action) {
  const { categoryID, name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/wine-region-category/${categoryID}`,
      {
        name: name
      }
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
      if (newData.success) {
        yield put(slice.actions.setWineRegionData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addWineRegionCategory(action) {
  const { name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/wine-region-category/`, {
      name: name
    })
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
      if (newData.success) {
        yield put(slice.actions.setWineRegionData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteWineRegionMenuCategory(action) {
  const { categoryID } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/wine-region-category/${categoryID}`)
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
    if (newData.success) {
      yield put(slice.actions.setWineRegionData(newData?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addDMVWineMenuItems(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/wine-region/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
      if (newData.success) {
        yield put(slice.actions.setWineRegionData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDMVWineItems() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/wine-region/${itemID ?? ""}`)
    if (data.success) {
      yield put(slice.actions.setWineMenuItem(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateDMVWineItems(action) {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/wine-region/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
      if (newData.success) {
        yield put(slice.actions.setWineRegionData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteDMVWineMenuItem() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/wine-region/${itemID}`)
    yield put(slice.actions.setDMVMenuCategoryId(""))
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/wine-region-web/`)
    if (newData.success) {
      yield put(slice.actions.setWineRegionData(newData?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editBeverageCategory(action) {
  const { categoryID, name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/beverage-category/${categoryID}`,
      {
        name: name
      }
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
      if (newData.success) {
        yield put(slice.actions.setBeverageData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addBeverageCategory(action) {
  const { name } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/beverage-category/`, {
      name: name
    })
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
      if (newData.success) {
        yield put(slice.actions.setBeverageData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteBeverageCategory(action) {
  const { categoryID } = action.payload
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/beverage-category/${categoryID}`)
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
    if (newData.success) {
      yield put(slice.actions.setBeverageData(newData?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addDMVBeverageMenuItems(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/beverage/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
      if (newData.success) {
        yield put(slice.actions.setBeverageData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDMVBeverageItems() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/beverage/${itemID ?? ""}`)
    if (data.success) {
      yield put(slice.actions.setBeverageMenuItem(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateDMVBeverageItems(action) {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/beverage/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
      if (newData.success) {
        yield put(slice.actions.setBeverageData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteDMVBeverageMenuItem() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/beverage/${itemID}`)
    yield put(slice.actions.setDMVMenuCategoryId(""))
    const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/beverage-item-web/`)
    if (newData.success) {
      yield put(slice.actions.setBeverageData(newData?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDMVAutoSuggestQuestions(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/trivia/auto-suggested-questions/?platform=GAMIFICATION&page=${action.payload?.page}`
    )
    if (data.success) {
      yield put(slice.actions.setDMVAutoSuggestQuestions(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editDMVAutoSuggestedQuestion(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/intermediate-questions/${action.payload.questionId}`,
      action.payload.data
    )
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/trivia/auto-suggested-questions/?platform=GAMIFICATION&page=${action.payload.currentPage}`
      )
      if (newData.success) {
        yield put(slice.actions.setDMVAutoSuggestQuestions(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}
export function* getQuizCategory() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/platform/quiz/categories`)
    if (data.success) {
      yield put(slice.actions.setQuizCategory(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}
export function* createQuiz(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { payload, handleRoute } = action.payload
    const { data } = yield call(axios.post, `${baseUrl}/frontline/platform/quiz/`, payload)
    if (data.success) {
      handleRoute()
      yield put(slice.actions.setQuizId(data?.data?.quiz_id || ""))
      yield put(slice.actions.setQuizQuestionBank(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editQuiz(action) {
  try {
    const { quizId } = yield select((store) => store?.dmv)
    yield put(appActions.setIsLoading(true))
    const { payload, type, handleRoute } = action.payload
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/platform/quiz/${quizId}`,
      payload
    )
    if (data.success) {
      handleRoute()
      if (type != "edit") {
        const { data: newData } = yield call(
          axios.get,
          `${baseUrl}/frontline/platform/quiz/${quizId}/questions`
        )
        if (newData.success) {
          yield put(slice.actions.setQuizQuestionBank(newData?.data || {}))
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDmvQuizList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/platform/quiz/`)
    if (data.success) {
      yield put(slice.actions.setDmvQuizList(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addQuestionToQuiz(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { quizId } = yield select((store) => store.dmv)
    const { payload, handleRoute } = action.payload
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/platform/quiz/${quizId}/questions`,
      payload
    )
    if (data.success) {
      handleRoute()
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteQuestionFromQuiz(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { quizId } = yield select((store) => store.dmv)
    const { id } = action.payload
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/frontline/platform/quiz/${quizId}/questions/${id}`
    )
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/platform/quiz/${quizId}`
      )
      if (newData.success) {
        yield put(slice.actions.setQuizData(newData?.data || {}))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getQuizData(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/platform/quiz/${action.payload}`)
    if (data.success) {
      yield put(slice.actions.setQuizData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getQuizQuestionsList() {
  try {
    yield put(appActions.setIsLoading(true))
    const { quizId } = yield select((store) => store.dmv)
    const { data } = yield call(axios.get, `${baseUrl}/frontline/platform/quiz/${quizId}/questions`)
    if (data.success) {
      yield put(slice.actions.setQuizQuestionsList(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* editSpiritCategory(action) {
  const { categoryID, name } = action.payload
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.patch, `${baseUrl}/frontline/spirit-category/${categoryID}`, {
      name: name
    })
    if (data.success) {
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addSpiritCategory(action) {
  const { name } = action.payload
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/spirit-category/`, {
      name: name,
      sub_category: menuCategory?.name
    })
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteSpiritCategory(action) {
  const { categoryID } = action.payload
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/spirit-category/${categoryID}`)
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv`
    )
    if (newData.success) {
      yield put(slice.actions.setSpiritData(newData?.data || []))
    }
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-sub-category/?platform=dmv&console=dmv`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritSubMenuItems(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDMVSpiritSubMenuist(action) {
  try {
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-sub-category/${
        action.payload !== "" && action.payload
          ? `?platform=${action.payload}&console=${action.payload}`
          : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritSubMenuItems(data.data))
      yield put(slice.actions.setSubFilterValue({ name: data.data?.[0]?.id, list: "Summary View" }))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getSpirit(action) {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    if (menuCategory?.name) {
      yield put(appActions.setIsLoading(true))
      const { data } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv${
          action.payload !== "" && action.payload ? `&search=${action.payload}` : ""
        }`
      )
      if (data.success) {
        yield put(slice.actions.setSpiritData(data?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDMVSpiritItems() {
  const { itemID } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`)
    if (data.success) {
      yield put(slice.actions.setSpiritMenuItem(data.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addDMVSpiritMenuItems(action) {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/frontline/spirit-menu/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
      const { data: countData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-sub-category/?platform=dmv&console=dmv`
      )
      if (countData.success) {
        yield put(slice.actions.setSpiritSubMenuItems(countData.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* updateDMVSpiritItems(action) {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-menu/${itemID ?? ""}`,
      action.payload
    )
    if (data.success) {
      yield put(slice.actions.setDMVMenuCategoryId(data.data.id))
      const { data: newData } = yield call(
        axios.get,
        `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv`
      )
      if (newData.success) {
        yield put(slice.actions.setSpiritData(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteDMVSpiritMenuItem() {
  const { itemID, menuCategory } = yield select((store) => store.dmv)
  try {
    yield put(appActions.setIsLoading(true))
    yield call(axios.delete, `${baseUrl}/frontline/spirit-menu/${itemID}`)
    yield put(slice.actions.setDMVMenuCategoryId(""))
    const { data: newData } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?console=dmv`
    )
    if (newData.success) {
      yield put(slice.actions.setSpiritData(newData?.data || []))
    }
    const { data: countData } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-sub-category/?platform=dmv&console=dmv`
    )
    if (countData.success) {
      yield put(slice.actions.setSpiritSubMenuItems(countData.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGenerateQuestionDMVSpirits(action) {
  try {
    const { search, page } = action.payload
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-menu/?page=${page}&page_size=5${
        search !== "" ? `&search=${search}` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritsData(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getImportSpirit(action) {
  try {
    const { menuCategory } = yield select((store) => store.dmv)
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/spirit-web-menu/${menuCategory?.name}?&item_state=available${
        action.payload !== "" && action.payload ? `&search=${action.payload}` : ""
      }`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritData(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addDMVSpiritSubMenuist(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-sub-category/${action?.payload?.category}`,
      action.payload
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/frontline/spirit-sub-category/`)
      if (newData.success) {
        yield put(slice.actions.setSpiritSubMenuItems(newData?.data || []))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getDMVSpiritSummary(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/frontline/spirit-sub-category/${action?.payload}`
    )
    if (data.success) {
      yield put(slice.actions.setSpiritSummary(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getMonthlyAnalytics(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { activeTab, restaurant, period, orderBy } = action.payload
    let params = `?restaurant=${restaurant}&period=${period}&tab=${activeTab}`
    if (orderBy) {
      params += `&order_by=${orderBy}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/frontline/platform/analytics/${params}`)
    if (data.success) {
      yield put(slice.actions.setMontlyAnalytics(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getWeeklyAnalytics(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { restaurant, period } = action.payload
    const { data } = yield call(
      axios.get,
      `${baseUrl}/frontline/platform/trend-analytics/?restaurant=${restaurant}&period=${period}`
    )
    if (data.success) {
      yield put(slice.actions.setWeeklyAnalytics(data?.data || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getExportData(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { restaurant, period, type, activeTab, onClose, analyticsType } = action.payload
    let params = `${analyticsType}/?export=True&restaurant=${restaurant}&period=${period}&file_type=${type}`
    if (activeTab) {
      params += `&tab=${activeTab}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/frontline/platform/${params}`)
    if (data.success) {
      const { file_url } = data.data

      if (file_url) {
        const [metadata, base64Data] = file_url.split(";base64,")
        const mimeType = metadata.split(":")[1].split(";")[0]
        const fileName = metadata.split("name=")[1] || "exported_file.csv"
        const binaryData = Uint8Array.from(atob(base64Data), (char) => char.charCodeAt(0))
        const blob = new Blob([binaryData], { type: mimeType })
        saveAs(blob, fileName)
      }

      onClose()
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getPushNotification(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, orderBy, search } = action.payload
    let params = `?page=${page}`
    if (orderBy) {
      params += `&order_by=${orderBy}`
    }
    if (search && search !== "") {
      params += `&search=${search}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/notifications/v1/${params}`)
    if (data.success) {
      yield put(slice.actions.setPushNotification(data?.data || {}))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getEmployeeList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const restaurantIds = action.payload
    let params = ""
    if (restaurantIds !== "restaurant_ids=All") {
      params = `?${restaurantIds}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/notifications/user-list/${params}`)
    if (data.success) {
      yield put(slice.actions.setEmployeeList(data?.data?.users || []))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addPushNotification(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/notifications/v1/`, action.payload)
    if (data.success) {
      let params = `page=1`
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/v1/?${params}`)
      if (newData.success) {
        yield put(slice.actions.setPushNotification(newData?.data || {}))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* resendNotification(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/notifications/trigger-v1/`, action.payload)
    if (data.success) {
      let params = `page=1`
      const { data: newData } = yield call(axios.get, `${baseUrl}/notifications/v1/?${params}`)
      if (newData.success) {
        yield put(slice.actions.setPushNotification(newData?.data || {}))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getVIPRestaurants() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/vip/restaurants-vip/?restaurant=3e01ef87-42f3-42f5-8a50-9556a459d8e9`
    )
    if (data.success) {
      yield put(slice.actions.setVIPRestaurantsList(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getVIPList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { id, handleRoute } = action.payload
    const { data } = yield call(axios.get, `${baseUrl}/vip/guest-vip/?restaurant=${id}`)
    if (data.success) {
      yield put(slice.actions.setVIPList(data?.data))
      handleRoute()
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getVIPProfile(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, ` ${baseUrl}/vip/generate-vip/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setVIPProfileList(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getVIPImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/vip/generated-vip-image/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setVIPImageData(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* confirmVIPImage(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.patch, `${baseUrl}/vip/generated-vip-image/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setVIPImageData(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* generateVIPQuestion(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/vip/generate-question/`, action.payload)
    if (data.success) {
      toast.success("Questions Generated successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
      yield put(slice.actions.setGeneratedVIPQuestions(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getVIPQuestion(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { page, category } = action.payload
    let params = `?page=${page}`
    if (category && category != "all") {
      params += `&restaurant=${category}`
    }
    const { data } = yield call(axios.get, `${baseUrl}/vip/question-bank/${params}`)
    if (data.success) {
      yield put(slice.actions.setVIPQuestionBank(data?.data))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* addToVIPQuestionsBank(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.post, `${baseUrl}/vip/transfer-question/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setGeneratedVIPQuestions([]))
      const { data: newData } = yield call(axios.get, `${baseUrl}/vip/question-bank/?page=1`)
      if (newData.success) {
        if (!data.data.message) {
          toast.success(data?.data?.message ?? "Added to question bank", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark"
          })
        }
        yield put(slice.actions.setVIPQuestionBank(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* editVIPQuestions(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { isRankUpdate } = action.payload
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/questions/${action.payload.questionId}/`,
      action.payload.data
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/vip/question-bank/?page=1`)
      if (newData.success) {
        yield put(slice.actions.setVIPQuestionBank(newData?.data))
        toast.success(
          isRankUpdate ? "Question's rank updated successfully" : "Question updated successfully",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "dark"
          }
        )
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* deleteVIPQuestions(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { question } = action.payload
    const { data } = yield call(
      axios.delete,
      `${baseUrl}/trivia/questions/${question?.id + `/` ?? ""}`
    )
    if (data.success) {
      const { data: newData } = yield call(axios.get, `${baseUrl}/vip/question-bank/?page=1`)
      if (newData.success) {
        yield put(slice.actions.setVIPQuestionBank(newData?.data))
      }
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}

export function* getGenearateQuestionsPrompt() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/vip/quiz-prompt/`)
    if (data.success) {
      yield put(slice.actions.setPrompt(data?.data?.quiz_prompt))
    }
  } catch (e) {
    console.log(e)
  }
  yield put(appActions.setIsLoading(false))
}
