import React from "react"
import {
  Dialog,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText
} from "@mui/material"
import { styled } from "@mui/system"
import AppSelect from "components/StyledComponents/AppSelect"
import AppTextField from "components/StyledComponents/AppTextField"
import AppDatePicker from "components/AppDatePicker"
import AppButton from "components/StyledComponents/AppButton"
import styles from "./repeat.module.scss"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.white.main,
    padding: "16px",
    borderRadius: "8px",
    maxWidth: "670px !important"
  }
}))

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "&.MuiFormControlLabel-root": {
    margin: 0
  },
  "& .MuiTypography-root": {
    fontFamily: "Inter-Regular",
    fontSize: 14,
    lineHeight: "140%",
    fontWeight: 400,
    fontStyle: "normal",
    color: theme.palette.white.main
  }
}))
const CustomToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  "&.MuiToggleButtonGroup-root": {
    display: "flex",
    justifyContent: "space-between"
  }
}))
const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: "40px",
  height: "40px",
  color: theme.palette.white.main,
  border: "none",
  gap: "10px",
  "&.Mui-selected": {
    borderRadius: "50% !important",
    background: theme.palette.secondary.main
  },
  "&:hover": {
    background: theme.palette.secondary.main,
    borderRadius: "50% !important"
  }
}))

const dayLabels = [
  { label: "M", value: 0 },
  { label: "T", value: 1 },
  { label: "W", value: 2 },
  { label: "T", value: 3 },
  { label: "F", value: 4 },
  { label: "S", value: 5 },
  { label: "S", value: 6 }
]
const RepeatModal = ({ open, onClose = () => {}, formik, setRepeats }) => {
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Set recurrence
        </Typography>

        <Box className={styles.mainContainer}>
          {/* Start Date */}
          <Box className={styles.Container}>
            <Typography variant="body1Regular" textAlign={"right"}>
              Start
            </Typography>
            <Box className={`datesContainer`}>
              <Box className={`${styles.datePicker} dateFieldContainer`}>
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  isRequired={true}
                  key={"scheduleNotificationStartDate"}
                  value={formik.values.scheduleNotificationStartDate}
                  onChange={(val) => formik.setFieldValue("scheduleNotificationStartDate", val)}
                  error={formik.errors.scheduleNotificationStartDate}
                  touched={formik.touched.scheduleNotificationStartDate}
                />
              </Box>
            </Box>
          </Box>

          {/* Repeat Every */}
          <Box className={styles.Container}>
            <Typography variant="body1Regular" textAlign={"right"}>
              Repeat every
            </Typography>
            <Box className={styles.repeatEveryContainer}>
              <AppTextField
                id="repeatEvery"
                name="repeatEvery"
                value={formik.values.repeatEvery}
                isRequired={true}
                onChange={(e) => formik.setFieldValue("repeatEvery", e.target.value)}
                error={formik.errors.repeatEvery}
                touched={formik.touched.repeatEvery}
                helperText={formik.errors.repeatEvery}
              />
              <AppSelect
                id="repeat"
                name="repeat"
                isRequired={true}
                onChange={(e) => {
                  formik.setFieldValue("repeat", e.target.value)
                  setRepeats(e.target.value)
                }}
                menuItems={repeatMenu}
                value={formik.values.repeat}
                error={formik.errors.repeat}
                touched={formik.touched.repeat}
              />
            </Box>
          </Box>

          {/* Weekly Selection */}
          {formik.values.repeat === "weekly" && (
            <>
              <Box className={styles.Container}>
                <Typography variant="body1Regular" textAlign={"right"}>
                  Select Days
                </Typography>
                <CustomToggleButtonGroup
                  isRequired={true}
                  value={formik.values.selectedDays}
                  onChange={(event, newDays) => {
                    formik.setFieldValue("selectedDays", newDays)
                  }}
                  error={formik.touched.selectedDays && Boolean(formik.errors.selectedDays)}
                  sx={{ display: "flex", justifyContent: "space-between" }}>
                  {dayLabels.map((day, index) => (
                    <CustomToggleButton key={index} value={day.value}>
                      {day.label}
                    </CustomToggleButton>
                  ))}
                </CustomToggleButtonGroup>
                <Box></Box>
                <Box>
                  {formik.touched.selectedDays && formik.errors.selectedDays && (
                    <FormHelperText error={true}>{formik.errors.selectedDays}</FormHelperText>
                  )}
                </Box>
              </Box>
            </>
          )}

          {/* Monthly & Yearly Selection */}
          {(formik.values.repeat === "monthly" || formik.values.repeat === "yearly") && (
            <Box className={styles.Container}>
              <Box></Box>
              <RadioGroup
                name="monthlyOption"
                id="monthlyOption"
                value={formik.values.monthlyOption}
                onChange={(e) => formik.setFieldValue("monthlyOption", e.target.value)}
                error={formik.touched.monthlyOption && Boolean(formik.errors.monthlyOption)}>
                <Box className={styles.radioContainer}>
                  <CustomFormControlLabel
                    value="day"
                    control={<Radio />}
                    label={<Typography noWrap>On day</Typography>}
                  />
                  <Box className={styles.radioGroup}>
                    <AppTextField
                      id="selectedDay"
                      name="selectedDay"
                      disabled={formik.values.monthlyOption !== "day"}
                      value={formik.values.selectedDay || ""}
                      onChange={(e) => formik.setFieldValue("selectedDay", e.target.value)}
                      error={formik.errors.selectedDay}
                      touched={formik.touched.selectedDay}
                      helperText={formik.errors.selectedDay}
                    />
                    {formik.values.repeat === "yearly" && (
                      <AppSelect
                        id={"selectedMonth"}
                        name={"selectedMonth"}
                        value={formik.values.selectedMonth}
                        onChange={(e) => formik.setFieldValue("selectedMonth", e.target.value)}
                        disabled={formik.values.monthlyOption !== "day"}
                        menuItems={monthOptions}
                        error={formik.errors.selectedMonth}
                        touched={formik.touched.selectedMonth}
                      />
                    )}
                  </Box>
                </Box>
                <Box className={styles.radioContainerSecond}>
                  <CustomFormControlLabel
                    value="week"
                    control={<Radio />}
                    label={
                      <Typography variant="body2" noWrap>
                        On the
                      </Typography>
                    }
                  />
                  <Box className={styles.radioGroupSecond}>
                    <AppSelect
                      placeholder={"Select Week"}
                      id="selectedWeek"
                      value={formik.values.selectedWeek}
                      onChange={(e) => formik.setFieldValue("selectedWeek", e.target.value)}
                      disabled={formik.values.monthlyOption !== "week"}
                      error={formik.errors.selectedWeek}
                      touched={formik.touched.selectedWeek}
                      menuItems={weekOptions}
                    />
                    <AppSelect
                      id="selectedWeekDay"
                      name="selectedWeekDay"
                      placeholder={"Select Day"}
                      value={formik.values.selectedWeekDay}
                      onChange={(e) => formik.setFieldValue("selectedWeekDay", e.target.value)}
                      disabled={formik.values.monthlyOption !== "week"}
                      menuItems={weekDaysOptions}
                      error={
                        formik.touched.selectedWeekDay && Boolean(formik.errors.selectedWeekDay)
                      }
                    />
                    {formik.values.repeat === "yearly" && (
                      <>
                        <Typography variant="caption2" sx={{ marginTop: "14px" }}>
                          of
                        </Typography>
                        <AppSelect
                          id={"selectedMonth"}
                          name={"selectedMonth"}
                          value={formik.values.selectedMonth}
                          placeholder={"Select Month"}
                          onChange={(e) => formik.setFieldValue("selectedMonth", e.target.value)}
                          disabled={formik.values.monthlyOption !== "week"}
                          menuItems={monthOptions}
                          error={formik.errors.selectedMonth}
                          touched={formik.touched.selectedMonth}
                        />
                      </>
                    )}
                  </Box>
                </Box>
                {formik.errors.monthlyOption && (
                  <FormHelperText error={true}>{formik.errors.monthlyOption}</FormHelperText>
                )}
              </RadioGroup>
            </Box>
          )}

          {/* End Date */}
          <Box className={styles.Container}>
            <Typography variant="body1Regular" textAlign={"right"}>
              End
            </Typography>
            <Box className={`datesContainer`}>
              <Box className="ml-9 dateFieldContainer">
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  key={"scheduleNotificationEndDate"}
                  value={formik.values.scheduleNotificationEndDate}
                  onChange={(val) => formik.setFieldValue("scheduleNotificationEndDate", val)}
                  error={formik.errors.scheduleNotificationEndDate}
                  touched={formik.touched.scheduleNotificationEndDate}
                />
              </Box>
            </Box>
          </Box>
          {formik.errors.scheduledDate && (
            <FormHelperText error={true}>{formik.errors.scheduledDate}</FormHelperText>
          )}
        </Box>
      </DialogContent>

      {/* Actions */}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <AppButton sx={{ marginRight: "10px" }} onClick={onClose}>
          Cancel
        </AppButton>
        <AppButton onClick={formik.handleSubmit}>Save</AppButton>
      </Box>
    </StyledDialog>
  )
}

export default RepeatModal

const repeatMenu = [
  { label: "Every Weekday", value: "Every Weekday(Mon-Fri)" },
  { label: "Week", value: "weekly" },
  { label: "Month", value: "monthly" },
  { label: "Year", value: "yearly" },
  { label: "Day", value: "daily" }
]
const weekDaysOptions = [
  { label: "Monday", value: 0 },
  { label: "Tuesday", value: 1 },
  { label: "Wednesday", value: 2 },
  { label: "Thursday", value: 3 },
  { label: "Friday", value: 4 },
  { label: "Saturday", value: 5 },
  { label: "Sunday", value: 6 }
]
const monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "OOctoberct", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
]
const weekOptions = [
  { label: "First", value: "first" },
  { label: "Second", value: "second" },
  { label: "Third", value: "third" },
  { label: "Fourth", value: "fourth" },
  { label: "Last", value: "last" }
]
