import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Box, Grid, InputAdornment, useTheme } from "@mui/material"
import SearchTextField from "components/StyledComponents/SearchTextField"
import SearchIcon from "@mui/icons-material/Search"
import AppButton from "components/StyledComponents/AppButton"
import PushNotificationListTable from "./notificationListTable"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import PushNotificationForm from "./CreateNotificationForm"
import { validationSchemaNotificationPush } from "helpers/validationSchema"
import { addPushNotificationAction, getPushNotificationAction } from "redux/DMV/actions/user"
import { convertDate, payloadDate } from "helpers/functions"
import { superAdminAction } from "redux/superAdmin/slice/user"
import { useDebounce } from "hooks/utils"
import { getRestaurantsAction } from "redux/L&D/actions"

const DMVNotifications = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [openModal, setopenModal] = useState(false)
  const [repeat_schedule, setrepeat_schedule] = useState({})
  const { notificationImageId } = useSelector((store) => store?.superAdmin?.notifications || [])
  const { orderBy } = useSelector((state) => state?.app?.filters || {})
  const debouncedSearchTerm = useDebounce(searchValue, 500)

  const initialValues = {
    notificationName: "",
    uploadThumbnail: "",
    scheduleNotificationDate: "",
    scheduleNotificationTime: "",
    restaurants: ["ALL"],
    streakMilestone: "",
    employeeList: ["ALL"],
    notificationContent: "",
    repeat: "Does Not Repeat"
  }

  const { pushNotification } = useSelector((store) => store?.dmv || [])
  const theme = useTheme()
  const dispatch = useDispatch()

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Push Notifications"
    dispatch(
      getPushNotificationAction({
        page: currentPage,
        search: debouncedSearchTerm,
        orderBy: orderBy
      })
    )
    dispatch(getRestaurantsAction({}))
  }, [currentPage, debouncedSearchTerm, orderBy])

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const handleAddNotification = () => {
    setopenModal(true)
  }

  const handleSubmit = (value) => {
    let EmployeeClassList = value.employeeList
    let transformedList =
      EmployeeClassList?.map((value) => (value === "All" ? ["All"] : [value])) || []
    const NotificationTimeStamp = convertDate(
      value.scheduleNotificationDate,
      value.scheduleNotificationTime
    )

    const NotificationTimeSplit = NotificationTimeStamp.split(" ")
    const payloadStartDate = payloadDate(NotificationTimeSplit)

    const payload = {
      title: value.notificationName,
      scheduled_date: payloadStartDate || "",
      scheduled_time: NotificationTimeSplit[4] || "",
      employee_ids: transformedList,
      content: value.notificationContent || "",
      status: "scheduled",
      is_scheduled: true,
      streaks: value.streakMilestone ? value.streakMilestone : [],
      ...(notificationImageId
        ? { image: notificationImageId }
        : value.uploadThumbnail
        ? {}
        : { image: "" }),
      restaurant_ids: value.restaurants.map((value) => (value === "All" ? ["All"] : [value])),
      ...(value.repeat && value.repeat !== "Does Not Repeat" && repeat_schedule
        ? { repeat_schedule }
        : {})
    }

    dispatch(addPushNotificationAction(payload))
    onClose()
  }

  const onClose = () => {
    setopenModal(false)
    dispatch(superAdminAction?.setNotificationImageId(""))
  }

  return (
    <>
      {openModal && (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchemaNotificationPush}
          initialValues={initialValues}>
          {(formik) => (
            <PushNotificationForm
              onClose={onClose}
              formik={formik}
              open={openModal}
              setRepeatData={setrepeat_schedule}
            />
          )}
        </Formik>
      )}
      <Grid container className="p-0 ">
        <Grid item xs={12}>
          <Header title="Push Notifications" breadCrumbs={breadCrumbs} />
        </Grid>
        <Grid item xs={12} className="d-flex justify-content-end mb-4">
          <Box>
            <SearchTextField
              id="search"
              name="search"
              value={searchValue}
              onChange={handleSearchField}
              fullWidth
              type="search"
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      backgroundColor: theme.palette.background.secondary
                    }}>
                    <SearchIcon htmlColor="#C4C4C4" />
                  </InputAdornment>
                )
              }}
              style={{
                border: "1px solid #C4C4C4",
                backgroundColor: theme.palette.background.secondary,
                borderRadius: 6
              }}
            />
          </Box>
          <Box sx={{ marginLeft: "10px" }}>
            <AppButton onClick={() => handleAddNotification()} styles={{ padding: "12px 20px" }}>
              + Add New Notification
            </AppButton>
          </Box>
        </Grid>
        <Container style={{ minWidth: "100%" }}>
          <Row style={{ width: "100%", overflow: "auto" }}>
            <Col lg="12" className="p-0">
              <PushNotificationListTable
                setPage={setCurrentPage}
                currentPage={currentPage}
                totalPage={pushNotification?.pages}
                rows={pushNotification?.results}
                columns={columns}
              />
            </Col>
          </Row>
        </Container>
      </Grid>
    </>
  )
}

export default DMVNotifications

const columns = [
  {
    id: "Notification Name",
    label: "Notification Name",
    minWidth: 170,
    isSorting: true,
    sortKey: "title"
  },
  {
    id: "Notification Content",
    label: "Notification Content",
    minWidth: 190,
    isSorting: true,
    sortKey: "content"
  },
  {
    id: "Role",
    label: "Role",
    align: "center",
    minWidth: 150
  },
  {
    id: "Streak Count",
    label: "Streak Count",
    align: "center",
    minWidth: 150,
    isSorting: true,
    sortKey: "streaks"
  },
  {
    id: "image",
    label: "Image",
    minWidth: 80,
    align: "center"
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 120
  },
  {
    id: "Resend Notification",
    label: "Resend Notification",
    align: "center",
    minWidth: 160
  }
]
