import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"

import { validationSchemaFrontLineUser } from "helpers/validationSchema"
import { formatDate } from "helpers/functions"
import FrontLineUserForm from "../frontLineUserForm"
import { useDispatch } from "react-redux"
import { EditFrontlineUsersDataAction, getFrontlineUsersDataAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

const initData = {
  fullName: "",
  email: "",
  contactNumber: "",
  role: "",
  address: "",
  hire_date: null,
  reportingManager: "",
  cms_access: false,
  analytics_access: false,
  raffle_access: true
}

const EditFrontLineUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue, setInitialFormValue] = useState(initData)
  const history = useHistory()
  const { frontlineUserData } = useSelector((store) => store?.superAdmin?.frontlineUser ?? {})

  const dispatch = useDispatch()

  function initFormData() {
    const initData = {
      fullName: frontlineUserData?.full_name,
      email: frontlineUserData?.email,
      contactNumber: frontlineUserData?.mobile ?? "",
      userType: frontlineUserData?.role,
      role: frontlineUserData?.emp_job_class,
      address: frontlineUserData?.address ?? "",
      hireDate: frontlineUserData?.hire_date ? new Date(frontlineUserData?.hire_date) : null,
      cms_access: frontlineUserData?.cms_access,
      analytics_access: frontlineUserData?.analytics_access,
      raffle_access: frontlineUserData?.raffle_access
    }
    setInitialFormValue(initData)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    initFormData()
  }, [frontlineUserData])

  function handleSubmit(values) {
    const payload = {
      full_name: values?.fullName,
      email: values?.email,
      country_code: "+1",
      mobile: values?.contactNumber,
      emp_job_class: values?.role,
      role: values?.userType,
      address: values?.address || "",
      hire_date: values?.hireDate ? formatDate(new Date(values?.hireDate)) : null,
      cms_access: values?.cms_access === "true" || values?.cms_access === true,
      analytics_access: values?.analytics_access === "true" || values?.analytics_access === true,
      raffle_access: values?.raffle_access === "true" || values?.raffle_access === true
    }
    const handleRoute = () => {
      history.replace("/super-admin/dashboard/frontline-user-list")
    }

    dispatch(EditFrontlineUsersDataAction({ payload, onClose: handleRoute }))
  }

  useEffect(() => {
    dispatch(getFrontlineUsersDataAction())
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Edit Frontline User" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaFrontLineUser}
        initialValues={initialFormValue}>
        {(formik) => <FrontLineUserForm isEditContent={true} formik={formik}></FrontLineUserForm>}
      </Formik>
    </>
  )
}

export default EditFrontLineUser
