import React from "react"
import "./imageDisplay.scss"
import ImageComponent from "components/ImageComponent"
import image from "assets/images/imagePlaceholder.png"

function ImageDisplay({ data, keyProp }) {
  if (!data) {
    return (
      <div className="imageContainer">
        <img
          className="loadingImage"
          src={image}
          style={{ ...(keyProp ? { height: "180px", width: "180px", objectFit: "cover" } : {}) }}
        />
      </div>
    )
  }

  return <ImageComponent image={data} keyProp={keyProp} />
}

export default ImageDisplay
