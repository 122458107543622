import { Box, styled } from "@mui/material"
import Shimmer from "components/Shimmer/Shimmer"
import YoutubeFrame from "components/YoutubeFrame/YoutubeFrame"
import React, { useState } from "react"
import PlayIcon from "assets/icons/PlayIcon.svg"
import ImageDisplay from "components/ImageDisplayer"
import { getYoutubeIdByUrl } from "helpers/functions"

const StyledContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  boxSizing: "border-box",
  "& .thumbnailCtr": {
    height: 250,
    width: "100%",
    position: "absolute",
    cursor: "pointer",
    "& .thumbnail": {
      height: 250,
      width: "100%",
      borderRadius: 12,
      objectFit: "cover"
    },
    "& .playIcon": {
      position: "absolute",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 5
    }
  }
}))

const WineVideoPlayer = ({ video, usePopup = true }) => {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [isVideoPlayed, setIsVideoPlayed] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handleThumbnailClick = () => {
    if (usePopup) {
      setIsPopupOpen(true)
    } else {
      setIsVideoPlayed(true)
    }
  }

  return (
    <StyledContainer>
      {!videoLoaded ? (
        <Shimmer type="rounded" height="250px" styles={{ marginLeft: 0, marginRight: 0 }} />
      ) : (
        <>
          {isVideoPlayed ? null : (
            <Box className="thumbnailCtr" onClick={handleThumbnailClick}>
              <Box className="playIcon">
                <img src={PlayIcon} alt="Play" />
              </Box>
              <ImageDisplay data={video?.image_url} />
            </Box>
          )}
        </>
      )}

      {!usePopup && (
        <YoutubeFrame
          videoLoaded={videoLoaded}
          setVideoLoaded={setVideoLoaded}
          url={`https://www.youtube.com/embed/${getYoutubeIdByUrl(video?.video)}${
            isVideoPlayed || isPopupOpen ? "?autoplay=1&loop=1&rel=0&wmode=transparent" : ""
          }`}
          style={{
            width: "100%",
            visibility: isVideoPlayed ? "visible" : "hidden"
          }}
          isPopup={false}
        />
      )}

      {usePopup && (
        <YoutubeFrame
          videoLoaded={videoLoaded}
          setVideoLoaded={setVideoLoaded}
          url={`https://www.youtube.com/embed/${getYoutubeIdByUrl(video?.video)}${
            isVideoPlayed || isPopupOpen ? "?autoplay=1&loop=1&rel=0&wmode=transparent" : ""
          }`}
          style={{
            width: "560px",
            height: "315px"
          }}
          isPopup={true}
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
        />
      )}
    </StyledContainer>
  )
}

export default WineVideoPlayer
