import React from "react"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import image from "assets/images/imagePlaceholder.png"
import styles from "../generateVipQuestions.module.scss"
import DMVRadioButton from "components/DMVRadioButton"

const ChooseCorrectImage = ({ selectedImages, setSelectedImages }) => {
  const theme = useTheme()
  const { VIPImageData } = useSelector((state) => state?.dmv)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2Italic" color={theme.palette.text.secondary}>
          Step-3
        </Typography>
      </Grid>
      {VIPImageData?.map((VIP, index) => {
        const images = VIP?.images?.length > 0 ? VIP?.images : [{ id: "1234", url: image }]
        return (
          <Grid item xs={12} key={index}>
            <Typography variant="body4Regular" color={theme.palette.text.secondary} mt={2}>
              {`Choose VIP Image - ${VIP?.name}`}
            </Typography>
            <Grid container item xs={12} spacing={2} sx={{ mt: 2 }}>
              {images?.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={item.id}>
                  <Box className={styles.Container}>
                    <button
                      className={styles.radioButton}
                      onClick={() =>
                        setSelectedImages((prev) => {
                          return prev.includes(item?.id)
                            ? prev.filter((id) => id !== item?.id)
                            : [...prev, item?.id]
                        })
                      }>
                      <DMVRadioButton
                        className={styles.radio}
                        checked={selectedImages.includes(item?.id)}
                      />
                    </button>
                    <Box className={styles.imageContainer}>
                      <img className={styles.profileImage} src={item?.url || image} alt="VIP" />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ChooseCorrectImage
