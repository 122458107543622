import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { FormHelperText, IconButton } from "@mui/material"
import RedCloseIcon from "assets/icons/RedCloseIcon.svg"
import "./AppDatePicker.scss"

export default function AppDatePicker({
  value = new Date(),
  onChange = () => {},
  error,
  touched,
  placeholder = "DD MMM YYYY",
  ...props
}) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="w-100 picker-button"
          value={value}
          onChange={onChange}
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: (theme) => ({
              backgroundColor: theme.palette.background.default,
              "& .MuiCalendarPicker-root": {
                borderRadius: "12px"
              }
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                style: { cursor: "pointer" },
                ...(placeholder ? { placeholder } : {})
              }}
              onClick={() => setOpen(true)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {value && value !== null && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation()
                          onChange(null)
                        }}
                        sx={{ marginRight: "4px" }}>
                        <img
                          className="flex-shrink-0"
                          role="button"
                          src={RedCloseIcon}
                          alt="Clear"
                          style={{ width: 20 }}
                        />
                      </IconButton>
                    )}
                    {params.InputProps.endAdornment &&
                      React.cloneElement(params.InputProps.endAdornment, {
                        onClick: (e) => {
                          e.stopPropagation()
                        },
                        sx: {
                          "& .MuiSvgIcon-root": {
                            cursor: "default"
                          }
                        }
                      })}
                  </>
                )
              }}
            />
          )}
          inputFormat="dd MMM yyyy"
          {...props}
        />
      </LocalizationProvider>
      {error && touched ? <FormHelperText error={true}>{error}</FormHelperText> : null}
    </>
  )
}
