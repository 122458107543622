import React, { useState } from "react"
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  InputAdornment,
  Card,
  TextField,
  IconButton
} from "@mui/material"
import { styled, useTheme } from "@mui/system"
import SearchIcon from "assets/icons/Search.svg"
import { useDispatch, useSelector } from "react-redux"
import { useDebounce } from "hooks/utils"
import AppPagination from "components/StyledComponents/AppPagination"
import VantaMenuLogo from "assets/icons/VantaMenuLogo.svg"
import CloseIcon from "@mui/icons-material/Close"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getVIPListAction } from "redux/DMV/actions/user"
import { DMVActions } from "redux/DMV/slice/user"
import styles from "./chooseRestaurants.module.scss"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.white.main,
    padding: "10px",
    borderRadius: "8px"
  }
}))

const StyledCard = styled(Card)(({ theme }) => ({
  color: theme.palette.white.main,
  border: "1px solid #C4C4C480",
  borderRadius: "8px",
  textAlign: "center",
  padding: "16px",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.background.main,
    boxShadow: "0px 11px 33.1px -10px rgba(173, 145, 111, 0.30)"
  }
}))

const CustomSearchField = styled(TextField)(() => ({
  background: "transparent",
  border: "1px solid #C4C4C480",
  borderRadius: 8
}))

const ChooseRestaurantsModal = ({ open, onClose = () => {} }) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRestaurant, setSelectedRestaurant] = useState({})
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const { VIPRestaurantsList } = useSelector((store) => store.dmv)

  const style = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "15px",
      right: "15px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  const handleRestaurant = (restaurant) => {
    dispatch(DMVActions.setVIPRestaurant(restaurant?.target_restaurant))
    dispatch(getVIPListAction({ id: restaurant?.target_restaurant, handleRoute }))
    setSelectedRestaurant(restaurant)
  }

  const handleRoute = () => {
    history.push("/dmv/dashboard/generate-vip-questions")
  }
  const filterResults = (searchQuery, results) => {
    if (searchQuery.trim() === "") {
      return results
    }
    return results?.filter((item) => {
      const { name, location } = item
      const includesSearchQuery = (string) =>
        string.toLowerCase().includes(searchQuery.toLowerCase())
      const nameMatch = searchQuery && includesSearchQuery(name)
      const locationMatch = location ? includesSearchQuery(location) : false

      return nameMatch || locationMatch
    })
  }

  const filteredResults = filterResults(debouncedSearchTerm, VIPRestaurantsList)

  const itemsPerPage = 6
  const totalPages = Math.ceil(filteredResults?.length / itemsPerPage)

  const filterRestaurantList = filteredResults?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={onClose} sx={style.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Typography className="mb-2 " variant="h3" textAlign={"center"}>
          Select Restaurant
        </Typography>
        <Typography
          className="mb-3 d-flex align-items-center"
          sx={{ textAlign: "center" }}
          variant="body5Regular">
          Please select the restaurant from below where this question will be added to the question
          bank.
        </Typography>
        {VIPRestaurantsList?.length > 9 && (
          <>
            <Grid item my={4}>
              <CustomSearchField
                type="search"
                autoComplete="off"
                value={searchValue}
                onChange={handleSearchField}
                placeholder="Search Restaurant or Location..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchIcon}
                        style={{
                          cursor: "pointer",
                          fontSize: "22px"
                        }}
                      />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h2" color={theme.palette.white.main}>
                  All Restaurants
                </Typography>
                <Typography
                  variant="body2Light"
                  sx={{ fontSize: 12 }}
                  color={theme.palette.text.secondary}>
                  Showing {filterRestaurantList?.length} out of {VIPRestaurantsList?.length} Results
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <Box mt={4}>
          <Grid container spacing={2}>
            {filterRestaurantList?.length === 0 ? (
              <Grid item xs={12} className="d-flex justify-content-center">
                <Typography variant="h2" color={theme.palette.white.main}>
                  No Restaurants Found
                </Typography>
              </Grid>
            ) : (
              filterRestaurantList?.map((restaurant, index) => (
                <Grid item xs={4} key={index}>
                  <StyledCard
                    className={selectedRestaurant?.id === restaurant.id ? styles.card : ""}
                    onClick={() => handleRestaurant(restaurant)}>
                    <img className={styles.image} src={restaurant?.icon || VantaMenuLogo} />
                    <Grid item xs={12} mb={0.5}>
                      <Typography variant="body1Medium" color={theme.palette.secondary.main}>
                        {restaurant.name}
                      </Typography>
                    </Grid>
                    <Typography
                      variant="tag1"
                      sx={{ fontSize: 11 }}
                      color={theme.palette.text.secondary}>
                      {restaurant.location}
                    </Typography>
                  </StyledCard>
                </Grid>
              ))
            )}
          </Grid>
          {VIPRestaurantsList?.length > 9 && (
            <Grid container justifyContent="flex-end">
              {filterRestaurantList?.length ? (
                <AppPagination
                  onPageChange={setCurrentPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              ) : (
                ""
              )}
            </Grid>
          )}
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default ChooseRestaurantsModal
