import React from "react"
import { List, ListItem, Typography } from "@mui/material"
import Card from "components/Card/Card"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import { useHistory } from "react-router-dom"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

const VipQuestionBankCard = () => {
  const history = useHistory()

  return (
    <>
      <Card style={{ paddingBottom: 12 }}>
        <List>
          <ListItem sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">VIP Questions Bank</Typography>
            <span
              onClick={() => {
                history.push("/dmv/dashboard/vip-question-bank")
              }}>
              <IconButtonWithBorder islight={true}>
                <ArrowForwardIosIcon
                  sx={{
                    color: "white",
                    fontSize: "small"
                  }}
                />
              </IconButtonWithBorder>
            </span>
          </ListItem>
        </List>
      </Card>
    </>
  )
}

export default VipQuestionBankCard
