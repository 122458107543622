import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Popover,
  Radio,
  useTheme
} from "@mui/material"
import { Box, styled } from "@mui/system"
import React, { useEffect, useState } from "react"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import styles from "../../analytics.module.scss"
import AppPagination from "components/StyledComponents/AppPagination"
import { useDispatch, useSelector } from "react-redux"
import { getMonthlyAnalyticsAction } from "redux/DMV/actions/user"
import AppButton from "components/StyledComponents/AppButton"
import Header from "components/Header/Header"
import ExportData from "../../exportData"
import { appActions } from "redux/app/slice/app"
import downloadIcon from "assets/icons/Download.svg"
import trendUpIcon from "assets/icons/trending-up.svg"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatNumberToThousand } from "helpers/functions"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:first-of-type": {
      paddingLeft: 6,
      paddingRight: 27
    },
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    borderBottom: "none !important",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root:first-of-type .MuiTableCell-root": {
      borderBottom: `1px solid #FFFFFF80`
    }
  }
}))

const CustomTabList = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.aqua.main,
    height: "4px"
  },
  "& .MuiTab-root.Mui-selected": {
    color: theme.palette.aqua.main
  },
  "& .MuiTab-root": {
    textTransform: "none",
    padding: "20px 28px 20px 28px"
  },
  "& .MuiTabs-scroller ": {
    marginLeft: "12px"
  }
}))

const MonthlyAnalyticsTable = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedFilter, setSelectedFilter] = useState("monthly")
  const [activeTab, setActiveTab] = useState("trivia_analytics")
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [sortKey, setSortKey] = useState("")
  const [order, setOrder] = useState("ASC")
  const { monthlyAnalytics } = useSelector((state) => state.dmv)
  const { restaurant, period, orderBy } = useSelector((state) => state?.app?.filters || {})

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const dispatch = useDispatch()
  const theme = useTheme()
  const history = useHistory()

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleSelectedFilter = (value) => {
    if (value === "weekly") history.push("/dmv/dashboard/analytics-dashboard")
    dispatch(appActions.initFilters())
    setSelectedFilter(value)
    handleClose()
  }

  const handleSort = (key, order) => {
    const month = key.split(" ")[0]
    setSortKey(key)
    setOrder(order)
    dispatch(appActions.setOrderBy(order === "DESC" ? month : `-${month}`))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const itemsPerPage = 12
  const totalPages = Math.ceil(monthlyAnalytics?.length / itemsPerPage)
  const paginatedList =
    Array.isArray(monthlyAnalytics) &&
    monthlyAnalytics
      ?.filter((item) => item.name !== "Total")
      ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

  useEffect(() => {
    dispatch(
      getMonthlyAnalyticsAction({
        activeTab,
        restaurant,
        period,
        orderBy
      })
    )
  }, [activeTab, restaurant, period, orderBy])

  useEffect(() => {
    setCurrentPage(1)
  }, [monthlyAnalytics])

  useEffect(() => {
    dispatch(appActions.initFilters())
    document.title = "Analytics Dashboard"
    setBreadcrumbs(createBreadcrumbs())
  }, [])

  return (
    <>
      {openModal && (
        <ExportData
          onClose={() => setOpenModal()}
          activeTab={activeTab}
          analyticsType={"analytics"}
        />
      )}
      <Grid item xs={12}>
        <Header title="Analytics Dashboard" breadCrumbs={breadCrumbs}>
          <AppButton
            aria-describedby={id}
            styles={{ padding: "13px 20px", marginRight: "20px" }}
            onClick={handleClick}>
            <img src={trendUpIcon} style={{ marginRight: "8px" }} />
            Trend Analysis
          </AppButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              "& .MuiPopover-paper": {
                width: "196px",
                backgroundColor: theme.palette.background.main,
                border: "1px solid #fff",
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "7px",
                borderBottomRightRadius: "7px"
              }
            }}
            marginThreshold={110}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}>
            <Grid item px={2} py={1}>
              {Filters?.map((item, index) => (
                <Grid
                  container
                  key={index}
                  py={1}
                  justifyContent={"space-between"}
                  onClick={() => handleSelectedFilter(item.value)}>
                  <Typography>{item.label}</Typography>
                  <Radio role="button" color="primary" checked={item.value === selectedFilter} />
                </Grid>
              ))}
            </Grid>
          </Popover>
          <AppButton styles={{ padding: "13px 30px" }} onClick={() => setOpenModal(true)}>
            <img src={downloadIcon} style={{ marginRight: "8px" }} />
            Export
          </AppButton>
        </Header>
      </Grid>
      <Box className={styles.tabContainer}>
        <TabContext value={activeTab}>
          <Box>
            <CustomTabList onChange={handleChange}>
              <Tab label="Trivia Games Played" value="trivia_analytics" />
              <Tab label="Active Mobile Users" value="active_users" />
              <Tab label="Quiz Games" value="quiz_analytics" />
              <Tab label="Total Games Played" value="total_games_played" />
            </CustomTabList>
          </Box>
        </TabContext>
      </Box>
      <TableContainer sx={{ maxHeight: 800 }}>
        <StyledTable stickyHeader aria-label="contest table">
          {!monthlyAnalytics?.length ? (
            <Box className={styles.noDataContainer}>
              <Typography variant="h2">No Data Found</Typography>
            </Box>
          ) : (
            <>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell className={styles.countCell}>
                    <Typography variant="body2Regular" sx={{ opacity: 0.6 }}>
                      #
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.nameCell}>
                    <Typography variant="body2Regular" className={styles.headerText}>
                      Name
                    </Typography>
                  </TableCell>
                  {monthlyAnalytics?.[0] &&
                    Object.keys(monthlyAnalytics[0])
                      ?.filter((key) => key !== "name")
                      ?.map((key, index) => (
                        <TableCell
                          key={index}
                          align="center"
                          className={[styles.quizCell, { [styles.evenCell]: index % 2 === 0 }]}>
                          <Typography variant="body2Regular" className={styles.headerText}>
                            {key}
                            <span style={{ cursor: "pointer" }}>
                              {order === "DESC" && sortKey === key ? (
                                <ArrowDownwardIcon
                                  onClick={() => handleSort(key, "ASC")}
                                  fontSize="small"
                                />
                              ) : (
                                <ArrowUpwardIcon
                                  onClick={() => handleSort(key, "DESC")}
                                  fontSize="small"
                                />
                              )}
                            </span>
                          </Typography>
                        </TableCell>
                      ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {monthlyAnalytics
                  ?.filter((item) => item.name === "Total")
                  .map((item) => (
                    <TableRow key={item.name}>
                      <TableCell className={styles.countCell}>
                        <Typography variant="caption2">0</Typography>
                      </TableCell>
                      <TableCell align="left" className={styles.nameCell}>
                        <Typography variant="body3Regular">{item.name}</Typography>
                      </TableCell>
                      {Object.keys(item)
                        ?.filter((key) => key !== "name")
                        ?.map((key, idx) => (
                          <TableCell
                            key={key}
                            align="center"
                            className={idx % 2 === 0 && styles.evenCell}>
                            <Typography variant="body3Regular">
                              {typeof item[key] === "object"
                                ? `${formatNumberToThousand(item[key]?.total) || "-"}`
                                : formatNumberToThousand(item[key]) || "-"}
                            </Typography>
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
                {paginatedList
                  ?.filter((row) => row.name !== "Total")
                  ?.map((row, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell className={styles.countCell}>
                          <Typography variant="body2">
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" className={styles.nameCell}>
                          <Typography variant="body1Regular">{row.name}</Typography>
                        </TableCell>
                        {Object.keys(row)
                          ?.filter((key) => key !== "name")
                          ?.map((key, idx) => (
                            <TableCell
                              key={idx}
                              align="center"
                              className={idx % 2 === 0 && styles.evenCell}>
                              <Typography variant="body1Regular">
                                {typeof row[key] === "object"
                                  ? formatNumberToThousand(row[key]?.total) || "-"
                                  : formatNumberToThousand(row[key]) || "-"}
                              </Typography>
                            </TableCell>
                          ))}
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </>
          )}
        </StyledTable>
      </TableContainer>
      {monthlyAnalytics?.length ? (
        <AppPagination
          onPageChange={setCurrentPage}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default MonthlyAnalyticsTable
const Filters = [
  {
    label: "Weekly",
    value: "weekly"
  },
  {
    label: "Monthly",
    value: "monthly"
  }
]
