import ButtonNav from "components/ButtonNav"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"

const tabItems = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  BREAKFAST: "breakfast",
  BAR_MENU: "bar menu",
  WINE: "wine by glass",
  WINEBOTTLE: "wine by bottle",
  SPIRITS: "spirits",
  SPECIALS: "specials"
}

function TriviaMenuNavBar(props) {
  const [menu] = useState(menuNav)
  const dispatch = useDispatch()

  function handleButtonNav(item) {
    dispatch(learningDevelopmentActions.setGenerate_Question_MenuLink(item))
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <ButtonNav
            style={{
              marginRight: 12,
              marginTop: i === menuNav.length - 1 ? 13 : 0
            }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </ButtonNav>
        )
      })}
    </>
  )
}

export default TriviaMenuNavBar

const menuNav = [
  {
    name: "Specials",
    isActive: false,
    value: tabItems?.SPECIALS
  },
  {
    name: "Dinner",
    isActive: true,
    value: tabItems?.DINNER
  },
  {
    name: "Lunch",
    isActive: false,
    value: tabItems?.LUNCH
  },
  {
    name: "Brunch",
    isActive: false,
    value: tabItems?.BRUNCH
  },
  {
    name: "Breakfast",
    isActive: false,
    value: tabItems?.BREAKFAST
  },
  {
    name: "Bar Menu",
    isActive: false,
    value: tabItems?.BAR_MENU
  },
  {
    name: "Wine By Glass",
    isActive: false,
    value: tabItems?.WINE
  },
  {
    name: "Wine By Bottle",
    isActive: false,
    value: tabItems?.WINEBOTTLE
  },
  {
    name: "Spirits",
    isActive: false,
    value: tabItems?.SPIRITS
  }
]
