import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { Formik } from "formik"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useRef, useState } from "react"
import AddQuizForm from "./addQuizForm"
import { validationSchemaQuiz } from "helpers/validationSchema"
import { Typography } from "@material-ui/core"
import { useTheme } from "@emotion/react"
import { useDispatch, useSelector } from "react-redux"
import { createQuizAction } from "redux/DMV/actions/user"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { formatDate } from "helpers/functions"
import { DMVActions } from "redux/DMV/slice/user"

const AddQuiz = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const { isVIP, selectedVIPQuestions, VIPRestaurant } = useSelector((store) => store?.dmv)

  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const isDraft = useRef(false)
  const location = useLocation()

  const initialFormValue = {
    quizName: isVIP ? `VIP Quiz - ${new Date().toLocaleDateString()}` : "",
    restaurants: isVIP ? [`${VIPRestaurant}`] : ["All"],
    category: isVIP ? "VIP" : "",
    start_date: new Date(),
    end_date: new Date(),
    timeLimit: "5"
  }

  const handleSaveAsDraft = (formik) => {
    isDraft.current = true
    formik.handleSubmit()
  }

  const handleSubmit = (values) => {
    const RestaurantList = values?.restaurants
    const questions = selectedVIPQuestions?.map((question) => question?.id)
    const payload = {
      name: values?.quizName,
      start_date: formatDate(values?.start_date),
      end_date: formatDate(values?.end_date),
      status: "DRAFT",
      category: values?.category,
      duration: values.timeLimit,
      restaurants: RestaurantList?.includes("All") ? [] : RestaurantList,
      all_restaurants: RestaurantList?.includes("All"),
      ...(isVIP && { question_ids: questions })
    }
    dispatch(createQuizAction({ payload, handleRoute }))
    if (isVIP) {
      dispatch(DMVActions.setIsVIP(false))
    }
  }

  const handleRoute = () => {
    !isDraft.current
      ? history.replace("/dmv/dashboard/quiz/question-bank")
      : history.replace("/dmv/dashboard/quiz")
  }

  useEffect(() => {
    return () => {
      dispatch(DMVActions.setIsVIP(false))
      dispatch(DMVActions.setVIPRestaurant(""))
    }
  }, [location.pathname])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Create Quiz"
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaQuiz}
          initialValues={initialFormValue}>
          {(formik) => (
            <>
              <Grid item xs={12}>
                <Header title="DMV Quiz" breadCrumbs={breadCrumbs}>
                  <Typography
                    variant="body1Regular"
                    onClick={() => handleSaveAsDraft(formik)}
                    style={{
                      color: theme.palette.white.main,
                      textDecoration: "underline",
                      margin: "13px 50px",
                      cursor: "pointer",
                      textUnderlineOffset: "2px"
                    }}>
                    Save Draft
                  </Typography>
                </Header>
              </Grid>
              <AddQuizForm
                formik={formik}
                isVIP={isVIP}
                selectedVIPQuestions={selectedVIPQuestions}
              />
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default AddQuiz
