// Export all the private routes

import Dashboard from "pages/private/learning_development/dashboard"
import LeaderBoard from "pages/private/learning_development/LeaderBoard"
import TriviaAnalyticsSubScreen from "pages/private/learning_development/triviaAnalyticsSubScreen"
import TriviaCalendar from "pages/private/learning_development/trivia-calendar"
import TriviaAnalytics from "pages/private/learning_development/trivia-analytics"
import TriviaContest from "pages/private/learning_development/trivia-contest"
import WineOfTheMonth from "pages/private/learning_development/wine-of-the-month"
import ViewTriviaContest from "pages/private/learning_development/view-trivia-contest"
import EditTrivia from "pages/private/learning_development/trivia-contest/EditTriviaContest/editTrivia"
import NewContest from "pages/private/learning_development/trivia-contest/NewContest/NewContest"
import NewContestAddQuestions from "pages/private/learning_development/trivia-contest/NewContest/NewContestAddQuestions"
import EditTriviaAddQuestions from "pages/private/learning_development/trivia-contest/EditTriviaContest/editTriviaAddQuestion"
import MarketingDashboard from "pages/private/marketing/dashboard"
import MarketingTriviaCalendar from "pages/private/marketing/trivia-calendar"
import MarketingLeaderboard from "pages/private/marketing/leaderboard"
import tracking from "pages/private/general_manager/tracking"
import MarketingWineOfTheMonth from "pages/private/marketing/wine-of-the-month"
import MarketingAdditionalResources from "pages/private/marketing/additional-resources"
import GMDashboard from "pages/private/general_manager/dashboard"
import GMTriviaCalendar from "pages/private/general_manager/trivia-calendar"
import GMPerformanceSummary from "pages/private/general_manager/performanceSummary"
import GMLeaderBoard from "pages/private/general_manager/leaderboard"
import GMPerformanceManagement from "pages/private/general_manager/performanceManagement"
import GMAdditionalInformation from "pages/private/general_manager/AdditionalInformation"
import GMTriviaAnalytics from "pages/private/general_manager/TriviaAnalytics"
import GMSuggestQuestion from "pages/private/general_manager/SuggestQuestion"
import GMPerformanceManagementProgress from "pages/private/general_manager/performanceManagementProgress"
import GMApprovePerformanceManagement from "pages/private/general_manager/ApprovePerformanceMgmt"
import GMNotifications from "pages/private/general_manager/Notifications"
import DMVDashboard from "pages/private/DVM_Console/dashboard"
import DMVSettings from "pages/private/DVM_Console/Settings"
import DMVTriviaCategory from "pages/private/DVM_Console/triviaCategory"
import InviteRate from "pages/private/DVM_Console/inviteRate"
import DMVWineClubMembership from "pages/private/DVM_Console/wineClubMembership"

import ClientInformation from "pages/private/DVM_Console/clientOnboarding/clientInformationTable"
import RestaurantInformation from "pages/private/DVM_Console/clientOnboarding/restaurantInformationTable"
import AddClient from "pages/private/DVM_Console/clientOnboarding/addClient"
import AddRestaurant from "pages/private/DVM_Console/clientOnboarding/addRestaurant"
import UsageDashboard from "pages/private/DVM_Console/userMetrics/usageDashboard"
import PerformanceManagementContent from "pages/private/DVM_Console/performanceMgmtContent"
import videoAnalytics from "pages/private/DVM_Console/userMetrics/videoAnalytics"
import UserPerformanceManagement from "pages/private/DVM_Console/userMetrics/userPerformanceManagement"
import ExecutiveDashboard from "pages/private/executive/dashboard"
import ExecutiveTriviaAnalytics from "pages/private/executive/trivia-analytics"
import TopUsers from "pages/private/DVM_Console/userMetrics/topUsers"
import ExecutivePercentGoal from "pages/private/executive/percentGoal"
import BasePeriodGraph from "pages/private/DVM_Console/BasePeriod/BasePeriodGraph"
import BasePeriodTable from "pages/private/DVM_Console/BasePeriod/BasePeriodTable"
import IncrementalMembership from "pages/private/DVM_Console/BasePeriod/incementalMembership"
import POSData from "pages/private/DVM_Console/BasePeriod/POSData"
import ArchivedItems from "pages/private/marketing/menu/ArchivedItems"

import SuperAdminDashboard from "pages/private/superAdmin/dashboard"
import AdminUser from "pages/private/superAdmin/adminUsers"
import FrontlineUser from "pages/private/superAdmin/frontLineUser"
import AddAdminUser from "pages/private/superAdmin/addAdminUser"
import AddFrontLineUser from "pages/private/superAdmin/addFrontlineUser"

import ExecutivePerformanceMgmtProgress from "pages/private/executive/performanceMgmtProgress"
import ExecutivePerformanceSummary from "pages/private/executive/performanceSummary"
import MembershipSignupPerformance from "pages/private/executive/membershipSignupPerformance.js"
import DMVTastingNotesConfig from "pages/private/DVM_Console/DMVTastingNotesConfig/CreateTastingNotes"
import AreaDirectorDashboard from "pages/private/area_director/dashboard"
import AreaDirectorTriviaCalendar from "pages/private/area_director/trivia-calendar"
import AreaDirectorNotifications from "pages/private/area_director/Notifications"
import AreaDirectorWineOfTheMonth from "pages/private/area_director/wine-of-the-month"
import AreaDirectorTriviaAnalytics from "pages/private/area_director/TriviaAnalytics"
import AreaDirectorPercentGoal from "pages/private/area_director/percentGoal"
import AreaDirectorPerformanceMgmtProgress from "pages/private/area_director/performanceMgmtProgress"
import AreaDirectorPerformanceSummary from "pages/private/area_director/performanceSummary"
import AreaDirectorLeaderBoard from "pages/private/area_director/LeaderBoard"
import AreaDirectorMembershipSignup from "pages/private/area_director/memberSignUpPerformance"
import TastingNotesWeek from "pages/private/DVM_Console/TastingNotesWeek"
import ViewTastingNotes from "pages/private/DVM_Console/ViewTastingNote"
import EditTastingNotes from "pages/private/DVM_Console/DMVTastingNotesConfig/EditTastingNotes"
import ViewWineClubMemberShip from "pages/private/DVM_Console/wineClubMembership/viewWineClubMemberShip"
import EditWeekWineClub from "pages/private/DVM_Console/wineClubMembership/EditWeekWineClub"
import LDNotifications from "pages/private/learning_development/LDNotifications"
import DMVNotifications from "pages/private/DVM_Console/DMVPushNotifications"
import TriviaConfiguration from "pages/private/marketing/trivia-configration"
import GrandCru from "pages/private/DVM_Console/GrandCru"
import EditRaffleContest from "pages/private/marketing/trivia-configration/editRaffleContest"
import DMVPiggyBank from "pages/private/DVM_Console/PiggyBank"
import DmvQuiz from "pages/private/DVM_Console/DMV_Quiz"
import AddQuiz from "pages/private/DVM_Console/DMV_Quiz/addQuiz"
import QuizQuestionBank from "pages/private/DVM_Console/DMV_Quiz/questionBank"
import EditQuiz from "pages/private/DVM_Console/DMV_Quiz/editQuiz"
import ViewQuiz from "pages/private/DVM_Console/DMV_Quiz/viewQuiz"
import AnalyticsDashboard from "pages/private/DVM_Console/AnalyticsDashBoard"
import MonthlyAnalyticsTable from "pages/private/DVM_Console/AnalyticsDashBoard/analyticsTable/components/mothlyTable"
import GenerateVipQuestions from "pages/private/DVM_Console/GenerateVIPQuestions"
import VipQuestionBank from "pages/private/DVM_Console/VipQuestionBank"
// import RoundsList from "pages/private/DVM_Console/RoundsList"
// import CreateRound from "pages/private/DVM_Console/Round/CreateRound"
// import PreviewRound from "pages/private/DVM_Console/PreviewRound"
// import AssignRoundVideos from "pages/private/DVM_Console/AssignRoundVideos"
// import ViewRound from "pages/private/DVM_Console/ViewRound"
// import EditRound from "pages/private/DVM_Console/Round/EditRound"
import EditFrontLineUser from "pages/private/superAdmin/addFrontlineUser/editFrontlineUser"
import ViewFrontLineUser from "pages/private/superAdmin/viewFrontLineUser"
import EditAdminUser from "pages/private/superAdmin/addAdminUser/editAdminUser"
import ViewAdminUser from "pages/private/superAdmin/viewAdminUser"
import Menu from "pages/private/marketing/menu"
import EditMenu from "pages/private/marketing/menu/EditMenu"
import AppRelease from "pages/private/superAdmin/appRelease"
import QuestionBank from "pages/private/learning_development/QuestionBank"
import DMVMenu from "pages/private/DVM_Console/DMV_Menu"
import DMVEditMenu from "pages/private/DVM_Console/DMV_Menu/DMVEditMenu"
import DMV_AutoSuggest from "pages/private/DVM_Console/DMV_AutoSuggest"
import DMVGenerate from "pages/private/DVM_Console/DMV_GenerateQuestion"
import SuperAdminNotification from "pages/private/superAdmin/notification"
import GiftCard from "pages/private/superAdmin/giftCard"
import Gamification from "pages/private/superAdmin/Gamification"
import EditGamification from "pages/private/superAdmin/Gamification/EditGamification"
import GamificationLeaderboard from "pages/private/general_manager/Gamification/gamificationLeaderboard"
import QuizLeaderBoard from "pages/private/general_manager/quiz"
import EventManagement from "pages/private/superAdmin/EventManagement"
import AddEvent from "pages/private/superAdmin/EventManagement/AddEvent"
import EditEvent from "pages/private/superAdmin/EventManagement/AddEvent/EditEvent"
import ChooseRestaurant from "pages/public/ChooseRestaurant"
import EditMenuImage from "pages/private/superAdmin/MenuImage/EditMenuImage/editMenuImage"
import MenuImage from "pages/private/superAdmin/MenuImage"
import MenuManagement from "pages/private/superAdmin/MenuManagement"
import RoleManagement from "pages/private/superAdmin/RoleManagement"
import FlaggedQuestion from "pages/private/learning_development/QuestionBank/flaggedQuestion"

export const PrivateRoutes = [
  // Learning Development Routes

  // Marketing Routes

  // General Manager Routes

  //DVM Console

  // Executive Routes
  {
    path: "/executive/dashboard",
    exact: true,
    component: ExecutiveDashboard
  },
  {
    path: "/executive/dashboard/trivia-analytics",
    exact: true,
    component: ExecutiveTriviaAnalytics
  },
  {
    path: "/executive/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: WineOfTheMonth
  },
  {
    path: "/executive/dashboard/percentage-of-goal",
    exact: true,
    component: ExecutivePercentGoal
  },
  {
    path: "/executive/dashboard/performance-management-progress",
    exact: true,
    component: ExecutivePerformanceMgmtProgress
  },
  {
    path: "/executive/dashboard/performance-summary",
    exact: true,
    component: ExecutivePerformanceSummary
  },
  {
    path: "/executive/dashboard/membership-sign-up-performance",
    exact: true,
    component: MembershipSignupPerformance
  },

  // SuperAdmin Routes

  // Area Director
  {
    path: "/area-director/dashboard/",
    exact: true,
    component: AreaDirectorDashboard
  },
  {
    path: "/area-director/dashboard/notifications",
    exact: true,
    component: AreaDirectorNotifications
  },
  {
    path: "/area-director/dashboard/calendar",
    exact: true,
    component: AreaDirectorTriviaCalendar
  },
  {
    path: "/area-director/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: AreaDirectorWineOfTheMonth
  },
  {
    path: "/area-director/dashboard/trivia-analytics",
    exact: true,
    component: AreaDirectorTriviaAnalytics
  },
  {
    path: "/area-director/dashboard/percentage-of-goal",
    exact: true,
    component: AreaDirectorPercentGoal
  },
  {
    path: "/area-director/dashboard/performance-management-progress",
    exact: true,
    component: AreaDirectorPerformanceMgmtProgress
  },
  {
    path: "/area-director/dashboard/performance-summary",
    exact: true,
    component: AreaDirectorPerformanceSummary
  },
  {
    path: "/area-director/dashboard/leaderboard",
    exact: true,
    component: AreaDirectorLeaderBoard
  },
  {
    path: "/area-director/dashboard/membership=sign-up-performance",
    exact: true,
    component: AreaDirectorMembershipSignup
  }
]

export const GmRoutes = [
  {
    path: "/gm/dashboard",
    exact: true,
    component: GMDashboard
  },
  {
    path: "/gm/dashboard/performance-summary/tracking",
    exact: true,
    component: tracking
  },
  {
    path: "/gm/dashboard/calendar",
    exact: true,
    component: GMTriviaCalendar
  },
  {
    path: "/gm/dashboard/leaderboard",
    exact: true,
    component: GMPerformanceSummary
  },
  {
    path: "/gm/dashboard/leaderboard-old",
    exact: true,
    component: GMLeaderBoard
  },
  {
    path: "/gm/dashboard/performance-management-progress",
    exact: true,
    component: GMPerformanceManagementProgress
  },
  {
    path: "/gm/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: WineOfTheMonth
  },
  {
    path: "/gm/dashboard/trivia-analytics",
    exact: true,
    component: GMTriviaAnalytics
  },
  {
    path: "/gm/dashboard/calendar/suggest-a-question",
    exact: true,
    component: GMSuggestQuestion
  },
  {
    path: "/gm/dashboard/notifications",
    exact: true,
    component: GMNotifications
  },
  {
    path: "/gm/dashboard/notifications/performance-management",
    exact: true,
    component: GMPerformanceManagement
  },
  {
    path: "/gm/dashboard/notifications/approve-performance-management",
    exact: true,
    component: GMApprovePerformanceManagement
  },
  {
    path: "/gm/dashboard/notifications/performance-management/additional-information",
    exact: true,
    component: GMAdditionalInformation
  }
]

export const MarketingRoute = [
  {
    path: "/marketing/dashboard",
    exact: true,
    component: MarketingDashboard
  },
  {
    path: "/marketing/dashboard/calendar",
    exact: true,
    component: MarketingTriviaCalendar
  },
  {
    path: "/marketing/dashboard/leaderboard-old",
    exact: true,
    component: MarketingLeaderboard
  },
  {
    path: "/marketing/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: MarketingWineOfTheMonth
  },
  {
    path: "/marketing/dashboard/additional-resources",
    exact: true,
    component: MarketingAdditionalResources
  },
  {
    path: "/marketing/dashboard/contest-configuration",
    exact: true,
    component: TriviaConfiguration
  },
  {
    path: "/marketing/dashboard/contest-configuration/Edit",
    exact: true,
    component: EditRaffleContest
  },
  {
    path: "/marketing/dashboard/leaderboard",
    exact: true,
    component: GMPerformanceSummary
  },
  {
    path: "/marketing/dashboard/explore-menu",
    exact: true,
    component: Menu
  },
  {
    path: "/marketing/dashboard/explore-menu/edit",
    exact: true,
    component: EditMenu
  },
  {
    path: "/marketing/dashboard/explore-menu/archived-items",
    exact: true,
    component: ArchivedItems
  }
]

export const Learning_DevelopmentRoutes = [
  { path: "/learning_development/dashboard", exact: true, component: Dashboard },
  {
    path: "/learning_development/dashboard/trivia-calendar",
    exact: true,
    component: TriviaCalendar
  },
  {
    path: "/learning_development/dashboard/notifications",
    exact: true,
    component: LDNotifications
  },
  {
    path: "/learning_development/dashboard/trivia-contest/trivia-analytics",
    exact: true,
    component: TriviaAnalyticsSubScreen
  },
  {
    path: "/learning_development/dashboard/leaderboard",
    exact: true,
    component: GMPerformanceSummary
  },
  { path: "/learning_development/dashboard/leaderboard-old", exact: true, component: LeaderBoard },
  {
    path: "/learning_development/dashboard/trivia-analytics",
    exact: true,
    component: TriviaAnalytics
  },
  {
    path: "/learning_development/dashboard/trivia-contest",
    exact: true,
    component: TriviaContest
  },
  {
    path: "/learning_development/dashboard/trivia-contest/new-trivia-contest",
    exact: true,
    component: NewContest
  },
  {
    path: "/learning_development/dashboard/trivia-contest/new-trivia-contest/add-questions",
    exact: true,
    component: NewContestAddQuestions
  },
  {
    path: "/learning_development/dashboard/trivia-contest/edit",
    exact: true,
    component: EditTrivia
  },
  {
    path: "/learning_development/dashboard/question-bank/add-questions",
    exact: true,
    component: EditTriviaAddQuestions
  },
  {
    path: "/learning_development/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: WineOfTheMonth
  },
  {
    path: "/learning_development/dashboard/trivia-contest/view-trivia-contest",
    exact: true,
    component: ViewTriviaContest
  },
  {
    path: "/learning_development/dashboard/question-bank",
    exact: true,
    component: QuestionBank
  }
]

export const SuperAdminRoutes = [
  //Gm
  {
    path: "/gm/dashboard",
    exact: true,
    component: GMDashboard
  },
  {
    path: "/gm/dashboard/performance-summary/tracking",
    exact: true,
    component: tracking
  },
  {
    path: "/gm/dashboard/calendar",
    exact: true,
    component: GMTriviaCalendar
  },
  {
    path: "/gm/dashboard/performance-summary",
    exact: true,
    component: GMPerformanceSummary
  },
  {
    path: "/gm/dashboard/leaderboard-old",
    exact: true,
    component: GMLeaderBoard
  },
  {
    path: "/gm/dashboard/leaderboard",
    exact: true,
    component: GMPerformanceSummary
  },
  {
    path: "/gm/dashboard/performance-management-progress",
    exact: true,
    component: GMPerformanceManagementProgress
  },
  {
    path: "/gm/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: WineOfTheMonth
  },
  {
    path: "/gm/dashboard/trivia-analytics",
    exact: true,
    component: GMTriviaAnalytics
  },
  {
    path: "/gm/dashboard/calendar/suggest-a-question",
    exact: true,
    component: GMSuggestQuestion
  },
  {
    path: "/gm/dashboard/notifications",
    exact: true,
    component: GMNotifications
  },
  {
    path: "/gm/dashboard/notifications/performance-management",
    exact: true,
    component: GMPerformanceManagement
  },
  {
    path: "/gm/dashboard/notifications/approve-performance-management",
    exact: true,
    component: GMApprovePerformanceManagement
  },
  {
    path: "/gm/dashboard/notifications/performance-management/additional-information",
    exact: true,
    component: GMAdditionalInformation
  },
  //LnD

  { path: "/learning_development/dashboard", exact: true, component: Dashboard },
  {
    path: "/learning_development/dashboard/trivia-calendar",
    exact: true,
    component: TriviaCalendar
  },
  {
    path: "/learning_development/dashboard/notifications",
    exact: true,
    component: LDNotifications
  },
  {
    path: "/learning_development/dashboard/trivia-contest/trivia-analytics",
    exact: true,
    component: TriviaAnalyticsSubScreen
  },
  {
    path: "/learning_development/dashboard/leaderboard",
    exact: true,
    component: GMPerformanceSummary
  },
  { path: "/learning_development/dashboard/leaderboard-old", exact: true, component: LeaderBoard },
  {
    path: "/learning_development/dashboard/trivia-analytics",
    exact: true,
    component: TriviaAnalytics
  },
  {
    path: "/learning_development/dashboard/trivia-contest",
    exact: true,
    component: TriviaContest
  },
  {
    path: "/learning_development/dashboard/trivia-contest/new-trivia-contest",
    exact: true,
    component: NewContest
  },
  {
    path: "/learning_development/dashboard/trivia-contest/new-trivia-contest/add-questions",
    exact: true,
    component: NewContestAddQuestions
  },
  {
    path: "/learning_development/dashboard/trivia-contest/edit",
    exact: true,
    component: EditTrivia
  },
  {
    path: "/learning_development/dashboard/question-bank/add-questions",
    exact: true,
    component: EditTriviaAddQuestions
  },
  {
    path: "/learning_development/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: WineOfTheMonth
  },
  {
    path: "/learning_development/dashboard/trivia-contest/view-trivia-contest",
    exact: true,
    component: ViewTriviaContest
  },
  {
    path: "/learning_development/dashboard/question-bank",
    exact: true,
    component: QuestionBank
  },
  {
    path: "/learning_development/dashboard/question-bank/add-questions/flagged-questions",
    exact: true,
    component: FlaggedQuestion
  },

  // Marketing
  {
    path: "/marketing/dashboard",
    exact: true,
    component: MarketingDashboard
  },
  {
    path: "/marketing/dashboard/calendar",
    exact: true,
    component: MarketingTriviaCalendar
  },
  {
    path: "/marketing/dashboard/leaderboard-old",
    exact: true,
    component: MarketingLeaderboard
  },
  {
    path: "/marketing/dashboard/featured-Items-of-the-Month",
    exact: true,
    component: MarketingWineOfTheMonth
  },
  {
    path: "/marketing/dashboard/additional-resources",
    exact: true,
    component: MarketingAdditionalResources
  },
  {
    path: "/marketing/dashboard/contest-configuration",
    exact: true,
    component: TriviaConfiguration
  },
  {
    path: "/marketing/dashboard/contest-configuration/Edit",
    exact: true,
    component: EditRaffleContest
  },
  {
    path: "/marketing/dashboard/leaderboard",
    exact: true,
    component: GMPerformanceSummary
  },
  {
    path: "/marketing/dashboard/leaderboard/gamification-leaderboard",
    exact: true,
    component: GamificationLeaderboard
  },
  {
    path: "/marketing/dashboard/leaderboard/quiz-leaderboard",
    exact: true,
    component: QuizLeaderBoard
  },
  {
    path: "/marketing/dashboard/explore-menu",
    exact: true,
    component: Menu
  },
  {
    path: "/marketing/dashboard/explore-menu/edit",
    exact: true,
    component: EditMenu
  },
  {
    path: "/marketing/dashboard/explore-menu/archived-items",
    exact: true,
    component: ArchivedItems
  },

  //Super Admin
  {
    path: "/super-admin/dashboard/",
    exact: true,
    component: SuperAdminDashboard
  },
  {
    path: "/super-admin/dashboard/admin-user-list",
    exact: true,
    component: AdminUser
  },
  {
    path: "/super-admin/dashboard/frontline-user-list",
    exact: true,
    component: FrontlineUser
  },
  {
    path: "/super-admin/dashboard/admin-user-list/add-admin-user",
    exact: true,
    component: AddAdminUser
  },
  {
    path: "/super-admin/dashboard/frontline-user-list/add-frontline-user",
    exact: true,
    component: AddFrontLineUser
  },
  {
    path: "/super-admin/dashboard/frontline-user-list/edit-frontline-user",
    exact: true,
    component: EditFrontLineUser
  },
  {
    path: "/super-admin/dashboard/frontline-user-list/view-frontline-user",
    exact: true,
    component: ViewFrontLineUser
  },
  {
    path: "/super-admin/dashboard/admin-user-list/edit-admin-user",
    exact: true,
    component: EditAdminUser
  },
  {
    path: "/super-admin/dashboard/admin-user-list/view-admin-user",
    exact: true,
    component: ViewAdminUser
  },
  // DMV Console
  {
    path: "/dmv/dashboard-old",
    exact: true,
    component: DMVDashboard
  },
  {
    path: "/dmv/dashboard",
    exact: true,
    component: DMVSettings
  },
  {
    path: "/dmv/dashboard/push-notifications",
    exact: true,
    component: DMVNotifications
  },
  {
    path: "/dmv/dashboard/piggy-bank",
    exact: true,
    component: DMVPiggyBank
  },
  {
    path: "/dmv/dashboard/trivia-categories",
    exact: true,
    component: DMVTriviaCategory
  },
  // {
  //   path: `/dmv/dashboard/rounds`,
  //   exact: true,
  //   component: RoundsList
  // },
  // {
  //   path: `/dmv/dashboard/rounds/create-new-round`,
  //   exact: true,
  //   component: CreateRound
  // },
  // {
  //   path: `/dmv/dashboard/rounds/edit-round`,
  //   exact: true,
  //   component: EditRound
  // },
  // {
  //   path: `/dmv/dashboard/rounds/preview-round`,
  //   exact: true,
  //   component: PreviewRound
  // },
  // {
  //   path: `/dmv/dashboard/rounds/assign-videos`,
  //   exact: true,
  //   component: AssignRoundVideos
  // },
  {
    path: "/dmv/dashboard/4-month-tr-trend",
    exact: true,
    component: InviteRate
  },
  {
    path: "/super-admin/dashboard/tasting-notes-list/tasting-notes-config",
    exact: true,
    component: DMVTastingNotesConfig
  },
  {
    path: "/super-admin/dashboard/tasting-notes-list",
    exact: true,
    component: TastingNotesWeek
  },
  {
    path: "/super-admin/dashboard/tasting-notes-list/view",
    exact: true,
    component: ViewTastingNotes
  },
  {
    path: "/super-admin/dashboard/tasting-notes-list/edit",
    exact: true,
    component: EditTastingNotes
  },
  // {
  //   path: "/dmv/dashboard/tasting-notes-config",
  //   exact: true,
  //   component: DMVTastingNotesConfig
  // },
  // {
  //   path: "/dmv/dashboard/tasting-notes-config/list",
  //   exact: true,
  //   component: TastingNotesWeek
  // },
  {
    path: "/dmv/dashboard/tasting-notes-list/view",
    exact: true,
    component: ViewTastingNotes
  },
  {
    path: "/dmv/dashboard/tasting-notes-list/edit",
    exact: true,
    component: EditTastingNotes
  },
  {
    path: "/dmv/dashboard/wine-club-memberships",
    exact: true,
    component: DMVWineClubMembership
  },
  {
    path: "/dmv/dashboard/client-information",
    exact: true,
    component: ClientInformation
  },
  {
    path: "/dmv/dashboard/restaurant-information",
    exact: true,
    component: RestaurantInformation
  },
  {
    path: "/dmv/dashboard/add-client",
    exact: true,
    component: AddClient
  },
  {
    path: "/dmv/dashboard/add-restaurant",
    exact: true,
    component: AddRestaurant
  },
  {
    path: "/dmv/dashboard/usage-dashboard",
    exact: true,
    component: UsageDashboard
  },
  {
    path: "/dmv/dashboard/performance-management-content",
    exact: true,
    component: PerformanceManagementContent
  },
  {
    path: "/dmv/dashboard/video-analytics",
    exact: true,
    component: videoAnalytics
  },
  {
    path: "/dmv/dashboard/performance-management",
    exact: true,
    component: UserPerformanceManagement
  },
  {
    path: "/dmv/dashboard/top-users",
    exact: true,
    component: TopUsers
  },
  {
    path: "/dmv/dashboard/base-period-graph",
    exact: true,
    component: BasePeriodGraph
  },
  {
    path: "/dmv/dashboard/base-period-table",
    exact: true,
    component: BasePeriodTable
  },
  {
    path: "/dmv/dashboard/incremental-membership",
    exact: true,
    component: IncrementalMembership
  },
  {
    path: "/dmv/dashboard/pos-data",
    exact: true,
    component: POSData
  },
  {
    path: "/dmv/dashboard/wine-club-memberships/list",
    exact: true,
    component: ViewWineClubMemberShip
  },
  {
    path: "/dmv/dashboard/wine-club-memberships/list/edit",
    exact: true,
    component: EditWeekWineClub
  },
  {
    path: "/dmv/dashboard/exclusive-content",
    exact: true,
    component: GrandCru
  },
  // {
  //   path: "/dmv/dashboard/rounds/view-round",
  //   exact: true,
  //   component: ViewRound
  // }
  {
    path: "/super-admin/dashboard/app-release-list",
    exact: true,
    component: AppRelease
  },
  {
    path: "/dmv/dashboard/explore-menu",
    exact: true,
    component: DMVMenu
  },
  {
    path: "/dmv/dashboard/explore-menu/edit",
    exact: true,
    component: DMVEditMenu
  },
  {
    path: "/dmv/dashboard/generate-question",
    exact: true,
    component: DMVGenerate
  },
  {
    path: "/dmv/dashboard/auto-suggest",
    exact: true,
    component: DMV_AutoSuggest
  },
  {
    path: "/dmv/dashboard/vip-question-bank",
    exact: true,
    component: VipQuestionBank
  },
  {
    path: "/dmv/dashboard/generate-vip-questions",
    exact: true,
    component: GenerateVipQuestions
  },
  {
    path: "/dmv/dashboard/quiz",
    exact: true,
    component: DmvQuiz
  },
  {
    path: "/dmv/dashboard/quiz/add-quiz",
    exact: true,
    component: AddQuiz
  },
  {
    path: "/dmv/dashboard/quiz/edit-quiz",
    exact: true,
    component: EditQuiz
  },
  { path: "/dmv/dashboard/quiz/view-quiz", exact: true, component: ViewQuiz },
  {
    path: "/dmv/dashboard/quiz/question-bank",
    exact: true,
    component: QuizQuestionBank
  },
  {
    path: "/dmv/dashboard/analytics-dashboard",
    exact: true,
    component: AnalyticsDashboard
  },
  {
    path: "/dmv/dashboard/analytics-dashboard/monthly-analytics",
    exact: true,
    component: MonthlyAnalyticsTable
  },
  {
    path: "/super-admin/dashboard/notification",
    exact: true,
    component: SuperAdminNotification
  },
  {
    path: "/super-admin/dashboard/gift-card-list",
    exact: true,
    component: GiftCard
  },
  {
    path: "/super-admin/dashboard/gamification",
    exact: true,
    component: Gamification
  },
  {
    path: "/super-admin/dashboard/gamification/edit-gamification",
    exact: true,
    component: EditGamification
  },
  {
    path: "/super-admin/dashboard/event-listing",
    exact: true,
    component: EventManagement
  },
  {
    path: "/super-admin/dashboard/event-listing/add-new-event",
    exact: true,
    component: AddEvent
  },
  {
    path: "/super-admin/dashboard/event-listing/edit-event",
    exact: true,
    component: EditEvent
  },
  { path: "/auth/choose-restaurant", exact: true, component: ChooseRestaurant },
  { path: "/super-admin/dashboard/menu-image", exact: true, component: MenuImage },
  {
    path: "/super-admin/dashboard/menu-image/edit-menu-image",
    exact: true,
    component: EditMenuImage
  },
  { path: "/super-admin/dashboard/menu-management", exact: true, component: MenuManagement },
  { path: "/super-admin/dashboard/role-management", exact: true, component: RoleManagement }
]
