import React, { useState, useEffect } from "react"
import "./imageDisplay.scss"
import loadingImage from "assets/images/imagePlaceholder.png"

function ImageComponent({ image, keyProp }) {
  const [isLoading, setIsLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(image)

  useEffect(() => {
    setIsLoading(true)
    setCurrentImage(image)
  }, [image])

  return (
    <div className="image-wrapper">
      <div className={`image-container loading ${isLoading ? "top" : "bottom"}`}>
        <img
          className="loading-image"
          src={loadingImage}
          style={keyProp ? { height: "180px", width: "180px", objectFit: "cover" } : {}}
        />
      </div>
      <div className={`image-container actual ${isLoading ? "bottom" : "top"}`}>
        <img
          className="actual-image"
          src={currentImage}
          onLoad={() => setIsLoading(false)}
          style={{
            ...(isLoading && {
              height: "0.5px",
              width: "0.5px"
            }),
            ...(keyProp && !isLoading
              ? { height: "180px", width: "180px", borderRadius: "6px" }
              : {})
          }}
        />
      </div>
    </div>
  )
}

export default ImageComponent
