import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useState } from "react"
import styles from "../quiz.module.scss"
import avatar from "assets/images/user/avatarUser.svg"
import AppPagination from "components/StyledComponents/AppPagination"
import TableNoRecords from "components/TableNoRecords"
import { getRankColor } from "helpers/functions"
import ProfileDisplayer from "components/ProfileDisplayer"
import { trimText } from "helpers/functions"
import { Tooltip } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    padding: "24px 24px 12px 0px",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const QuizSummaryTable = ({ rows = [], setPage, currentPage, totalPage, activeToggle }) => {
  const dispatch = useDispatch()
  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const handleSortDirection = (dir, id) => {
    setSortDirection(dir)
    handleSort(dir, id)
  }

  const handleSort = (dir, id) => {
    if (dir === "asc") {
      setSort(id)
      dispatch(appActions.setOrderBy(id))
    } else {
      setSort(`-${id}`)
      dispatch(appActions.setOrderBy(`-${id}`))
    }
  }

  const formatValue = (value) => {
    if (value === "-") return "-"
    switch (activeToggle) {
      case "percentage":
        return `${value}%`
      case "time":
        return `${value}Sec`
      default:
        return value
    }
  }

  return (
    <>
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <CustomTable stickyHeader aria-label="sticky table">
            {!rows?.length ? null : (
              <TableHead>
                <TableRow>
                  <TableCell className={styles.rankCell}>
                    <Typography variant="body2Regular" className={styles.headerText}>
                      Rank
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.nameCell}>
                    <Typography variant="body2Regular" className={styles.headerText}>
                      Name
                    </Typography>
                  </TableCell>
                  {rows[0]?.quizzes?.map((quiz, index) => (
                    <TableCell key={index} align="center" className={styles.quizCell}>
                      {quiz.name.length > 14 ? (
                        <Tooltip title={quiz.name}>
                          <Typography
                            variant="body2Regular"
                            style={{ cursor: "pointer" }}
                            className={styles.headerText}>
                            {trimText(quiz.name, 14)}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body2Regular" className={styles.headerText}>
                          {quiz.name}
                        </Typography>
                      )}
                      <span style={{ cursor: "pointer" }} className={styles.headerText}>
                        {sortDirection === "desc" && sort === `-${quiz?.quiz_id}` ? (
                          <ArrowDownwardIcon
                            onClick={() => handleSortDirection("asc", quiz?.quiz_id)}
                            fontSize="small"
                          />
                        ) : (
                          <ArrowUpwardIcon
                            onClick={() => handleSortDirection("desc", quiz?.quiz_id)}
                            fontSize="small"
                          />
                        )}
                      </span>
                    </TableCell>
                  ))}
                  <TableCell align="center" className={styles.stickyRight}>
                    <Typography variant="body2Regular" className={styles.headerText}>
                      Cumulative
                      <span style={{ cursor: "pointer" }} className={styles.headerText}>
                        {sortDirection === "desc" && sort === `-cumulative` ? (
                          <ArrowDownwardIcon
                            onClick={() => handleSortDirection("asc", "cumulative")}
                            fontSize="small"
                          />
                        ) : (
                          <ArrowUpwardIcon
                            onClick={() => handleSortDirection("desc", "cumulative")}
                            fontSize="small"
                          />
                        )}
                      </span>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {!rows?.length ? (
                <TableNoRecords colSpan={9} label="No Data Found" />
              ) : (
                rows?.map((row) => (
                  <TableRow key={row.rank}>
                    <TableCell className={styles.rankCell}>
                      <div className={styles.rank} style={{ borderColor: getRankColor(row) }}>
                        {row.rank}
                      </div>
                    </TableCell>
                    <TableCell className={styles.nameCell}>
                      <div className={`${styles.tableAvatar}`}>
                        <div
                          className={`${styles.tableAvatar__img} ${
                            row.rank === 1 && styles.borderImg
                          }`}>
                          {row?.image_url ? (
                            <ProfileDisplayer image={row?.image_url} />
                          ) : (
                            <img src={avatar} alt="Default Avatar" />
                          )}
                        </div>
                        <div className="d-flex flex-column">
                          <Typography className="me-2" variant="body2Regular">
                            {row?.name}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    {row?.quizzes?.map((quiz) => (
                      <TableCell key={quiz.quizId} align="center">
                        <Typography variant="body2Regular">{formatValue(quiz?.value)}</Typography>
                      </TableCell>
                    ))}
                    <TableCell align="center" className={styles.stickyRight}>
                      <Typography variant="body2Regular">{formatValue(row?.cumulative)}</Typography>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Paper>
      <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
    </>
  )
}

export default QuizSummaryTable
