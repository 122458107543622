import React, { useEffect, useState } from "react"
import AppSelect from "components/StyledComponents/AppSelect"
import AppTextField from "components/StyledComponents/AppTextField"
import { Container, Col, Row } from "react-bootstrap"
import AppDatePicker from "components/AppDatePicker"
import { Box, Grid, Typography } from "@mui/material"
import StyledLabel from "components/StyledComponents/StyledLabel"
import AppButton from "components/StyledComponents/AppButton"
import { useDispatch, useSelector } from "react-redux"
import MultiSelect from "components/StyledComponents/MultipleSelect"
import EditQuizQuestion from "../EditPendingQuestion"
import { getQuizCategoryAction } from "redux/DMV/actions/user"
import { getRestaurantsListAction } from "redux/L&D/actions"

const AddQuizForm = ({
  formik,
  disabled = false,
  questions,
  isEdit = false,
  isVIP = false,
  selectedVIPQuestions = [],
  handleAddQuestion = () => {}
}) => {
  const { restaurantList } = useSelector((store) => store.user)
  const { quizCategory, quizData } = useSelector((store) => store.dmv)
  const [restaurant, setRestaurant] = useState([])
  const [quizCategoryList, setQuizListCategory] = useState([])
  const dispatch = useDispatch()

  const handleRestaurantChange = (e) => {
    const selectedRestaurants = e.target.value
    formik.setFieldValue(
      "restaurants",
      selectedRestaurants?.includes("All") ? ["All"] : selectedRestaurants
    )
  }

  useEffect(() => {
    const allKeyPair = [
      { value: "All", label: "All" },
      ...(restaurantList
        ? restaurantList.map((r) => ({
            value: r.id,
            label: r.name
          }))
        : [])
    ]
    setRestaurant(allKeyPair)
  }, [restaurantList])

  useEffect(() => {
    const allKeyPair = [
      ...(quizCategory
        ? quizCategory.map((qc) => ({
            value: qc.name,
            label: qc.name
          }))
        : [])
    ]
    setQuizListCategory(allKeyPair)
  }, [quizCategory])

  useEffect(() => {
    dispatch(getRestaurantsListAction())
    dispatch(getQuizCategoryAction())
  }, [])

  return (
    <>
      <Container fluid>
        <Row className="mt-5">
          <Col lg="4">
            <AppTextField
              id="quizName"
              name="quizName"
              fullWidth
              type="text"
              label="Quiz Name"
              isRequired={true}
              isUpperCase={true}
              isDisabled={disabled}
              placeholder="Enter Quiz Name"
              value={formik.values.quizName}
              onChange={formik.handleChange}
              error={formik.touched.quizName && formik.errors.quizName}
              helperText={formik.touched.quizName && formik.errors.quizName}
            />
          </Col>
          <Col lg="4">
            <MultiSelect
              id="restaurants"
              name="restaurants"
              menuItems={restaurant}
              label="Restaurant"
              placeholder="Select Restaurant"
              isRequired={true}
              disabled={disabled}
              value={formik.values?.restaurants}
              onChange={handleRestaurantChange}
              error={formik.errors.restaurants}
              touched={formik.touched.restaurants}
            />
          </Col>
          <Col lg="4">
            <AppSelect
              id="category"
              name="category"
              fullWidth
              label="Category"
              disabled={disabled}
              menuItems={quizCategoryList}
              isRequired={true}
              value={formik.values.category}
              onChange={formik.handleChange}
              error={formik.touched.category && formik.errors.category}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4">
            <Grid item xs={12} className="datesContainer">
              <StyledLabel variant="body1Regular">
                {"Start Date"}
                <span>*</span>
              </StyledLabel>
              <Box className="dateFieldContainer w-100 mt-2">
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  fullWidth
                  disabled={disabled}
                  value={formik.values.start_date}
                  onChange={(val) => formik.setFieldValue("start_date", val)}
                  error={formik.errors.start_date}
                  touched={formik.touched.start_date}
                />
              </Box>
            </Grid>
          </Col>
          <Col lg="4">
            <Grid item xs={12} className="datesContainer">
              <StyledLabel variant="body1Regular">
                {"End Date"}
                <span>*</span>
              </StyledLabel>
              <Box className="dateFieldContainer w-100 mt-2">
                <AppDatePicker
                  minDate={new Date()}
                  maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                  inputFormat="dd MMM yyyy"
                  disableMaskedInput
                  fullWidth
                  disabled={disabled}
                  value={formik.values.end_date}
                  onChange={(val) => formik.setFieldValue("end_date", val)}
                  error={formik.errors.end_date}
                  touched={formik.touched.end_date}
                />
              </Box>
            </Grid>
          </Col>
          <Col lg="4">
            <AppSelect
              id="timeLimit"
              name="timeLimit"
              label="Time limit per question"
              menuItems={timelimit}
              fullWidth
              isRequired={true}
              disabled={disabled}
              value={formik.values.timeLimit}
              onChange={formik.handleChange}
              error={formik.touched.timeLimit && formik.errors.timeLimit}
            />
          </Col>
        </Row>
      </Container>
      {quizData?.status?.toLowerCase() !== "published" && (
        <>
          <Container fluid className="mt-5">
            <Row>
              <Col
                lg="12"
                className={`d-flex  ${
                  !isEdit ? "justify-content-end" : "justify-content-between"
                }`}>
                {isEdit && <Typography variant="h2">Questions</Typography>}
                <AppButton
                  disabled={disabled}
                  onClick={() => {
                    {
                      isEdit ? handleAddQuestion(formik) : formik.handleSubmit()
                    }
                  }}>
                  Add Questions
                </AppButton>
              </Col>
            </Row>
          </Container>
          {(isEdit || (isVIP && selectedVIPQuestions.length > 0)) && (
            <Container fluid className="mt-4">
              <Row>
                <Col lg="12">
                  <EditQuizQuestion
                    isEdit={isEdit}
                    rows={isVIP ? selectedVIPQuestions : questions ?? []}
                    columns={PendingColumns}
                    contestData={quizData}
                  />
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  )
}

export default AddQuizForm

const PendingColumns = [
  {
    id: "#",
    label: "#",
    minWidth: 35
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 300
  },
  {
    id: "Actions",
    label: "Actions",
    minWidth: 10,
    align: "center"
  }
]

const timelimit = [
  {
    value: "5",
    label: "5"
  },
  {
    value: "10",
    label: "10"
  },
  {
    value: "15",
    label: "15"
  },
  {
    value: "20",
    label: "20"
  },
  {
    label: "30",
    value: "30"
  }
]
