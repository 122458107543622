import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import CategoryCard from "./component/categoryCard"
import { useDispatch, useSelector } from "react-redux"
import { getVIPQuestionAction } from "redux/DMV/actions/user"
import QuestionsBanks from "pages/private/learning_development/QuestionBank/components/QuestionBanks"
import { useDebounce } from "hooks/utils"
import { DMVActions } from "redux/DMV/slice/user"

const VipQuestionBank = () => {
  const [breadCrumbs, setBreadcrumbs] = useState("")
  const [selectedCategories, setSelectedCategories] = useState("all")
  const [allSelected, setAllSelected] = useState(false)
  const [page, setPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [questionList, setQuestionList] = useState([])
  const [allQuestions, setAllQuestions] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [questionCategoryCount, setQuestionCategoryCount] = useState([])
  const { VIPQuestionBank } = useSelector((store) => store?.dmv)
  const rank = useSelector((store) => store?.app?.filters?.rank)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleClick = (option) => {
    setSelectedCategories(option?.title === "All Restaurants" ? "all" : option?.category)
  }

  useEffect(() => {
    if (selectedCategories.length > 0) {
      dispatch(getVIPQuestionAction({ page: 1, category: selectedCategories }))
    }
  }, [selectedCategories])

  useEffect(() => {
    setAllSelected(selectedCategories?.includes("all"))
  }, [selectedCategories])

  useEffect(() => {
    document.title = "VIP QuestionBank"
    setBreadcrumbs(createBreadcrumbs())
    dispatch(getVIPQuestionAction({ page: 1 }))
    setCurrentPage(1)
  }, [])

  useEffect(() => {
    const newData =
      VIPQuestionBank?.overall_stats?.map((stat) => ({
        title: stat?.restaurant_name,
        category: stat?.restaurant,
        count: stat?.count
      })) || []

    setQuestionCategoryCount(newData)
  }, [VIPQuestionBank])

  function filterResults(result, selectedRank, searchTerm) {
    let filteredResults = result
    if (searchTerm?.trim() !== "") {
      const searchRegex = new RegExp(searchTerm.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i")
      filteredResults = filteredResults.filter(
        (item) =>
          item.answer.some((answer) => searchRegex.test(answer.answer)) ||
          searchRegex.test(item.title) ||
          searchRegex.test(item.food_name)
      )
    }

    if (selectedRank.length > 0) {
      filteredResults = filteredResults.filter((item) => selectedRank.includes(item.rank))
    }

    return filteredResults
  }
  const itemsPerPage = 12
  useEffect(() => {
    const filteredResults = filterResults(VIPQuestionBank?.questions, rank, debouncedSearchTerm)
    setAllQuestions(filteredResults)
    const totalItems = filteredResults?.length || 0
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    setPage(totalPages)
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems)
    const questionList = filteredResults?.slice(startIndex, endIndex)
    setQuestionList(questionList)
    setCurrentPage(1)
  }, [VIPQuestionBank, currentPage, debouncedSearchTerm, rank])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header title="VIP Question Bank" breadCrumbs={breadCrumbs}>
          <AppButton
            styles={{ padding: "12px 24px" }}
            onClick={() => {
              history.push("/dmv/dashboard/quiz/add-quiz")
              dispatch(DMVActions.setIsVIP(true))
            }}>
            Create Quiz
          </AppButton>
        </Header>
      </Grid>
      <Grid item xs={12}>
        <Box className="d-flex w-100 flex-wrap">
          {questionCategoryCount?.map((option, index) => (
            <CategoryCard
              handleClick={() => handleClick(option)}
              title={option?.title}
              key={index}
              count={option?.count}
              isIncluded={
                allSelected
                  ? option?.category === "all"
                  : selectedCategories.includes(option?.category)
              }
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <QuestionsBanks
          pages={page}
          questions={questionList}
          isEditable={true}
          categoryCount={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          showRank={true}
          showImage={true}
          RankOpt={rankOpt}
          searchValue={searchTerm}
          setSearchValue={setSearchTerm}
          isDelete={true}
          isVIP={true}
          allQuestions={allQuestions}
        />
      </Grid>
    </Grid>
  )
}

export default VipQuestionBank
const rankOpt = [1, 2, 3]
