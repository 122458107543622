import * as yup from "yup"
const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .required("Email is Required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid Email Address"),
  password: yup.string().required("Password is Required")
})

const validationSchemaAddContest = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  scope: yup.string().required("Scope is Required"),
  created_by: yup.string().required("Creator is Required"),
  published_week_day: yup.string().required("Publish Weekday is Required"),
  start_date: yup.string().required("Start Date is Required").nullable(),
  creation_date: yup.string().required("Creation Date is Required").nullable()
})

const validationSchemaAddIncorrectContest = yup.object({
  name: yup.string().trim(),
  scope: yup.string().required("Scope is Required"),
  created_by: yup.string().required("Creator is Required"),
  published_week_day: yup.string().required("Publish Weekday is Required"),
  start_date: yup.string().required("Start Date is Required").nullable(),
  creation_date: yup.string().required("Creation Date is Required").nullable()
})

const validationSchemaEditContest = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  scope: yup.string().required("Scope is Required"),
  created_by: yup.string().required("Creator is Required"),
  // start_date: yup.string().required("Start Date is Required").nullable(),
  creation_date: yup.string().required("Creation Date is Required").nullable()
})

const validationSchemaRenameContest = yup.object({
  name: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40)
})

const validationSchemaEditSuggestedQuestion = yup.object({
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  category: yup.string().required("Category Is Required"),
  filterCategory: yup.string().required("Menu Category Is Required"),
  difficultyLevel: yup.string().required("Difficulty Level Is Required"),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    }),
  duration: yup
    .number()
    .required("Duration Is Required")
    .lessThan(61, "Duration must be less than or equal to 60")
    .moreThan(29, "Duration must be greater than or equal to 30")
})

const validationSchemaQuizQuestion = yup.object({
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    })
})
const validationSchemaReasonsForm = yup.object({
  questionType: yup.string().required("Please select question type"),
  reasonCodes: yup.string().required("Reason code is required"),
  description: yup
    .string()
    .required("Description is required")
    .max(500, "Description cannot exceed 500 characters")
    .test(
      "description",
      "Description cannot be empty or only spaces",
      (value) => value && value.trim().length > 0
    )
    .test(
      "no-more-than-two-consecutive-spaces",
      () => `Description cannot have more than two consecutive spaces`,
      (value) => {
        return !/\s{3,}/.test(value)
      }
    )
})

const validationSchemaAddQuestions = yup.object({
  questionSet: yup.array().of(
    yup.object().shape({
      category: yup.string().required("Category Is Required"),
      filterCategory: yup.string().required("Menu Category Is Required"),
      difficultyLevel: yup.string().required("Difficulty Level Is Required"),
      question: yup
        .string()
        .trim()
        .required("Question Field Cannot Be Empty")
        .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
      optionSet: yup
        .array()
        .of(
          yup.object().shape({
            name: yup
              .string()
              .trim()
              .required("Option Is Required")
              .test(
                "len",
                "Max 100 characters allowed",
                (val) => !val?.length || val?.length <= 100
              ),
            isCorrect: yup.boolean()
          })
        )
        .min(4, "Please add 4 options")
        .test("one-correct-option", "Please select one correct option", (answers) => {
          return answers?.some((answer) => answer?.isCorrect)
        })
        .test("unique", "All Options must be Unique", function (list) {
          return list?.length === new Set(list?.map((option) => option?.name)).size
        })
    })
  )
})

const validationSchemaSuggestQuestion = yup.object({
  userCategory: yup.string().required("User Category is Required"),
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  suggestedFor: yup.string().required("Select a Trivia Contest"),
  triviaCategory: yup.string().required("Category Is Required"),
  difficultyLevel: yup.string().required("Difficulty Level Is Required"),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    }),
  duration: yup
    .number()
    .required("Duration Is Required")
    .lessThan(61, "Duration must be less than or equal to 60")
    .moreThan(29, "Duration must be greater than or equal to 30")
})

const validationSchemaWineOfTheMonth = yup.object({
  month: yup.string().required("Select a month"),
  nameOfTheWine: yup
    .string()
    .required("Enter name of the Feature Item")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  videoThumbnail: yup.mixed().nullable().required("Video Thumbnail Is Required"),
  videoURL: yup
    .string()
    .trim()
    .required("Video Url is Required")
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  wineImageVideo: yup
    .mixed()
    .nullable()
    .required("Wine Image Is Required")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  wineRegion: yup
    .string()
    .trim()
    // .required("Enter Wine Region")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  grapeVarietals: yup
    .string()
    // .required("Enter Grape Varietals Region")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  description: yup
    .string()
    .required("Enter Description")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  tastingNotes: yup
    .array()
    .of(
      yup
        .string()
        .trim()
        .max(180, "Max 180 characters are allowed")
        .min(1, "Min 1 characters are required")
    )
    .min(1, "Minimum one items is required")
    .max(40, "Maximum 40 items is allowed")
    .required("Provide at least one item"),
  menuPairing: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .trim()
        .test("len", "Max 50 characters allowed", (val) => !val?.length || val?.length <= 50)
        .when("image", {
          is: (image) => !!image,
          then: yup.string().required("Title is mandatory when image is uploaded")
        }),
      image: yup.mixed().nullable()
    })
  ),
  scheduleNotificationDate: yup
    .string()
    .required("Date of Schedule Notification is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required")
})

const validationSchemaWineOfTheMonthImport = yup.object({
  month: yup.string().required("Select a month"),
  nameOfTheWine: yup.string().required("Enter name of the wine"),
  videoURL: yup.string().required("Please enter  video URL"),
  pdfURL: yup.string().required("Please enter  pdf URL")
})

const validationSchemaWineOfTheMonthAdditional = yup.object({
  month: yup.string().required("Select a month"),
  description: yup.string().required("Enter description"),
  videoURL: yup.string().required("Please select a file"),
  pdfURL: yup.string().required("Please select a file")
})

const validationSchemaAddCategories = yup.object({
  categorySet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Category Name Is Required")
          .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
        image: yup.mixed().nullable().required("Category Image Is Required")
      })
    )
    .min(4, "Please add 5 categories")
})

const validationSchemaDMVWineOfMonth = yup.object({
  week: yup.string().required("Select a date"),
  wine: yup
    .string()
    .trim()
    .required("Wine Name Is Required")
    .test("len", "Max 60 characters allowed", (val) => val?.length <= 60),
  origin: yup
    .string()
    .trim()
    .required("Wine Origin Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  personality: yup
    .string()
    .trim()
    .required("Personality Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  grapeVarietals: yup
    .string()
    .trim()
    .required("Grape Varietals Is Required")
    .test("len", "Max 120 characters allowed", (val) => val?.length <= 120),
  wineImage: yup.mixed().nullable().required("Wine Image Is Required"),
  videoUrl: yup
    .string()
    .trim()
    .optional()
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  videoThumbnail: yup
    .mixed()
    .nullable()
    .when("videoUrl", {
      is: (videoUrl) => Boolean(videoUrl),
      then: yup.mixed().nullable().required("Thumbnail Is Required")
    }),
  week_answer: yup.string().required("Wine Color is Required"),
  tastingNotesSet: yup.array().of(
    yup.object().shape({
      flavour: yup
        .string()
        .trim()
        .required("Flavour Is Required")
        .test("len", "Max 18 characters allowed", (val) => val?.length <= 18)
    })
  ),
  menuPairing: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().max(40, "Max 40 characters allowed"),
      image: yup.mixed().nullable()
    })
  ),
  wineCharateristicsSet: yup
    .array()
    .of(
      yup.object().shape({
        characteristic: yup.string().required("Characteristic Is Required"),
        level: yup.string().required("Level Is Required")
      })
    )
    .min(2, "Please add 2 Characteristics")
    .test("unique", "All Characteristics must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.characteristic)).size
    })
})

const validationSchemaDMVSpiritOfMonth = yup.object({
  week: yup.string().required("Select a date"),
  wine: yup
    .string()
    .trim()
    .required("Spirit Name Is Required")
    .test("len", "Max 60 characters allowed", (val) => val?.length <= 60),
  origin: yup
    .string()
    .trim()
    .required("Spirit Origin Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  personality: yup
    .string()
    .trim()
    .required("Personality Is Required")
    .test("len", "Max 27 characters allowed", (val) => val?.length <= 27),
  grapeVarietals: yup
    .string()
    .trim()
    .required("Ingredients Is Required")
    .test("len", "Max 120 characters allowed", (val) => val?.length <= 120),
  wineImage: yup.mixed().nullable().required("Spirit Image Is Required"),
  videoUrl: yup
    .string()
    .trim()
    .optional()
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  videoThumbnail: yup
    .mixed()
    .nullable()
    .when("videoUrl", {
      is: (videoUrl) => Boolean(videoUrl),
      then: yup.mixed().nullable().required("Thumbnail Is Required")
    }),
  week_answer: yup.string().required("Spirit Color is Required"),
  tastingNotesSet: yup.array().of(
    yup.object().shape({
      flavour: yup
        .string()
        .trim()
        .required("Flavour Is Required")
        .test("len", "Max 18 characters allowed", (val) => val?.length <= 18)
    })
  ),
  menuPairing: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().max(40, "Max 40 characters allowed"),
      image: yup.mixed().nullable()
    })
  ),
  wineCharateristicsSet: yup
    .array()
    .of(
      yup.object().shape({
        characteristic: yup.string().required("Characteristic Is Required"),
        level: yup.string().required("Level Is Required")
      })
    )
    .min(2, "Please add 2 Characteristics")
    .test("unique", "All Characteristics must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.characteristic)).size
    })
})

const validationSchemaMembershipImage = yup.object({
  membershipImage: yup.mixed().nullable().required("Membership Image Is Required")
})

const validationSchemaWineClubMembership = yup.object({
  benefitsSet: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .required("Benefit Name Is Required")
        .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
      description: yup
        .string()
        .trim()
        .required("Benefit Detail Is Required")
        .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
      icon: yup.mixed().nullable().required("Benefit Icon Is Required"),
      memberships: yup.number().required("Please Select memberships"),
      tooltip: yup
        .string()
        .trim()
        .test("len", "Max 125 characters allowed", (val) => !val?.length || val?.length <= 125)
    })
  )
})

const validationSchemaWineClubMembershipEdit = yup.object({
  benefitName: yup
    .string()
    .trim()
    .required("Benefit Name Is Required")
    .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
  benefitDetail: yup
    .string()
    .trim()
    .required("Benefit Detail Is Required")
    .test("len", "Max 20 characters allowed", (val) => val?.length <= 20),
  imageIcon: yup.mixed().nullable().required("Benefit Icon Is Required"),
  membership: yup.number().required("Please Select memberships"),
  tooltip: yup
    .string()
    .trim()
    .test("len", "Max 125 characters allowed", (val) => !val?.length || val?.length <= 125)
})

const validationSchemaRaffleTicket = yup.object({
  start_date: yup.date().required("Start Date is Required"),
  time: yup.string().nullable().required("Raffle Time is Required"),
  // dinner_time: yup.string().nullable().required("Raffle Dinner Time is Required"),
  description: yup.string().trim().required("Description Is Required"),
  month: yup.string().required("Month is Required")
  // wine_club_dinner: yup
  //   .date()
  //   .min(yup.ref("start_date"), "Wine Club Dinner Date cannot be less/equals to than Raffle Date")
  //   .required("Dinner Date is Required")
})

const validationSchemaDMVGrandCru = yup.object(
  {
    date: yup.string().required("Month and Year are Required").nullable(),
    description: yup
      .string()
      .trim()
      .required("Description Is Required")
      .test("len", "Max 125 characters allowed", (val) => val?.length <= 125),
    title: yup
      .string()
      .trim()
      .required("Title Is Required")
      .test("len", "Max 100 characters allowed", (val) => val?.length <= 100),
    thumbnail: yup.mixed().nullable().required("Thumbnail Is Required"),
    video: yup.lazy(() =>
      yup
        .mixed()
        .nullable()
        .when("videoUrl", {
          is: (videoUrl) => !videoUrl,
          then: yup.mixed().nullable().required("Video Is Required"),
          otherwise: yup.mixed().optional().nullable()
        })
    ),
    videoUrl: yup.lazy(() =>
      yup.string().when("video", {
        is: (video) => !video,
        then: yup
          .string()
          .trim()
          .required("Video Url Is Required")
          .matches(/https?/, "Invalid URL"),
        otherwise: yup.string().optional().nullable()
      })
    )
  },
  [["video", "videoUrl"]]
)

const validationSchemaPerformanceManagementVideo = yup.object({
  title: yup
    .string()
    .trim()
    .required("Title Is Required")
    .test("len", "Max 125 characters allowed", (val) => val?.length <= 125),
  description: yup
    .string()
    .trim()
    .required("Description Is Required")
    .test("len", "Max 255 characters allowed", (val) => val?.length <= 255),
  videoUrl: yup
    .string()
    .trim()
    .required("Video Url is Required")
    .matches(/^(https?:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/, "Invalid URL"),
  thumbnail: yup.mixed().nullable().required("Thumbnail is Required")
})

const validationSchemaCreateRound = yup.object({
  year: yup.string().required("Year is Required").nullable(),
  round: yup.number().required("Round Is Required").max(15, "Must be less than 15"),
  startDate: yup.string().required("Start Date is Required").nullable(),
  endDate: yup.string().required("End Date is Required").nullable()
})

const validationSchemaFrontLineUser = yup.object({
  role: yup.string().required("Role is Required"),
  fullName: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 50 characters allowed", (val) => val?.length <= 50),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter the valid email id")
    .trim()
    .test(
      "Domain name is not valid",
      "Please enter a valid email",
      (email) =>
        email?.includes(".com") ||
        email?.includes(".co") ||
        email?.includes(".in") ||
        email?.includes(".io") ||
        email?.includes(".ai") ||
        email?.includes(".net") ||
        email?.includes(".org") ||
        email?.includes(".us") ||
        email?.includes(".edu")
    ),
  contactNumber: yup.string().trim(),
  address: yup.string().trim(),
  hireDate: yup.string().nullable()
})

const validationSchemaAdminUser = yup.object({
  role: yup.string().required("Role is Required"),
  fullName: yup
    .string()
    .trim()
    .required("Name is Required")
    .test("len", "Max 50 characters allowed", (val) => val?.length <= 50),
  email: yup.string().trim().required("Email is Required"),
  contactNumber: yup.string().trim(),
  address: yup.string().trim(),
  reportingManager: yup.string().trim(),
  hireDate: yup.string().nullable()
})

const validationSchemaCSV = yup.object({
  csvUrl: yup.string().required("File is Required")
})

const validationSchemaCommentAdd = yup.object({
  comment: yup
    .string()
    .trim()
    .required("Please add a comment")
    .test("len", "Max 20 characters allowed", (val) => val?.length <= 20)
})
const validationSchemaCategoryModal = yup.object({
  category: yup
    .string()
    .trim()
    .required("Please add a Category Text")
    .test("len", "Max 40 characters allowed", (val) => val?.length <= 40)
})

function generateDynamicValidations(fields, item) {
  const schema = {}
  fields.forEach((field) => {
    let validation
    if (field.field_type === "Array") {
      if (field.label === "glossary" || field.label === "unique_facts") {
        validation = yup.array().of(
          yup.object().shape({
            fact: yup
              .string()
              .optional()
              .test("no-only-spaces", `${field.fieldName} cannot contain only spaces`, (value) => {
                return value === undefined || value.trim().length > 0
              })
              .test(
                "no-more-than-two-consecutive-spaces",
                `${field.fieldName} cannot contain more than two consecutive spaces`,
                (value) => {
                  return value === undefined || !/\s{3,}/.test(value)
                }
              )
          })
        )
      } else if (field.label === "ingredients") {
        validation = yup.array().of(
          yup.object().shape({
            name: yup
              .string()
              .optional()
              .test("no-only-spaces", `${field.fieldName} cannot contain only spaces`, (value) => {
                return value === undefined || value.trim().length > 0
              })
              .test(
                "no-more-than-two-consecutive-spaces",
                `${field.fieldName} cannot contain more than two consecutive spaces`,
                (value) => {
                  return value === undefined || !/\s{3,}/.test(value)
                }
              ),
            measurement: yup.mixed().optional()
          })
        )
      } else {
        // Fallback for generic arrays of strings
        validation = yup.array().of(
          yup
            .string()
            .max(500, "Max 500 characters are allowed")
            .optional()
            .test("no-only-spaces", `${field.fieldName} cannot contain only spaces`, (value) => {
              if (value === null || value === undefined || value === "") return true
              return typeof value === "string" && value.trim() !== ""
            })
            .test(
              "no-more-than-two-consecutive-spaces",
              `${field.fieldName} cannot contain more than two consecutive spaces`,
              (value) => {
                if (value === null || value === undefined || value === "") return true
                return !/\s{3,}/.test(value)
              }
            )
        )
      }
    } else if (field.label === "unique_facts") {
      if (field.label === "unique_facts") {
        validation = yup.array().of(
          yup.object().shape({
            fact: yup
              .string()
              .optional()
              .test("no-only-spaces", `${field.fieldName} cannot contain only spaces`, (value) => {
                return value === undefined || value.trim().length > 0
              })
              .test(
                "no-more-than-two-consecutive-spaces",
                `${field.fieldName} cannot contain more than two consecutive spaces`,
                (value) => {
                  return value === undefined || !/\s{3,}/.test(value)
                }
              )
          })
        )
      }
    } else {
      switch (field.field_type) {
        case "Text":
          validation = yup
            .string()
            .optional()
            .test("no-only-spaces", `${field.fieldName} cannot contain only spaces`, (value) => {
              if (value === null || value === undefined || value === "") return true
              return typeof value === "string" && value.trim() !== ""
            })
            .test(
              "no-more-than-two-consecutive-spaces",
              `${field.fieldName} cannot contain more than two consecutive spaces`,
              (value) => {
                if (value === null || value === undefined || value === "") return true
                return !/\s{3,}/.test(value)
              }
            )
            .max(10000, "Max 10000 characters are allowed")

          if (field.label === "notification_date") {
            validation = yup
              .string()
              .optional()
              .test(
                "require-time-if-date",
                "Notification date is required if Notification time is provided",
                function (value) {
                  const parentValues = this.parent
                  const notificationTime = parentValues?.notification_time

                  if (!value && !notificationTime) return true
                  if (value && notificationTime) return true
                  if (!value && notificationTime) return false
                  return true
                }
              )
              .nullable()
          }

          if (field.label === "notification_time") {
            validation = yup
              .string()
              .optional()
              .test(
                "require-date-if-time",
                "Notification time is required if Notification date is provided",
                function (value) {
                  const parentValues = this.parent
                  const notificationDate = parentValues?.notification_date

                  if (!value && !notificationDate) return true
                  if (value && notificationDate) return true
                  if (!value && notificationDate) return false

                  return true
                }
              )
          }

          if (field.label == "image") {
            validation = yup.mixed().nullable()
          }

          if (field.label === "name") {
            validation = yup
              .string()
              .trim()
              .required(`${item} Name is required`)
              .max(500, "Max 500 characters are allowed")
          }
          break

        case "Number":
          validation = yup.number().optional().typeError(`${field.fieldName} must be a number`)

          if (field.label === "abv") {
            validation = yup
              .number()
              .optional()
              .typeError(`${field.fieldName} must be a number`)
              .max(100, `${field.fieldName} should be less than 100`)
          }

          break

        default:
          validation = yup.mixed().optional()
          break
      }
    }

    schema[field.label] = validation
  })

  return yup.object(schema)
}

const validationSchemaVersionAdd = yup.object({
  title: yup.string().trim().required("Title is Required").max(50, "Max 50 characters are allowed"),
  platform: yup.string().trim().required("Platform is Required"),
  appVersion: yup
    .string()
    .trim()
    .required("App Version is Required")
    .max(10, "Max 10 characters are allowed"),
  status: yup.string().trim().required("Status is Required")
})

const validationSchemaWineRegion = yup.object({
  region: yup
    .string()
    .trim()
    .required("Region Name is Required")
    .max(5000, "Max 5000 characters are allowed"),
  Place: yup.string().trim().max(1000, "Max 1000 characters are allowed"),
  famous_wines: yup.string().trim().max(6000, "Max 6000 characters are allowed"),
  white_grape_varietals: yup.string().trim().max(6000, "Max 6000 characters are allowed"),
  red_grape_varietals: yup.string().trim().max(6000, "Max 6000 characters are allowed"),
  terroir: yup.string().trim().max(1000, "Max 1000 characters are allowed"),
  famous_vineyards: yup.string().trim().max(1000, "Max 1000 characters are allowed"),
  unique_fact: yup.string().trim().max(5000, "Max 5000 characters are allowed")
})

const validationSchemaBeverageItems = yup.object({
  cocktailName: yup
    .string()
    .trim()
    .required("Cocktail Name is Required")
    .max(5000, "Max 5000 characters are allowed"),
  description: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  tasteProfile: yup.string().trim().max(6000, "Max 6000 characters are allowed"),
  // unique_fact: yup.string().trim().max(1500, "Max 1500 characters are allowed"),
  origin_name: yup.string().trim().max(1000, "Max 1000 characters are allowed"),
  how_its_made: yup.string().trim().max(1500, "Max 1500 characters are allowed")
})

const validationSchemaTriviaMonth = yup.object({
  month: yup.string().trim().required("Cocktail Name is Required")
})

const validationSchemaEditGamificationQuestion = yup.object({
  question: yup
    .string()
    .trim()
    .required("Question Field Cannot Be Empty")
    .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
  optionSet: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .required("Option Is Required")
          .test("len", "Max 100 characters allowed", (val) => !val?.length || val?.length <= 100),
        isCorrect: yup.boolean()
      })
    )
    .min(4, "Please add 4 options")
    .test("one-correct-option", "Please select one correct option", (answers) => {
      return answers.some((answer) => answer.isCorrect)
    })
    .test("unique", "All Options must be Unique", function (list) {
      return list.length === new Set(list.map((option) => option.name)).size
    })
})

const validationSchemaSpiritItems = yup.object({
  spiritName: yup
    .string()
    .trim()
    .required("Spirit Name is Required")
    .max(50, "Max 50 characters are allowed"),
  description: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  origin_name: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  nose: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  palate: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  finish: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  distillation: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  aging: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  bottling: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  unique_fact: yup.array().of(
    yup.object().shape({
      fact: yup.string().trim().max(5000, "Max 5000 characters are allowed")
    })
  )
})

const validationSchemaNotification = yup.object({
  notificationName: yup
    .string()
    .trim()
    .required("Notification Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  role: yup
    .array()
    .of(yup.string().min(1, "Role is Required"))
    .min(1, "Role is Required")
    .required("Role is Required"),
  scheduleNotificationDate: yup
    .string()
    .required("Date of Schedule Notification is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required"),
  notificationContent: yup
    .string()
    .trim()
    .required("Notification Content is Required")
    .max(100, "Max 100 characters are allowed")
})

const validationSchemaNotificationPush = yup.object({
  notificationName: yup
    .string()
    .trim()
    .required("Notification Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  restaurants: yup.array().required("Restaurant is Required"),
  scheduleNotificationDate: yup
    .string()
    .required("Date of Schedule Notification is Required")
    .nullable(),
  scheduleNotificationTime: yup
    .string()
    .nullable()
    .required("Notification Schedule Time is Required"),
  notificationContent: yup
    .string()
    .trim()
    .required("Notification Content is Required")
    .max(100, "Max 100 characters are allowed"),
  streakMilestone: yup.array(),
  employeeList: yup.array().required("Employee List is Required"),
  repeat: yup.string()
})

const validationSchemaRepeatModal = yup.object().shape({
  scheduledDate: yup.string().required("Schedule Notification Date is Required").nullable(),
  repeat: yup.string().required("Repeat is Required"),
  scheduleNotificationStartDate: yup
    .string()
    .required("Date of Repeating Notification is Required")
    .nullable()
    .test(
      "less start date",
      "Start Date cannot be less than Scheduled Start Date",
      function (value) {
        if (!value || !this.parent.scheduledDate) return true
        return new Date(value) >= new Date(this.parent.scheduledDate)
      }
    ),
  scheduleNotificationEndDate: yup
    .string()
    .required("End Date Repeating Notification is Required")
    .nullable()
    .test("greater scheduled date", "End Date must be greater than Start Date", function (value) {
      if (!value || !this.parent.scheduleNotificationStartDate) return true
      return new Date(value) > new Date(this.parent.scheduleNotificationStartDate)
    }),
  repeatEvery: yup
    .number()
    .typeError("Repeat Every must be a number")
    .required("Repeat Every is Required"),
  selectedDays: yup
    .array()
    .of(yup.number())
    .when("repeat", {
      is: (val) => val === "weekly",
      then: yup.array().of(yup.number()).min(1, "Selected Day is Required"),
      otherwise: yup.array().of(yup.number()).nullable()
    }),
  selectedDay: yup.number().when("repeat", {
    is: (val) => val === "monthly" || val === "yearly",
    then: yup.number().when("monthlyOption", {
      is: "day",
      then: yup
        .number()
        .typeError("Day must be a number")
        .required("Day is Required")
        .min(1, "Day must be at least 1")
        .max(31, "Day must not be greater than 31"),
      otherwise: yup.number().nullable()
    })
  }),
  selectedMonth: yup.number().when("repeat", {
    is: "yearly",
    then: yup.number().when("monthlyOption", {
      is: "week",
      then: yup.number().required("Month is Required"),
      otherwise: yup.number()
    })
  }),
  selectedWeek: yup.string().when("repeat", {
    is: (val) => val === "monthly" || val === "yearly",
    then: yup.string().when("monthlyOption", {
      is: "week",
      then: yup.string().required("Week is Required"),
      otherwise: yup.string()
    })
  }),
  monthlyOption: yup.string().when("repeat", {
    is: (val) => val === "monthly" || val === "yearly",
    then: yup.string().required("Monthly Options is Required"),
    otherwise: yup.string()
  }),
  selectedWeekDay: yup.string().when("repeat", {
    is: (val) => val === "monthly" || val === "yearly",
    then: yup.string().when("monthlyOption", {
      is: "week",
      then: yup.string().required("Day is Required"),
      otherwise: yup.string()
    })
  })
})

const validationSchemaSpiritSummary = yup.object().shape({
  category: yup.string().test("is-not-zero", "Category must be selected", (value) => value !== "0"),
  description: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  origin_name: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  nose: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  palate: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  finish: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  distillation: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  aging: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  bottling: yup.string().trim().max(5000, "Max 5000 characters are allowed"),
  unique_fact: yup.array().of(
    yup.object().shape({
      fact: yup.string().trim().max(5000, "Max 5000 characters are allowed")
    })
  )
})

const validationSchemaGiftCard = yup.object({
  giftCardType: yup.string().required("Prize Type is Required"),
  uploadThumbnail: yup.mixed().nullable().required("Background Image Is Required"),
  header: yup
    .string()
    .trim()
    .required("Header is Required")
    .max(125, "Max 125 characters are allowed"),
  month: yup.string().required("Date is Required").nullable(),
  time: yup.string().nullable().required("Time is Required")
})

const acceptedValues = [1, 5, 10, 15, 20, 25, 50, 100]

const validationSchemaGamification = yup.object({
  profile_picture: yup
    .number()
    .required("Coins are Required")
    .oneOf(acceptedValues, "Invalid number of coins"),
  featured_item: yup
    .number()
    .required("Coins are Required")
    .oneOf(acceptedValues, "Invalid number of coins"),
  tasting_notes: yup
    .number()
    .required("Coins are Required")
    .oneOf(acceptedValues, "Invalid number of coins"),
  flash_cards: yup
    .number()
    .required("Coins are Required")
    .oneOf(acceptedValues, "Invalid number of coins"),
  trivia_award: yup
    .number()
    .required("Coins are Required")
    .oneOf(acceptedValues, "Invalid number of coins"),
  event_rsvp: yup
    .number()
    .required("Coins are Required")
    .oneOf(acceptedValues, "Invalid number of coins")
})

const validationSchemaEvent = yup.object({
  name: yup
    .string()
    .trim()
    .required("Event Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  startDate: yup.string().required("Start Date of Event is Required").nullable(),
  startTime: yup.string().nullable().required("Event Start Time is Required"),
  endTime: yup.string().nullable().required("Event End Time is Required"),
  rsvpDate: yup.string().required("RSVP Deadline Date is Required").nullable(),
  rsvpTime: yup.string().nullable().required("RSVP Deadline Time is Required"),
  eventType: yup.string().required("Event Type is Required"),
  eventLink: yup
    .mixed()
    .nullable()
    .when("eventType", {
      is: "Online",
      then: yup.string().nullable().required("Event Link Is Required").url("Invalid URL format")
    }),
  address: yup
    .string()
    .trim()
    .nullable()
    .when("eventType", {
      is: "Offline",
      then: yup
        .string()
        .nullable()
        .required("Event Address Is Required")
        .matches(/^[\S]+(\s[\S]+)*$/, "Address cannot have multiple whitespaces")
        .test("no-empty-spaces", "Address cannot be empty", (value) => value && value.trim() !== "")
    }),
  description: yup
    .string()
    .required("Enter Description")
    .test("len", "Max 500 characters allowed", (val) => !val?.length || val?.length <= 500),
  notificationDate: yup.string().required("Date of Schedule Notification is Required").nullable(),
  notificationTime: yup.string().nullable().required("Notification Schedule Time is Required"),
  notificationContent: yup
    .string()
    .trim()
    .required("Notification Content is Required")
    .max(100, "Max 100 characters are allowed")
})

const validationSchemaMenuImage = yup.object({
  menuimage: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Category Name is Required")
        .trim()
        .min(1, "Category Name is Required")
        .max(40, "Max 40 characters allowed"),
      image: yup.mixed().nullable()
    })
  )
})

const validationSchemaRoleModal = yup.object({
  role: yup
    .string()
    .required("Please add a Role Name")
    .max(40, "Max 40 characters allowed")
    .test(
      "no-more-than-one-consecutive-space",
      "Role name cannot contain more than one consecutive space",
      (val) => {
        return val ? !/\s{2,}/.test(val) : true
      }
    )
})

const validationSchemaQuiz = yup.object({
  quizName: yup
    .string()
    .trim()
    .required("Quiz Name is Required")
    .test("len", "Max 40 characters allowed", (val) => !val?.length || val?.length <= 40),
  restaurants: yup.array().required("Restaurant is Required"),
  category: yup.string().required("Category is Required"),
  start_date: yup.string().required("Start Date is Required").nullable(),
  end_date: yup.string().required("End Date is Required").nullable(),
  timeLimit: yup.number().required("Time Limit is Required")
})

export {
  validationSchemaEvent,
  validationSchemaLogin,
  validationSchemaAddContest,
  validationSchemaEditSuggestedQuestion,
  validationSchemaReasonsForm,
  validationSchemaAddQuestions,
  validationSchemaSuggestQuestion,
  validationSchemaQuizQuestion,
  validationSchemaWineOfTheMonth,
  validationSchemaWineOfTheMonthImport,
  validationSchemaWineOfTheMonthAdditional,
  validationSchemaAddCategories,
  validationSchemaDMVWineOfMonth,
  validationSchemaEditContest,
  validationSchemaRenameContest,
  validationSchemaWineClubMembership,
  validationSchemaWineClubMembershipEdit,
  validationSchemaMembershipImage,
  validationSchemaRaffleTicket,
  validationSchemaDMVGrandCru,
  validationSchemaPerformanceManagementVideo,
  validationSchemaCreateRound,
  validationSchemaFrontLineUser,
  validationSchemaCSV,
  validationSchemaAdminUser,
  validationSchemaCommentAdd,
  validationSchemaCategoryModal,
  validationSchemaVersionAdd,
  validationSchemaWineRegion,
  validationSchemaBeverageItems,
  validationSchemaTriviaMonth,
  validationSchemaEditGamificationQuestion,
  validationSchemaSpiritItems,
  validationSchemaNotification,
  validationSchemaSpiritSummary,
  validationSchemaDMVSpiritOfMonth,
  validationSchemaGiftCard,
  validationSchemaAddIncorrectContest,
  validationSchemaGamification,
  validationSchemaMenuImage,
  generateDynamicValidations,
  validationSchemaRoleModal,
  validationSchemaQuiz,
  validationSchemaNotificationPush,
  validationSchemaRepeatModal
}
