import React, { useEffect, useState } from "react"
import {
  OutlinedInput,
  Grid,
  styled,
  Select,
  Typography,
  MenuItem,
  FormHelperText,
  Checkbox,
  TextField,
  InputAdornment,
  Box,
  Tooltip,
  useTheme
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { useDebounce } from "hooks/utils"

const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.body1Regular,
  height: 48,
  width: "100%",
  border: `1px solid ${theme.palette.text.secondary} !important`,
  borderRadius: 6,
  padding: "0px",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  backgroundColor: "transparent",
  "& .MuiSelect-root:before": {
    borderBottom: "none !important"
  },
  "& .MuiSelect-select": {
    paddingLeft: "16px"
  },
  "&:focus": {
    borderRadius: 6,
    borderColor: `${theme.palette.text.primary} !important`
  },
  "& .MuiSvgIcon-root": {
    marginRight: "12px",
    color: theme.palette.text.primary
  },
  "&.Mui-error": {
    borderColor: "#A9402F !important"
  },
  "& .MuiSelect-select.Mui-disabled": {
    color: "#969698 !important",
    WebkitTextFillColor: "#969698"
  }
}))

const StyledMenu = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.hover} !important`
  },
  color: theme.palette.text.primary,
  ...theme.typography.body1Regular
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

function getStyles(name, value) {
  return {
    fontWeight: value.indexOf(name) === -1 ? "500" : "600"
  }
}

export default function MultipleSelect({
  label,
  isRequired,
  style = {},
  menuItems = [],
  error,
  touched,
  value = "",
  search,
  onMenuClick = () => {},
  ...props
}) {
  const cleanValue = Array.isArray(value) ? value.filter(Boolean) : []
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchTerm = useDebounce(searchValue, 500)
  const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems)
  const theme = useTheme()

  const filterResults = (items, searchTerm) => {
    if (!searchTerm.trim()) return items
    const searchRegex = new RegExp(searchTerm.trim().replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i")
    return items.filter((item) => searchRegex.test(item.label))
  }

  useEffect(() => {
    const filtered = filterResults(menuItems, debouncedSearchTerm)
    setFilteredMenuItems(filtered)
  }, [debouncedSearchTerm, menuItems])

  const handleSearchField = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setSearchValue(e.target.value)
  }

  const truncateString = (str, maxLength) => {
    if (!str) return ""
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str
  }

  const renderValue = (selected) => {
    if (!selected.length) return <em style={{ color: "#aaa" }}>Select an option</em>
    return selected
      .map((val) => {
        const item = menuItems.find((item) => item.value === val)
        return item ? item.label : ""
      })
      .filter(Boolean)
      .join(", ")
  }

  return (
    <Grid container style={style}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {label ?? "-"}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <StyledSelect
          IconComponent={KeyboardArrowDownIcon}
          variant="standard"
          disableUnderline
          multiple
          value={cleanValue}
          input={<OutlinedInput label="Name" />}
          error={Boolean(error)}
          MenuProps={{
            sx: (theme) => ({
              "& .MuiPaper-root": {
                marginTop: "16px",
                maxHeight: 300,
                backgroundColor: "transparent",
                width: "100px",
                maxWidth: "150%",
                boxShadow: "4px 4px 14px 0px #161616"
              },
              "& .MuiMenu-list": {
                backgroundColor: theme.palette.background.default,
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: "100%"
              },
              "& .MuiMenuItem-root": {
                backgroundColor: theme.palette.background.default,
                "&.Mui-selected": {
                  backgroundColor: `${theme.palette.primary.main} !important`
                }
              }
            }),
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
          renderValue={renderValue}
          {...props}>
          {search && (
            <Box
              style={{
                position: "sticky",
                backgroundColor: "#0A0A0D",
                top: 0,
                zIndex: 1
              }}
              onClick={(e) => e.stopPropagation()}>
              <TextField
                type="search"
                autoComplete="off"
                value={searchValue}
                onChange={handleSearchField}
                onKeyDown={(e) => {
                  e.stopPropagation()
                  if (e.key === "Enter") {
                    e.preventDefault()
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ cursor: "pointer", color: "#ffff", fontSize: "24px" }} />
                    </InputAdornment>
                  )
                }}
                fullWidth
                style={{ backgroundColor: "#0A0A0D" }}
              />
            </Box>
          )}
          {filteredMenuItems.map((item, index) => (
            <StyledMenu
              key={`${item.label}-${index}`}
              value={item?.value}
              style={getStyles(item?.value, cleanValue)}
              onClick={(e) => {
                e.preventDefault()
                onMenuClick(item)
              }}>
              <Checkbox
                checked={cleanValue.includes(item.value)}
                style={{
                  color: theme.palette.secondary.default
                }}
              />
              {item.label.length > 95 ? (
                <Tooltip title={item.label}>
                  <Box component="span">{truncateString(item.label, 95)}</Box>
                </Tooltip>
              ) : (
                item.label
              )}
            </StyledMenu>
          ))}
        </StyledSelect>
      </Grid>
      <FormHelperText error={error || touched}>{touched || error}</FormHelperText>
    </Grid>
  )
}
