import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton, Grid } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppAutocomplete from "components/AppAutocomplete"
import { useDispatch } from "react-redux"
import { uploadMenuItemAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"
import StyledLabel from "components/StyledComponents/StyledLabel"
import AppDatePicker from "components/AppDatePicker"
import AddUniqueFact from "pages/private/DVM_Console/DMV_Menu/AddDMVMenuItems/components/AddUniqueFact"
import AppTextArea from "components/StyledComponents/AppTextArea"
import AddIngrediant from "../AddIngrediant"

const buttonText = {
  EDIT: "Update Item",
  SAVE: "Save Item"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function MenuItemsForm({
  formik,
  open = false,
  onClose = () => {},
  isEdit = false,
  menuFields,
  title
}) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      dishName: "",
      image: "",
      menuDescription: [],
      allergens: [],
      chefDescription: "",
      itemPrice: null,
      scheduleNotificationDate: "",
      scheduleNotificationTime: ""
    })
    dispatch(marketingActions.setMenuImageId(""))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("image", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("image")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "image") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("image", image)
      dispatch(uploadMenuItemAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "image" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  const getComponentType = (label) => {
    switch (label) {
      case "name":
      case "category":
      case "item_state":
      case "service_details":
      case "sourcing":
      case "modifications":
        return "textField"
      case "allergance":
      case "menu_description":
      case "allergens":
      case "ice":
      case "tasting_notes":
        return "autoComplete"
      case "image":
        return "imageUpload"
      case "item_price":
      case "price":
        return "numberField"
      case "unique_facts":
      case "glossary":
        return "uniqueFact"
      case "notification_date":
        return "datePicker"
      case "notification_time":
        return "timeField"
      case "description":
      case "bar_recipe":
      case "history":
      case "bar_prep":
      case "sound_bite":
        return "textArea"
      case "ingredients":
        return "ingredients"
      default:
        return "textField"
    }
  }

  const handleTimeChange = (e, field) => {
    const value = e?.target ? e.target.value : e
    formik.setFieldValue(field?.label, value)
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">
            {isEdit ? "Edit" : "Add New"} {title}
          </Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          {menuFields?.map((field, index) => {
            const componentType = getComponentType(field.label)
            switch (componentType) {
              case "textField":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <AppTextField
                        id={field.label}
                        name={field.label}
                        type="text"
                        fullWidth
                        isRequired={field.label === "name" ? true : false}
                        label={field.fieldName}
                        isUpperCase={!false}
                        placeholder={`Enter ${field.fieldName}`}
                        value={formik.values[field.label]}
                        onBlur={formik.handleBlur(field.label)}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors[field.label])}
                        touched={formik.touched[field.label]}
                        helperText={formik.errors[field.label]}
                      />
                    </Col>
                  </Row>
                )
              case "imageUpload":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <ImageUploadField
                        label={field.fieldName}
                        isVariant={true}
                        placeholder={`Upload ${field.fieldName}`}
                        value={formik.values[field.label]}
                        onChange={(e) => onImageUpload(e, formik)}
                        onDelete={() => handleImageDelete(formik)}
                        error={formik.errors[field.label]}
                        touched={formik.touched[field.label]}
                      />
                    </Col>
                  </Row>
                )
              case "autoComplete":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <AppAutocomplete
                        id={field.label}
                        isUpperCase={true}
                        placeholder={`Enter ${field.fieldName}`}
                        label={field.fieldName}
                        value={formik.values[field.label]}
                        error={Boolean(formik.errors[field.label])}
                        helperText={formik.errors[field.label]}
                        touched={formik.touched[field.label]}
                        onChange={(event, newValue) => formik.setFieldValue(field.label, newValue)}
                      />
                    </Col>
                  </Row>
                )
              case "uniqueFact":
                return (
                  <Row key={index}>
                    <AddUniqueFact
                      formik={formik}
                      values={formik.values?.[field.label] || []} // Ensure values is an array
                      name={field.label}
                      buttonText={`+ Add ${field.fieldName}`}
                      label={field.fieldName}
                    />
                  </Row>
                )
              case "textArea":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <AppTextArea
                        label={field?.fieldName}
                        minRows={5}
                        maxRows={15}
                        placeholder={`Enter ${field?.fieldName}`}
                        value={formik.values[field.label]}
                        onChange={formik.handleChange(field.label)}
                        onBlur={formik.handleBlur(field.label)}
                        error={Boolean(formik.errors[field.label])}
                        touched={formik.touched[field.label]}
                        helperText={formik.errors[field.label]}
                      />
                    </Col>
                  </Row>
                )
              case "numberField":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <AppTextField
                        id={field.label}
                        name={field.label}
                        type="number"
                        fullWidth
                        label={field.fieldName}
                        isUpperCase={!false}
                        placeholder={`Enter ${field.fieldName}`}
                        value={formik.values[field.label]}
                        onChange={formik.handleChange(field.label)}
                        onBlur={formik.handleBlur(field.label)}
                        error={Boolean(formik.errors[field.label])}
                        touched={formik.touched[field.label]}
                        helperText={formik.errors[field.label]}
                      />
                    </Col>
                  </Row>
                )
              case "datePicker":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <Grid item sm={12} lg={6} className="datesContainer">
                        <StyledLabel variant="body1Regular">{field.fieldName}</StyledLabel>
                        <Box className="dateFieldContainer w-100 mt-2">
                          <AppDatePicker
                            minDate={new Date()}
                            maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                            inputFormat="dd MMM yyyy"
                            value={formik.values[field.label]}
                            onChange={(val) => formik.setFieldValue(field.label, val)}
                            error={formik.errors[field.label]}
                            touched={formik.touched[field.label]}
                          />
                        </Box>
                      </Grid>
                    </Col>
                  </Row>
                )
              case "timeField":
                return (
                  <Row className="w-100 m-0 mt-4" key={index}>
                    <Col lg="12">
                      <AppTextField
                        id={field?.label}
                        type="time"
                        fullWidth
                        label={field?.fieldName}
                        isUpperCase={true}
                        value={formik.values?.[field?.label]}
                        onChange={(e) => handleTimeChange(e, field)}
                        error={formik.errors?.[field?.label] && formik.touched?.[field?.label]}
                        helperText={formik.touched?.[field?.label] && formik.errors?.[field?.label]}
                      />
                    </Col>
                  </Row>
                )
              case "ingredients":
                return (
                  <Row key={index}>
                    <AddIngrediant
                      formik={formik}
                      values={formik.values?.[field?.label]}
                      name={field?.label}
                      buttonText={`+ Add ${field?.fieldName}`}
                      label={field?.fieldName}
                    />
                  </Row>
                )
              default:
                return null
            }
          })}
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default MenuItemsForm
